var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.appShow
    ? _c(
        "div",
        { attrs: { id: "app" } },
        [
          _c("router-view"),
          _c(
            "el-dialog",
            {
              staticClass: "memWrap",
              attrs: {
                "show-close": false,
                "close-on-click-modal": false,
                visible: _vm.$store.state.showMem,
              },
              on: {
                "update:visible": function ($event) {
                  return _vm.$set(_vm.$store.state, "showMem", $event)
                },
                close: _vm.memCloseHandle,
              },
            },
            [
              _c("div", [
                !_vm.isConfirmMem
                  ? _c("div", { staticClass: "memTip" }, [
                      _c("div", { staticClass: "memTipImgWrap" }, [
                        _c("div", { staticClass: "tipImg" }),
                      ]),
                      _c("div", { staticClass: "memTipContetn" }, [
                        _c("p", { staticClass: "tipTile" }, [
                          _vm._v(_vm._s(_vm.$t("safetyAlert"))),
                        ]),
                        _c("ul", [
                          _c("ol", [
                            _vm._v(
                              "\n              · ShowPay" +
                                _vm._s(_vm.$t("mtip1")) +
                                "\n            "
                            ),
                          ]),
                          _c("ol", [
                            _vm._v(
                              "\n              ·\n              " +
                                _vm._s(_vm.$t("mtip2")) +
                                "\n            "
                            ),
                          ]),
                          _c("ol", [
                            _vm._v(
                              "\n              ·\n              " +
                                _vm._s(_vm.$t("mtip3")) +
                                "\n            "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", [
                        _c(
                          "button",
                          {
                            staticClass: "memBtn",
                            on: { click: _vm.startBackUp },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("startBackupLabel")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.isConfirmMem
                  ? _c("div", { staticClass: "backupMemWrap" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.$t("backupMem"))),
                      ]),
                      _c("div", { staticClass: "tip" }, [
                        _vm._v(_vm._s(_vm.$t("mtip4"))),
                      ]),
                      _c("div", { staticClass: "memShowBox" }, [
                        _vm._v(
                          "\n          " + _vm._s(_vm.memStr) + "\n        "
                        ),
                      ]),
                      _c("ul", { staticClass: "tip2" }, [
                        _c("ol", [
                          _vm._v(
                            "\n            ·\n            " +
                              _vm._s(_vm.$t("mtip5")) +
                              "\n          "
                          ),
                        ]),
                        _c("ol", [
                          _vm._v(
                            "\n            ·\n            " +
                              _vm._s(_vm.$t("mtip6")) +
                              "\n          "
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "finishMemBtn",
                              attrs: {
                                loading: _vm.finisedBackupLabelLoading,
                                disabled: _vm.disabled,
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.finishBackup.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("finisedBackupLabel")) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }