var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "check-sign" }, [
    _c("div", { staticClass: "item" }, [
      _c("span", [_vm._v("pubkey")]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.pubkey,
            expression: "pubkey",
          },
        ],
        attrs: { type: "text" },
        domProps: { value: _vm.pubkey },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.pubkey = $event.target.value
          },
        },
      }),
    ]),
    _c("div", { staticClass: "item" }, [
      _c("span", [_vm._v(_vm._s(_vm.$t("sign")))]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.sign,
            expression: "sign",
          },
        ],
        attrs: { type: "text" },
        domProps: { value: _vm.sign },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.sign = $event.target.value
          },
        },
      }),
    ]),
    _vm.result
      ? _c("p", [
          _vm._v(_vm._s(_vm.$t("checkResult")) + ":" + _vm._s(_vm.result)),
        ])
      : _vm._e(),
    _c(
      "button",
      {
        on: {
          click: function ($event) {
            return _vm.debounce(_vm.checkSign, 1000)
          },
        },
      },
      [_vm._v(_vm._s(_vm.$t("query")))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }