<template>
  <div class="home">
    <div class="homeWrap">
      <van-tabs v-model="active">
        <van-tab title="生成NFT合约">
          <van-tabs v-model="nftactive" class="nftjenesis">
            <div
              class="step"
              :class="{
                hide: nftactive == 2,
              }"
            >
              <span class="active"><em>1</em>填写NFT系列信息</span>
              <span>····</span>
              <span
                :class="{
                  active: nftactive == 1,
                }"
                ><em>2</em>铸造NFT</span
              >
            </div>
            <van-tab>
              <div class="form">
                <van-form @submit="onNFTGenesis">
                  <van-field
                    v-model="nftTotal"
                    name="nftTotal"
                    label="发行总数"
                    type="digit"
                    placeholder="请填写你的NFT发行总量"
                    autocomplete="off"
                    required
                  />
                  <van-field
                    v-model="nftSeriesName"
                    name="nftSeriesName"
                    label="系列名称"
                    placeholder="请填写token 系列名称"
                    required
                  />
                  <p class="genesis" v-if="nftgenesisId">
                    {{ `GenesisId:${nftgenesisId}` }}
                  </p>
                  <div style="margin: 16px">
                    <van-button
                      round
                      block
                      type="info"
                      native-type="submit"
                      :loading="isLoading"
                      >下一步</van-button
                    >
                  </div>
                </van-form>
              </div>
            </van-tab>
            <van-tab>
              <div class="form">
                <van-form @submit="onNFTIssue">
                  <van-field
                    v-model="nftgenesisId"
                    name="nftgenesisId"
                    label="GenesisId"
                    placeholder="填写genesisId"
                    required
                  />
                  <van-field
                    v-model="nftSensibleId"
                    name="nftSensibleId"
                    label="SensibleId"
                    placeholder="填写genesisId"
                    required
                  />
                  <van-field
                    v-model="nftgenesisTxid"
                    name="genesisTxid"
                    label="GenesisTxid"
                    placeholder="填写genesisTxid,必须是正确的tx格式"
                    required
                  />
                  <van-field
                    v-model="codehash"
                    name="codehash"
                    label="CodeHash"
                    placeholder="填写codeHash"
                    required
                  />
                  <van-field
                    class="mt-40"
                    v-model="receiverAddress"
                    name="receiverAddress"
                    label="接收地址"
                    placeholder="请填写NFT的接收地址"
                    required
                  />
                  <van-field
                    v-model="nftname"
                    name="nftname"
                    label="通证名称"
                    placeholder="请填写NFT名称"
                    required
                  />
                  <van-field
                    v-model="nftissuerName"
                    name="nftissuerName"
                    label="发布者名称"
                    placeholder="请填写NFT发布者名称"
                    required
                  />
                  <van-field
                    v-model="nftdesc"
                    name="nftdesc"
                    label="通证描述"
                    placeholder="请填写NFT描述"
                    required
                  />
                  <van-field
                    v-model="nfticon"
                    name="nfticon"
                    label="通证图标"
                    placeholder="请填图标TX,可以为空"
                    required
                  />
                  <van-field
                    v-model="nftwebsite"
                    name="nftwebsite"
                    label="网站介绍"
                    placeholder="NFT相关的网站介绍，可以为空"
                  />
                  <div class="mt-40">
                    <van-button
                      round
                      block
                      type="info"
                      native-type="submit"
                      :loading="isLoading"
                      >铸造NFT</van-button
                    >
                  </div>
                </van-form>
              </div>
            </van-tab>
            <van-tab class="succontent">
              <i class="el-icon-success"></i>
              <p>NFT铸造成功</p>
              <div class="detail">
                <p>
                  <span>通证名称：</span><span>{{ nftname }}</span>
                </p>
                <p>
                  <span>铸造者：</span><span>{{ nftissuerName }}</span>
                </p>
                <p>
                  <span>系列名称：</span><span>{{ nftSeriesName }}</span>
                </p>
                <p>
                  <span>发行总量：</span><span>{{ nftTotal }}</span>
                </p>
              </div>
              <van-button
                round
                block
                type="info"
                @click="nftactive = 1"
                class="finishbtn"
                >完成</van-button
              >
              <van-button
                round
                block
                type="info"
                @click="gotoUrl('index')"
                class="viewbtn"
                >点击查看</van-button
              >
            </van-tab>
          </van-tabs>
        </van-tab>
        <van-tab title="生成FT合约">
          <van-tabs v-model="ftactive" class="ftjenesis">
            <div
              class="step"
              :class="{
                hide: ftactive == 2,
              }"
            >
              <span class="active"><em>1</em>填写FT系列信息</span>
              <span>····</span>
              <span
                :class="{
                  active: ftactive == 1,
                }"
                ><em>2</em>发行FT</span
              >
            </div>
            <van-tab>
              <div class="form">
                <van-form @submit="onFTGenesis">
                  <van-field
                    v-model="ftName"
                    name="ftName"
                    label="FT名称"
                    placeholder="请填写FT合约名称"
                    required
                  />
                  <van-field
                    v-model="ftSymbol"
                    name="ftSymbol"
                    label="名称缩写"
                    placeholder="请填写FT合约的缩写名称 (FT单位)"
                    required
                  />
                  <van-field
                    v-model="decimalNum"
                    name="ftDecimalNum"
                    label="小数点位数"
                    type="digit"
                    placeholder="请设置FT的小书点位数，如: 8"
                    required
                  />
                  <van-field
                    v-model="ftissuerName"
                    name="ftissuerName"
                    label="发布者名称"
                    placeholder="请填写发布者名称"
                    required
                  />
                  <van-field
                    v-model="ftdesc"
                    name="ftdesc"
                    label="FT描述"
                    placeholder="请填写FT描述"
                    required
                  />
                  <van-field
                    v-model="fticon"
                    name="fticon"
                    label="FT图标"
                    placeholder="请上传图标/图片"
                    required
                  />
                  <van-field
                    v-model="ftwebsite"
                    name="ftwebsite"
                    label="网站介绍"
                    placeholder="FT相关的网站介绍，可以为空"
                  />
                  <p class="genesis" v-if="ftgenesisId">
                    {{ `GenesisId:${ftgenesisId}` }}
                  </p>
                  <p class="genesis" v-if="ftgenesisprotocol">
                    {{ `GenesisId:${ftgenesisprotocol}` }}
                  </p>
                  <div style="margin: 16px">
                    <van-button
                      round
                      block
                      type="info"
                      native-type="submit"
                      :loading="isLoading"
                      >下一步</van-button
                    >
                  </div>
                </van-form>
              </div>
            </van-tab>
            <van-tab>
              <div class="form">
                <van-form @submit="onFTIssue">
                  <van-field
                    v-model="ftgenesisId"
                    name="ftgenesisId"
                    label="GenesisId"
                    placeholder="填写genesisId"
                    required
                  />
                  <van-field
                    v-model="codehash"
                    name="codehash"
                    label="codeHash"
                    placeholder="填写codeHash"
                    required
                  />
                  <van-field
                    v-model="sensibleId"
                    name="sensibleId"
                    label="sensibleId"
                    placeholder="填写sensibleId"
                    required
                  />
                  <van-field
                    class="mt-40"
                    v-model="ftTotal"
                    name="ftTotal"
                    label="发行总数量"
                    placeholder="请填写你的FT发行总量"
                    type="digit"
                    required
                  />
                  <van-field
                    v-model="ftreceiverAddress"
                    name="ftreceiverAddress"
                    label="接收地址"
                    placeholder="请填写FT的接收地址"
                    required
                  />
                  <van-field name="isAddPub" label="是否增发">
                    <template #input>
                      <van-radio-group
                        v-model="radio"
                        direction="horizontal"
                        required
                      >
                        <van-radio name="1">是</van-radio>
                        <van-radio name="0">否</van-radio>
                      </van-radio-group>
                    </template>
                  </van-field>
                  <div style="margin: 16px">
                    <van-button
                      round
                      block
                      type="info"
                      native-type="submit"
                      :loading="isLoading"
                      >发行FT</van-button
                    >
                  </div>
                </van-form>
              </div>
            </van-tab>
            <van-tab class="succontent">
              <i class="el-icon-success"></i>
              <p>FT发行成功！</p>
              <div class="detail">
                <p>
                  <span>通证名称：</span><span>{{ ftName }}</span>
                </p>
                <p>
                  <span>发行方：</span><span>{{ ftissuerName }}</span>
                </p>
                <p>
                  <span>发行总量：</span><span>{{ ftTotal }} {{ ftName }}</span>
                </p>
              </div>
              <van-button
                round
                block
                type="info"
                @click="ftactive = 1"
                class="finishbtn"
                >完成</van-button
              >
              <van-button
                round
                block
                type="info"
                @click="gotoUrl('index')"
                class="viewbtn"
                >点击查看</van-button
              >
            </van-tab>
          </van-tabs>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

// import {NftMgr,FtMgr} from '../bundle/bundle'
export default {
  name: "Home",
  components: {},
  data() {
    return {
      genesisTxid: "",
      ftName: "",
      receiverAddress: "",
      ftreceiverAddress: "",
      ftVersion: "",
      nftTotal: "",
      ftTotal: "",
      ftDecimalNum: "",
      ftSymbol: "",
      active: 0,
      nftactive: 0,
      ftactive: 0,
      radio: "0",
      yes: "1",
      no: "0",
      ftgenesisprotocol: "",
      transferLoding: false,
      isLoading: false,
      decimalNum: "",
      nftgenesisId: "",
      nftgenesisTxid: "",
      nftSensibleId: "",
      nftSeriesName: "",
      codehash: "",
      sensibleId: "",
      nftsymbol: "",
      nftname: "",
      nftdesc: "",
      nfticon: "",
      nftwebsite: "",
      nftissuerName: "",
      ftgenesisId: "",
      ftdesc: "",
      fticon: "",
      ftwebsite: "",
      ftissuerName: "",
      metafileP: "",
      transfernftP: "",
      issueFtP: "",
      // network:'mainnet',
      // privateKey:'',
      // metaTxId:'a24413a581f06969d6b7094d93313a5f6e69f43dbb6c2eab8274bafea9d6b749',
    };
  },
  mounted() {},
  methods: {
    async onNFTGenesis(values) {
      let result;
      this.isLoading = true;
      try {
        result = await this.$store.state.metanet.genesisNFT({
          nftTotal: +values.nftTotal,
          signersRaw: [
            {
              satotxApiPrefix:
                "https://satotx.showpay.top,https://cnsatotx.showpay.top",
              satotxPubKey:
                "5b94858991d384c61ffd97174e895fcd4f62e4fea618916dc095fe4c149bbdf1188c9b33bc15cbe963a63b2522e70b80a5b722ac0e6180407917403755df4de27d69cc115c683a99face8c823cbccf73c7f0d546f1300b9ee2e96aea85542527f33b649f1885caebe19cf75d9a645807f03565c65bd4c99c8f6bb000644cfb56969eac3e9331c254b08aa279ceb64c47ef66be3f071e28b3a5a21e48cdfc3335d8b52e80a09a104a791ace6a2c1b4da88c52f9cc28c54a324e126ec91a988c1fe4e21afc8a84d0e876e01502386f74e7fc24fc32aa249075dd222361aea119d4824db2a797d58886e93bdd60556e504bb190b76a451a4e7b0431973c0410e71e808d0962415503931bbde3dfce5186b371c5bf729861f239ef626b7217d071dfd62bac877a847f2ac2dca07597a0bb9dc1969bed40606c025c4ff7b53a4a6bd921642199c16ede8165ed28da161739fa8d33f9f483212759498c1219d246092d14c9ae63808f58f03c8ca746904ba51fa326d793cea80cda411c85d35894bdb5",
            },
            {
              satotxApiPrefix:
                "https://satotx2.showpay.top,https://cnsatotx2.showpay.top",
              satotxPubKey:
                "09539fcf01e83c7c649164ddd0dd42463ef10a98c665cd0d9f791446a3c4c2dd3916f6e76075b36a06f40731821f6b7dbb8bea8effa4ea461fecce4b6b2d45ca4dd923028fd6dc6ce49512616ca55f01162e5d2f85faa22ec40bc35d4978204a9b07a53f04297b886fa4abb095034f106f8ff0fd172e1e96bf7198cd5b9944ec1af32328a156877769ecabd41489a7ac858fd35cd8d93e68f33053077cf50bb397b66d160598963d1b663b3bce6371877df0e33866e4d9557b0bde7a2a930c274fa9e697d9f17ad88528ecab1be32a9d518bb950fc8264f2056d4f395fcdc12dd59cb8945013105ed52433326e3fa067237f17ab62e65557c7538e634daf11288b7eaab537abf2cc2a90159632fb9bb8fac01085b70024e01e42cd431db70d004963e46da8733c18fd5ffaaecfd67c860ae37441271ba545f86cae72690a5a3261e0125a2bf069fe28a1e1431b4cac29f8a43cbefbe22d5ae4b92441f8915881560271ee31379d365da38f1a5fa1414d6ad71943a083cce0ee45d47f81ff3c9d",
            },
            {
              satotxApiPrefix:
                "https://satotx3.showpay.top,https://cnsatotx3.showpay.top",
              satotxPubKey:
                "8e37df222f9af47980ad72d31b2619b49460c7be3a4c1034c0e7f43146d80058e52bfc53b0608db5a9ec5cb832c326f785f5c4e4349cc9fd647839738f465573d1707ef84d14c41ad857a1a8e5a075ae953be4c52481ee3b8e85891613dcf99c1f7bf3a51cd67ed92f9b71d77b8517a57af5fb4e2bad7197031e00c1d8b85d0abc62fb98952d9ddfb43b47c01590ef0a365aed89b179505ccfd1d70effe4d375d5e774578434f8f9bb2281100b6e1daf8df4a40af35853c91aee2dc7f578034fa534586985e4df30b7a85efab943f68f01b46f72ced16655ac4f7e7f0439e0e6b43803dc7e262512c57ef862d41b98344ea6e725683846d91209ad87097cb4ae7092cc2c4ebd39383905e61eeaae495d8b3a57f9da8cca760b9546635cef2a8728c4209891ad1e5cbeb75d2b798f0dabd1eceaf4b297186fd2a45ed58fbaed886a4dc2f8690c9070061b9481e446319b7f0a54f9d94e7505e87e3d81bcd664ecc29acc2942361e60b30fa965cdc88185163c2857644d837c9d839bb9f6b8e6dd",
            },
            {
              satotxApiPrefix: "https://s1.satoplay.cn,https://s1.satoplay.com",
              satotxPubKey:
                "2c8c0117aa5edba9a4539e783b6a1bdbc1ad88ad5b57f3d9c5cba55001c45e1fedb877ebc7d49d1cfa8aa938ccb303c3a37732eb0296fee4a6642b0ff1976817b603404f64c41ec098f8cd908caf64b4a3aada220ff61e252ef6d775079b69451367eda8fdb37bc55c8bfd69610e1f31b9d421ff44e3a0cfa7b11f334374827256a0b91ce80c45ffb798798e7bd6b110134e1a3c3fa89855a19829aab3922f55da92000495737e99e0094e6c4dbcc4e8d8de5459355c21ff055d039a202076e4ca263b745a885ef292eec0b5a5255e6ecc45534897d9572c3ebe97d36626c7b1e775159e00b17d03bc6d127260e13a252afd89bab72e8daf893075f18c1840cb394f18a9817913a9462c6ffc8951bee50a05f38da4c9090a4d6868cb8c955e5efb4f3be4e7cf0be1c399d78a6f6dd26a0af8492dca67843c6da9915bae571aa9f4696418ab1520dd50dd05f5c0c7a51d2843bd4d9b6b3b79910e98f3d98099fd86d71b2fac290e32bdacb31943a8384a7668c32a66be127b74390b4b0dec6455",
            },
            {
              satotxApiPrefix: "https://satotx.metasv.com",
              satotxPubKey:
                "19d9193ee2e95d09445d28408e8a3da730b2d557cd8d39a7ae4ebbfbceb17ed5d745623529ad33d043511f3e205c1f92b6322833424d19823c3b611b3adabb74e1006e0e93a8f1e0b97ab801c6060a4c060f775998d9f003568ab4ea7633a0395eb761c36106e229394f2c271b8522a44a5ae759254f5d22927923ba85b3729460ecccca07a5556299aa7f2518814c74a2a4d48b48013d609002631f2d93c906d07077ef58d473e3d971362d1129c1ab9b8f9b1365519f0c023c1cadad5ab57240d19e256e08022fd0708951ff90a8af0655aff806c6382d0a72c13f1e52b88222d7dfc6357179b06ffcf937f9da3b0419908aa589a731e26bbaba2fa0b754bf722e338c5627b11dc24aadc4d83c35851c034936cf0df18167e856a5f0a7121d23cd48b3f8a420869a37bd1362905d7f76ff18a991f75a0f9d1bcfc18416d76691cc357cbdcc8cc0df9dbd9318a40e08adb2fb4e78b3c47bdf07eeed4f3f4e0f7e81e37460a09b857e0194c72ec03bb564b5b409d8a1b84c153186ecbb4cfdfd",
            },
          ],
          seriesName: this.nftSeriesName,
        });
      } catch (err) {
        this.isLoading = false;
        if (err?.resData) {
          return this.$message.error(
            `错误:${JSON.parse(err.resData.body).message},如有疑问请联系客服`
          );
        }
        if (err?.data) {
          return this.$message.error(
            `错误:${err.data.message},如有疑问请联系客服`
          );
        }
        return this.$message.error(`错误:${err},如有疑问请联系客服`);
      }
      console.log(result);
      if (result?.genesisTxid) {
        this.nftgenesisId = result.genesisId;
        this.nftgenesisTxid = result.genesisTxid;
        this.nftSensibleId = result.sensibleId;
        this.codehash = result.codehash;
        this.nftactive = 1;
        this.$message.success("发布成功");
      }
      this.isLoading = false;
    },
    async onNFTIssue(values) {
      let result;
      try {
        this.isLoading = true;
        result = await this.$store.state.metanet.issueNFT({
          genesisId: values.nftgenesisId,
          genesisTxid: values.genesisTxid,
          codehash: values.codehash,
          sensibleId: values.nftSensibleId,
          receiverAddress: values.receiverAddress,
          nftname: values.nftname,
          nftdesc: values.nftdesc,
          nfticon: values.nfticon,
          nftwebsite: values.nftwebsite,
          nftissuerName: values.nftissuerName,
          sensibleId: values.nftSensibleId,
        });
        console.log("issueNFT", result);
      } catch (err) {
        console.log(err);
        this.isLoading = false;
        if (err?.resData) {
          return this.$message.error(
            `错误:${JSON.parse(err.resData.body).message},如有疑问请联系客服`
          );
        }
        if (err?.data) {
          return this.$message.error(
            `错误:${err?.data?.message},如有疑问请联系客服`
          );
        }
        return this.$message.error(`错误:${err},如有疑问请联系客服`);
      }
      console.log(result);
      if (result?.txId) {
        this.nftactive = 2;
        this.$message.success("发布成功");
      }
      this.isLoading = false;
    },
    async onFTGenesis(values) {
      let result;
      this.isLoading = true;
      try {
        result = await this.$store.state.metanet.genesisFT({
          ftName: values.ftName,
          ftSymbol: values.ftSymbol,
          ftDecimalNum: +values.ftDecimalNum,
          ftdesc: values.ftdesc,
          fticon: values.fticon,
          ftwebsite: values.ftwebsite,
          ftissuerName: values.ftissuerName,
          signersRaw: [
            {
              satotxApiPrefix:
                "https://satotx.showpay.top,https://cnsatotx.showpay.top",
              satotxPubKey:
                "5b94858991d384c61ffd97174e895fcd4f62e4fea618916dc095fe4c149bbdf1188c9b33bc15cbe963a63b2522e70b80a5b722ac0e6180407917403755df4de27d69cc115c683a99face8c823cbccf73c7f0d546f1300b9ee2e96aea85542527f33b649f1885caebe19cf75d9a645807f03565c65bd4c99c8f6bb000644cfb56969eac3e9331c254b08aa279ceb64c47ef66be3f071e28b3a5a21e48cdfc3335d8b52e80a09a104a791ace6a2c1b4da88c52f9cc28c54a324e126ec91a988c1fe4e21afc8a84d0e876e01502386f74e7fc24fc32aa249075dd222361aea119d4824db2a797d58886e93bdd60556e504bb190b76a451a4e7b0431973c0410e71e808d0962415503931bbde3dfce5186b371c5bf729861f239ef626b7217d071dfd62bac877a847f2ac2dca07597a0bb9dc1969bed40606c025c4ff7b53a4a6bd921642199c16ede8165ed28da161739fa8d33f9f483212759498c1219d246092d14c9ae63808f58f03c8ca746904ba51fa326d793cea80cda411c85d35894bdb5",
            },
            {
              satotxApiPrefix:
                "https://satotx2.showpay.top,https://cnsatotx2.showpay.top",
              satotxPubKey:
                "09539fcf01e83c7c649164ddd0dd42463ef10a98c665cd0d9f791446a3c4c2dd3916f6e76075b36a06f40731821f6b7dbb8bea8effa4ea461fecce4b6b2d45ca4dd923028fd6dc6ce49512616ca55f01162e5d2f85faa22ec40bc35d4978204a9b07a53f04297b886fa4abb095034f106f8ff0fd172e1e96bf7198cd5b9944ec1af32328a156877769ecabd41489a7ac858fd35cd8d93e68f33053077cf50bb397b66d160598963d1b663b3bce6371877df0e33866e4d9557b0bde7a2a930c274fa9e697d9f17ad88528ecab1be32a9d518bb950fc8264f2056d4f395fcdc12dd59cb8945013105ed52433326e3fa067237f17ab62e65557c7538e634daf11288b7eaab537abf2cc2a90159632fb9bb8fac01085b70024e01e42cd431db70d004963e46da8733c18fd5ffaaecfd67c860ae37441271ba545f86cae72690a5a3261e0125a2bf069fe28a1e1431b4cac29f8a43cbefbe22d5ae4b92441f8915881560271ee31379d365da38f1a5fa1414d6ad71943a083cce0ee45d47f81ff3c9d",
            },
            {
              satotxApiPrefix:
                "https://satotx3.showpay.top,https://cnsatotx3.showpay.top",
              satotxPubKey:
                "8e37df222f9af47980ad72d31b2619b49460c7be3a4c1034c0e7f43146d80058e52bfc53b0608db5a9ec5cb832c326f785f5c4e4349cc9fd647839738f465573d1707ef84d14c41ad857a1a8e5a075ae953be4c52481ee3b8e85891613dcf99c1f7bf3a51cd67ed92f9b71d77b8517a57af5fb4e2bad7197031e00c1d8b85d0abc62fb98952d9ddfb43b47c01590ef0a365aed89b179505ccfd1d70effe4d375d5e774578434f8f9bb2281100b6e1daf8df4a40af35853c91aee2dc7f578034fa534586985e4df30b7a85efab943f68f01b46f72ced16655ac4f7e7f0439e0e6b43803dc7e262512c57ef862d41b98344ea6e725683846d91209ad87097cb4ae7092cc2c4ebd39383905e61eeaae495d8b3a57f9da8cca760b9546635cef2a8728c4209891ad1e5cbeb75d2b798f0dabd1eceaf4b297186fd2a45ed58fbaed886a4dc2f8690c9070061b9481e446319b7f0a54f9d94e7505e87e3d81bcd664ecc29acc2942361e60b30fa965cdc88185163c2857644d837c9d839bb9f6b8e6dd",
            },
            {
              satotxApiPrefix: "https://s1.satoplay.cn,https://s1.satoplay.com",
              satotxPubKey:
                "2c8c0117aa5edba9a4539e783b6a1bdbc1ad88ad5b57f3d9c5cba55001c45e1fedb877ebc7d49d1cfa8aa938ccb303c3a37732eb0296fee4a6642b0ff1976817b603404f64c41ec098f8cd908caf64b4a3aada220ff61e252ef6d775079b69451367eda8fdb37bc55c8bfd69610e1f31b9d421ff44e3a0cfa7b11f334374827256a0b91ce80c45ffb798798e7bd6b110134e1a3c3fa89855a19829aab3922f55da92000495737e99e0094e6c4dbcc4e8d8de5459355c21ff055d039a202076e4ca263b745a885ef292eec0b5a5255e6ecc45534897d9572c3ebe97d36626c7b1e775159e00b17d03bc6d127260e13a252afd89bab72e8daf893075f18c1840cb394f18a9817913a9462c6ffc8951bee50a05f38da4c9090a4d6868cb8c955e5efb4f3be4e7cf0be1c399d78a6f6dd26a0af8492dca67843c6da9915bae571aa9f4696418ab1520dd50dd05f5c0c7a51d2843bd4d9b6b3b79910e98f3d98099fd86d71b2fac290e32bdacb31943a8384a7668c32a66be127b74390b4b0dec6455",
            },
            {
              satotxApiPrefix: "https://satotx.metasv.com",
              satotxPubKey:
                "19d9193ee2e95d09445d28408e8a3da730b2d557cd8d39a7ae4ebbfbceb17ed5d745623529ad33d043511f3e205c1f92b6322833424d19823c3b611b3adabb74e1006e0e93a8f1e0b97ab801c6060a4c060f775998d9f003568ab4ea7633a0395eb761c36106e229394f2c271b8522a44a5ae759254f5d22927923ba85b3729460ecccca07a5556299aa7f2518814c74a2a4d48b48013d609002631f2d93c906d07077ef58d473e3d971362d1129c1ab9b8f9b1365519f0c023c1cadad5ab57240d19e256e08022fd0708951ff90a8af0655aff806c6382d0a72c13f1e52b88222d7dfc6357179b06ffcf937f9da3b0419908aa589a731e26bbaba2fa0b754bf722e338c5627b11dc24aadc4d83c35851c034936cf0df18167e856a5f0a7121d23cd48b3f8a420869a37bd1362905d7f76ff18a991f75a0f9d1bcfc18416d76691cc357cbdcc8cc0df9dbd9318a40e08adb2fb4e78b3c47bdf07eeed4f3f4e0f7e81e37460a09b857e0194c72ec03bb564b5b409d8a1b84c153186ecbb4cfdfd",
            },
          ],
        });
      } catch (err) {
        this.isLoading = false;
        if (err?.resData) {
          return this.$message.error(
            `错误:${JSON.parse(err.resData.body).message},如有疑问请联系客服`
          );
        }
        if (err?.data) {
          return this.$message.error(
            `错误:${err.data.message},如有疑问请联系客服`
          );
        }
        return this.$message.error(`错误:${err},如有疑问请联系客服`);
      }
      console.log(result);

      if (result?.genesisId) {
        this.ftgenesisId = result.genesisId;
        this.codehash = result.codehash;
        this.sensibleId = result.sensibleId;
        this.ftactive = 1;
        this.$message.success("发布成功");
      }
      this.isLoading = false;
    },
    async onFTIssue(values) {
      let result;
      this.isLoading = true;
      let decimalNum = await this.getFtDecimal(values.sensibleId);
      try {
        result = await this.$store.state.metanet.issueFT({
          genesisId: values.ftgenesisId,
          ftTotal: parseInt(values.ftTotal * Math.pow(10, decimalNum)),
          codehash: values.codehash,
          sensibleId: values.sensibleId,
          isAddPub: Boolean(+values.isAddPub),
          ftreceiverAddress: values.ftreceiverAddress,
        });
        console.log("result123", result);
      } catch (err) {
        this.isLoading = false;
        if (err?.resData) {
          return this.$message.error(
            `错误:${JSON.parse(err.resData.body).message},如有疑问请联系客服`
          );
        }
        return this.$message.error(`错误:${err},如有疑问请联系客服`);
      }
      console.log(result);
      if (result?.txId) {
        this.ftactive = 2;
        this.$message.success("发布成功");
      }
      this.isLoading = false;
    },
    async transfernftProtocol() {
      let result;
      this.isLoading = true;
      try {
        result = await this.$store.state.metanet.transfernftProtocol();
      } catch (err) {
        this.isLoading = false;
        if (err?.resData) {
          return this.$message.error(
            `错误:${JSON.parse(err.resData.body).message},如有疑问请联系客服`
          );
        }
        return this.$message.error(`错误:${err},如有疑问请联系客服`);
      }
      console.log(result);
      if (result?.txId) {
        this.ftgenesisId = null;
        this.$message.success("发布成功");
      }
      this.isLoading = false;
      this.transfernftP = result;
    },
    async metafileProtocol() {
      let result;
      this.isLoading = true;
      try {
        result = await this.$store.state.metanet.metafileProtocol();
      } catch (err) {
        this.isLoading = false;
        if (err?.resData) {
          return this.$message.error(
            `错误:${JSON.parse(err.resData.body).message},如有疑问请联系客服`
          );
        }
        return this.$message.error(`错误:${err},如有疑问请联系客服`);
      }
      console.log(result);
      if (result?.txId) {
        this.ftgenesisId = null;
        this.$message.success("发布成功");
      }
      this.isLoading = false;
      this.metafileP = result;
    },
    async issueFtProtocol() {
      let result;
      this.isLoading = true;
      try {
        result = await this.$store.state.metanet.issuenftProtocol();
      } catch (err) {
        this.isLoading = false;
        if (err?.resData) {
          return this.$message.error(
            `错误:${JSON.parse(err.resData.body).message},如有疑问请联系客服`
          );
        }
        return this.$message.error(`错误:${err},如有疑问请联系客服`);
      }
      console.log(result);
      if (result?.txId) {
        this.ftgenesisId = null;
        this.$message.success("发布成功");
      }
      this.isLoading = false;
      this.issueFtP = result;
    },
    async getTransferftProtocol() {
      this.transferLoding = true;
      try {
        let result = await this.$store.state.metanet.transferftProtocol();
        this.ftgenesisprotocol = result;
      } finally {
        this.transferLoding = false;
      }
    },
    //根据用户genisisID来获取FT的小数位
    getFtDecimal(params) {
      return new Promise(async (resolve, reject) => {
        const ftGenesisTxid =
          this.$store.state.metanet.reverceFtByteString(params);
        if (ftGenesisTxid) {
          let result = await this.$store.state.metanet.getFtGenesisInfo(
            ftGenesisTxid
          );
          resolve(result.data.decimalNum);
        } else {
          reject(this.$message.error(this.$t("lostFtDecimalNum")));
        }
      });
    },
  },
  async created() {},
};
</script>
<style lang="scss" scoped>
.home {
  box-sizing: content-box;
  background-color: #fff;
  width: 800px;
  margin: 0 auto;
}
.genesis {
  margin-top: 15px;
}
.homeWrap {
  padding-top: 80px;
}
.mt-40 {
  margin-top: 40px;
}
.form {
  margin-top: 40px;
}
.mart10 {
  margin-top: 10px;
}
.van-cell {
  height: 50px;
  line-height: 50px;
  padding-top: 0;
  padding-bottom: 0;
}
.step {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  margin: 0 auto;
  font-size: 12px;
  color: #909399;
  &.hide {
    display: none;
  }
  em {
    display: inline-block;
    margin-right: 6px;
    text-align: center;
    color: #ffffff;
    font-style: normal;
    border-radius: 50%;
    background-color: #bfc2cc;
    width: 20px;
    height: 20px;
    line-height: 20px;
  }
  span {
    &.active {
      color: #508ef0;
      em {
        color: #fff;
        background-color: #508ef0;
      }
    }
  }
}
.succontent {
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
  width: 350px;
  > p {
    color: #606266;
    margin: 20px 0 40px;
  }
  .detail {
    border-top: 1px solid #f5f7f9;
    border-bottom: 1px solid #f5f7f9;
    padding: 10px 0;
    p {
      display: flex;
      margin: 10px 0;
      justify-content: space-between;
      span:first-child {
        color: #909399;
      }
    }
  }
}
.finishbtn {
  margin: 60px auto 10px !important;
}
.viewbtn {
  border: none;
  color: rgba(80, 142, 240, 1);
  background-color: rgba(80, 142, 240, 0.15) !important;
}
.el-icon-success {
  font-size: 48px;
  color: #508ef0;
}
::v-deep .van-tab__text--ellipsis {
  font-size: 20px;
}
::v-deep .van-field__control--custom {
  min-height: 70px;
}
::v-deep .van-tabs__line {
  width: 12px;
  height: 4px;
  background: #508ef0;
  border-radius: 2px;
}
::v-deep .van-field__control--custom {
  min-height: 50px;
}
::v-deep .van-button {
  width: 120px;
  height: 40px;
  background: #508ef0;
  border-radius: 6px;
  margin: 0 auto;
}
.nftjenesis,
.ftjenesis {
  ::v-deep .van-tabs__line {
    display: none;
  }
  ::v-deep .van-tabs__wrap {
    display: none;
  }
  ::v-deep .van-tabs__content {
    border: 1px solid #f2f4f7;
    padding: 40px 50px;
    margin-top: 40px;
  }
}
input:focus,
textarea:focus {
  outline: none;
}
@media screen and (max-width: 500px) {
  .home {
    width: 380px;
  }
}
</style>
