var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index" },
    [
      _c(
        "el-table",
        { staticClass: "table", attrs: { data: _vm.tableData } },
        [
          _c("el-table-column", {
            attrs: { label: _vm.$t("platform"), prop: "plateName" },
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("introduce"), prop: "plateIntro" },
          }),
          _c("el-table-column", {
            attrs: { align: "right", label: _vm.$t("operation") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.isLogin
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.unBinding(scope.$index, scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("unbound")))]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.hasBind
        ? _c("div", { staticClass: "control" }, [
            _c("div", { staticClass: "tips" }, [
              _c("p", [_vm._v(_vm._s(_vm.$t("boundMetaid")))]),
              _c(
                "div",
                { staticClass: "btn" },
                [
                  _c("el-button", { on: { click: _vm.cancel } }, [
                    _vm._v(_vm._s(_vm.$t("cancel"))),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.confirmloding },
                      on: { click: _vm.handleBinding },
                    },
                    [_vm._v(_vm._s(_vm.$t("affirm")))]
                  ),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }