import axios from "axios";
import Api from "./base";
import store from "../store";
import { walletApi, aggregationApi, useMainNet } from "../common/js/baseApi";

// 水龙头
export const reward = (params) => {
  return Api.ajaxPost("/showpaycore/api/v1/wallet/faucet", params, walletApi());
};
// 获取同一铸造者所有系列 NFT 列表
export const getNFTSeriresDetailList = (params) => {
  return axios.post(
    `${aggregationApi()}/v2/app/sensible/getMyNftSummaryDetailList`,
    params
    // walletApi(),
  );
};
// 获取 NFT 系列列表
export const newGetNftSeriresList = (params) => {
  return axios.post(
    `${aggregationApi()}/v2/app/sensible/getMyNftSummaryList`,
    params
    // walletApi(),
  );
};
// 获取 MVC NFT 系列列表
export const getMVCNftSeriesList = (params) => {
  return axios.post(
    `${aggregationApi()}/v2/app/sensible/getMyNftSummaryList`,
    params
    // walletApi(),
  );
};
// 获取 FT 系列列表
export const newGetFtTokenList = (params) => {
  return axios.post(
    `${aggregationApi()}/v2/app/sensible/getMyFtList`,
    params
    // walletApi(),
  );
};
// 获取 MVC FT 系列列表
export const getMVCFtTokenList = (params) => {
  return axios.post(
    `${aggregationApi()}/v2/app/sensible/getMyFtList`,
    params
    // walletApi(),
  );
};
// 获取我已上架的 NFT
export const getMySaleNft = (params) => {
  return axios.post(
    `${aggregationApi()}/v2/app/nftOnShow/getMyNftOnShowList`,
    params
    // walletApi(),
  );
};
// 上报 nft 下架信息
export const setDeadlineTime = (params) => {
  return Api.ajaxPost(
    "/nftapi/nftonshow/api/v2/productTransaction/setShelvesTime",
    params
    // walletApi(),
  );
};
// sensible 获取 BSV 记录
export const getSensibleBsvHistory = (query) => {
  return axios.get(`/sensible/address/${query}/history`);
};
// sensible 获取 FT 记录
export const getSensibleFtHistory = (codehash, genesis, address) => {
  return axios.get(`/sensible/ft/history/${codehash}/${genesis}/${address}`);
};
// 获取 MetaBotNFT 头像接口
export const getNftAvatar = (params) => {
  return axios.post(
    `${aggregationApi()}/v2/app/nftAvatar/getMyNFTAvatarList`,
    params
  );
};
// 上报用户消费信息
export const uploadPayment = (params) => {
  const headers = params.headers;
  delete params.headers;
  return Api.ajaxPost("/apprs/api/v2/riskmanage/risk", params, walletApi(), {
    headers,
  });
};
// 获取风控信息
export const getriskinfo = (params) => {
  const headers = params.headers;
  delete params.headers;
  return Api.ajaxPost(
    "/apprs/api/v2/riskmanage/getriskinfo",
    params,
    walletApi(),
    { headers }
  );
};
// 获取汇率
export const getOneExchangeRateForLocal = (params) => {
  if (store.state.chain === "BSV") {
    return Api.ajaxPost(
      "/wallet/api/v1/token/getOneExchangeRateForLocal",
      params,
      walletApi()
    );
  }

  return Api.ajaxGet(
    "/metaid-base/v1/exchange/getOneExchangeRateForLocal/mvc",
    params,
    walletApi()
  );
};
// 注册 (获取短信/邮件接口)
export const registerVerification = (params, type) => {
  return Api.ajaxPost(
    type === "oauth"
      ? "/oauth2/api/v1/user/register/verification"
      : "/showpaycore/api/v1/user/register/verification",
    params,
    walletApi()
  );
};

// 获取密码提示
export const getUserRemark = (params) => {
  return Api.ajaxPost(
    "/showpaycore/api/v1/user/getUserRemark",
    params,
    walletApi()
  );
};

// 注册 (验证短信/邮件接口)
export const registerCheck = (params, type) => {
  return Api.ajaxPost(
    type === "oauth"
      ? "/oauth2/api/v1/user/register/check"
      : "/showpaycore/api/v1/user/register/check",
    params,
    walletApi()
  );
};

// 登录 (获取短信/邮件接口)
export const loginVerification = (params, type) => {
  return Api.ajaxPost(
    type === "oauth"
      ? "/oauth2/api/v1/user/login/verification"
      : "/showpaycore/api/v1/user/login/verification",
    params,
    walletApi()
  );
};

// 登录 (验证短信/邮件接口)
export const logincheck = (params) => {
  return Api.ajaxPost(
    "/showpaycore/api/v1/user/new/login/check",
    params,
    walletApi()
  );
};

// 获取用户信息
export const getuserinfo = (params) => {
  return Api.ajaxPost(
    "/showpaycore/api/v1/user/getuserinfo",
    params,
    walletApi()
  );
};

// 验证用户 token 信息
export const checkUserToken = (token) => {
  return Api.ajaxGet(
    "/showpaycore/api/v1/user/checkUserToken?user_ctoken=" + token,
    {},
    walletApi()
  );
};

// 上报用户钱包信息
export const setUserWalletInfo = (params) => {
  return Api.ajaxPost(
    `/showpaycore/api/v1/wallet/setuserwalletinfo`,
    params,
    walletApi()
  );
};

// 获取用户绑定充值地址
export const addresslist = (params) => {
  return Api.ajaxPost(
    "/showpaycore/api/v1/wallet/addresslist",
    params,
    walletApi()
  );
};

// 上传头像
export const uploadFileApiForShowMoney = (params) => {
  return Api.ajaxPost("/apis/uploadFileApiForShowMoney", params, walletApi());
};

// 上报每一个地址
export const addUserAddress = (params) => {
  return Api.ajaxPost(
    "/showpaycore/api/v1/wallet/addUserAddress",
    params,
    walletApi()
  );
};

// 上报每一个地址
export const sendMetaId = (params) => {
  return Api.ajaxPost("/core/api/v1/wallet/sendMetaId", params, walletApi());
};
// 上报交易
export const sendRawTx = (tx) => {
  const path =
    store.state.chain === "BSV"
      ? "/utxo/sendRawTx"
      : "/metaid-base/v1/meta/upload/raw";
  return Api.ajaxPost(
    path,
    {
      RAW: tx,
    },
    walletApi()
  );
};

// 获取用户参数
export const gettokenpage = (params) => {
  return Api.ajaxPost(
    "/showpaycore/api/v1/user/gettokenstring",
    params,
    walletApi()
  );
};
// 获取用户参数
export const settoken = (params) => {
  return Api.ajaxPost(
    "/showpaycore/api/v1/user/settokenstring",
    params,
    walletApi()
  );
};

// 设置用户名
export const setuserinfo = (params) => {
  return Api.ajaxPost(
    "/showpaycore/api/v1/user/setuserinfo",
    params,
    walletApi()
  );
};

// 获取历史数据
export const history = (params) => {
  return Api.ajaxPost("/utxo/historyPlatform", params, walletApi());
};

// 获取高度
export const Getheight = (params) => {
  return Api.ajaxPost("/utxo/height", params, walletApi());
};

// 获取历史记录分页
export const historyMetaIDNew = (params) => {
  return Api.ajaxPost("/utxo/historyMetaIDPage", params, walletApi());
};

// 绑定手机/邮箱 (已注册的用户)
export const bindPhoneOrEmail = (params) => {
  return Api.ajaxPost(
    "/showpaycore/api/v1/user/bindPhoneOrEmail",
    params,
    walletApi()
  );
};

// 验证用户地址是否正确
export const checkAddress = (params) => {
  return Api.ajaxPost(
    "/showpaycore/api/v1/wallet/checkAddress",
    params,
    walletApi()
  );
};

// 获取 ShowDID 的用户基本信息
export const getShowDIDUserInfo = (params) => {
  return Api.ajaxPost(
    "/serviceapi/api/v1/metago/getShowDIDUserInfo",
    {
      data: params,
    },
    walletApi()
  );
};
// 根据 ZeroAddress 获取 rootTxId
export const getMetaIdByZoreAddress = (params) => {
  return Api.ajaxPost(
    "/serviceapi/api/v1/metago/getMetaIdByZoreAddress",
    {
      data: params,
    },
    walletApi()
  );
};

// 获取 ShowDID 的用户基本信息
export const getOwnShowAccount = (params) => {
  return Api.ajaxPost(
    "/serviceapi/api/v1/showService/getOwnShowAccount",
    {
      data: params,
    },
    walletApi()
  );
};
// 根据 root 获取 info 的 address 和 tx 息
export const getInfoAddressAndTxId = (params) => {
  return Api.ajaxPost(
    "/serviceapi/api/v1/metago/getInfoAddressAndTxId",
    {
      data: params,
    },
    walletApi()
  );
};
// 上传 Showid
export const uploadShowAccount = (params) => {
  return Api.ajaxPost(
    "/serviceapi/api/v1/showService/uploadShowAccount",
    params,
    walletApi()
  );
};

// 获取获取个人项目列表
export const getProjectList = (params) => {
  return Api.ajaxPost(
    "/showpaycore/api/v1/thirdParty/getProjectList",
    params,
    walletApi()
  );
};

// 第三方开发者中心 - 添加项目
export const addProject = (params) => {
  return Api.ajaxPost(
    "/showpaycore/api/v1/thirdParty/addProject",
    params,
    walletApi()
  );
};

// 第三方开发者中心 - 修改项目
export const updateProject = (params) => {
  return Api.ajaxPost(
    "/showpaycore/api/v1/thirdParty/updateProject",
    params,
    walletApi()
  );
};

// 续期 token
export const refreshToken = (params) => {
  return Api.ajaxPost("/showpaycore/v2/user/refreshToken", params, walletApi());
};
/**
 * 更新密码
 * @param {*} params
 */
export const updatePassword = (params) => {
  return Api.ajaxPost(
    "/showpaycore/api/v1/user/updatePassword",
    params,
    walletApi()
  );
};
/**
 * 更新密码
 * @param {*} params
 */
export const setPassword = (params) => {
  return Api.ajaxPost(
    "/showpaycore/api/v1/user/setPassword",
    params,
    walletApi()
  );
};
/**
 * 忘记密码
 * @param {*} params
 */
export const forgetPassword = (params) => {
  return Api.ajaxPost(
    "/showpaycore/api/v1/user/forgetPassword",
    params,
    walletApi()
  );
};
/**
 * 忘记密码
 * @param {*} params
 */
export const getEnCryptedMnemonic = (params) => {
  return Api.ajaxPost(
    "/showpaycore/api/v1/user/getEnCryptedMnemonic",
    params,
    walletApi()
  );
};
/**
 * 忘记密码获取验证码
 * @param {*} params
 * @returns
 */
export const forgetPasswordVerification = (params) => {
  return Api.ajaxPost(
    "/showpaycore/api/v1/user/forgetPassword/verification",
    params,
    walletApi()
  );
};

// 获取协议数据
export const getProtocolData = (
  protocols,
  config,
  skip = 0,
  limit = 10,
  timestamp = -1
) => {
  let protocol_list = [];
  let fin = {};

  if (!config) {
    config = {};
  }
  if (Object.prototype.toString.call(config) !== "[object Object]") {
    throw new Error("second param must be object {}");
  }

  if (typeof protocols !== "string" && !Array.isArray(protocols)) {
    throw new Error("first param must be Array or string");
  }

  if (typeof protocols === "string") {
    fin = {
      parentNodeName: protocols,
    };
  } else {
    protocols.forEach((val) => {
      if (typeof val !== "string" && !Array.isArray(val)) {
        throw new Error("item must be Array or string");
      }
      if (Array.isArray(val)) {
        let [parentNodeName, screen] = val;
        let obj = {};
        if (Object.prototype.toString.call(screen) !== "[object Object]") {
          throw new Error(
            "if protocol item is array,the second item must be an object"
          );
        } else {
          obj["parentNodeName"] = parentNodeName;
          for (let key in screen) {
            obj[`data.${key}`] = screen[key];
          }
          protocol_list.push(obj);
        }
      } else {
        protocol_list.push({ parentNodeName: val });
      }
    });
    fin = {
      $and: [
        {
          $or: [...protocol_list],
        },
      ],
    };
  }
  const query = {
    find: {
      ...fin,
      metaId: process.env.VUE_APP_IDtags,
      ...config,
      // isValid: true,
    },
    sort: {
      timestamp,
    },
    skip,
    limit,
  };
  const base64 = btoa(JSON.stringify(query));
  return Api.ajaxGet(
    "/v2showMANDB/api/v1/query/queryFindMetaData/" + base64,
    {},
    walletApi()
  );
};
// 获取范数据
export const getMetaidData = (config, skip = 0, limit = 10, timestamp = -1) => {
  if (!config) {
    config = {};
  }
  if (Object.prototype.toString.call(config) !== "[object Object]") {
    throw new Error("second param must be object {}");
  }
  const query = {
    find: {
      metaId: process.env.VUE_APP_IDtags,
      ...config,
      // isValid: true,
    },
    sort: {
      timestamp,
    },
    skip,
    limit,
  };
  const base64 = btoa(JSON.stringify(query));
  return Api.ajaxGet(
    "/v2showMANDB/api/v1/query/queryFindMetaData/" + base64,
    {},
    walletApi()
  );
};

export const queryMetaData = async (params) => {
  const query = {
    find: {
      metaId: process.env.VUE_APP_IDtags,
      ...params,
      // isValid: true,
    },
  };
  const base64 = btoa(JSON.stringify(query));
  return Api.ajaxGet(
    "/v2showMANDB/api/v1/query/queryFindMetaData/" + base64,
    {},
    walletApi()
  );
};
export const getNftInfo = async (data) => {
  return axios({
    baseURL: walletApi(),
    method: "post",
    url: "/serviceapi/api/v1/showService/getNftGenesisMetaId",
    data,
  });
};
export const postFileBrfcNodeInfo = async (params) => {
  return Api.ajaxPost(
    "/issue/api/sensible/nftonshow/uploadfile",
    params,
    walletApi()
  );
};
export const postNftIssueBrfcNodeInfo = async (params) => {
  return Api.ajaxPost(
    "/issue/api/sensible/nftonshow/metatxid",
    params,
    walletApi()
  );
};
export const nftIssue = async (params) => {
  return Api.ajaxPost(
    "/issue/api/sensible/nftonshow/issue",
    params,
    walletApi()
  );
};
export const nftSell = async (params) => {
  return Api.ajaxPost(
    "/issue/api/sensible/nftonshow/sell",
    params,
    walletApi()
  );
};
export const postBirdFileBrfcNodeInfo = async (params) => {
  return Api.ajaxPost(
    "/issue/api/sensible/bird/uploadfile",
    params,
    walletApi()
  );
};
export const postBirdNftIssueBrfcNodeInfo = async (params) => {
  return Api.ajaxPost("/issue/api/sensible/bird/metatxid", params, walletApi());
};
export const nftBirdIssue = async (params) => {
  return Api.ajaxPost("/issue/api/sensible/bird/issue", params, walletApi());
};
// 签名
export const sign = async (params) => {
  return Api.ajaxPost("/showpaycore/api/v1/wallet/sign", params, walletApi());
};
// 获取验证码图片
export const getImgCode = async (data, type) => {
  return axios({
    baseURL: walletApi(),
    method: "post",
    url:
      type === "oauth"
        ? "/oauth2/api/v1/user/codeImage"
        : "/showpaycore/api/v1/user/codeImage",
    data,
  });
};
// 解绑
export const unbindWebot = async (params) => {
  return axios({
    method: "get",
    url: "https://webot.sv/callback/showbuzz/unbind",
    params,
  });
};
// 绑定
export const bindWebot = async (params) => {
  return axios({
    method: "get",
    url: "https://webot.sv/callback/showbuzz/bind",
    params,
  });
};
// 验签
export const signCheck = async (params) => {
  return Api.ajaxPost(
    "/showpaycore/api/v1/wallet/sign/check",
    params,
    walletApi()
  );
};
// 查询绑定信息
export const queryWebot = async (params) => {
  return axios({
    method: "get",
    url: "https://webot.sv/showbuzz/query",
    params,
  });
};

// 获取账号 tag
export const getTag = async (params) => {
  return Api.ajaxPost("/showpaycore/api/v1/user/getTag", params, walletApi());
};

// 获取推荐人信息
export const getReferrerInfo = async (params) => {
  return Api.ajaxPost(
    "/showpaycore/api/v1/recommend/getMetaIdByInviteCode",
    params,
    walletApi()
  );
};

// 获取推荐码
export const getInviteCode = async (params) => {
  return Api.ajaxPost(
    "/showpaycore/api/v1/user/getpromotioncode",
    params,
    walletApi()
  );
};
// 获取Token交易记录
export const getSensibleTransferList = async ({
  metaid,
  startTime,
  endTime,
  page,
  pageSize,
}) => {
  return Api.ajaxPost(
    "/serviceapi/api/v1/showService/getMySensibleTransferList",
    {
      data: JSON.stringify({
        metaid,
        startTime,
        endTime,
        page,
        pageSize,
      }),
    },
    walletApi()
  );
};

const getMetaSVSig = async (path) => {
  const url = walletApi() + "/metasv-signature/signature";
  return axios({
    method: "post",
    url: url,
    data: {
      path: path,
    },
  });
  return "";
};

export const callMetaSVApi = async (path, params) => {
  const signRes = await getMetaSVSig(path);
  let headers = {};
  if (signRes) {
    const signature = signRes.data.data;
    headers = {
      "MetaSV-Timestamp": signature.timestamp,
      "MetaSV-Client-Pubkey": signature.publicKey,
      "MetaSV-Nonce": signature.nonce,
      "MetaSV-Signature": signature.signEncoded,
    };
  }

  let metaSvUrl;
  if (store.state.chain === "BSV") {
    metaSvUrl = "https://apiv2.metasv.com";
  } else {
    metaSvUrl = "https://mainnet.mvcapi.com";
  }

  const url = metaSvUrl + path;

  return axios({
    method: "get",
    url: url,
    headers,
    params: params,
  }).then((res) => {
    return res.data;
  });
};
