var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "home" }, [
    _c(
      "div",
      { staticClass: "homeWrap" },
      [
        _c("h1", { staticClass: "title" }, [_vm._v("UBC纪念卡")]),
        _c(
          "el-button",
          {
            staticClass: "uppicbtn",
            attrs: { type: "primary" },
            on: { click: _vm.createMetafileBrfc },
          },
          [_vm._v("上传图片")]
        ),
        _c(
          "van-tabs",
          {
            model: {
              value: _vm.nftactive,
              callback: function ($$v) {
                _vm.nftactive = $$v
              },
              expression: "nftactive",
            },
          },
          [
            _c("van-tab", { attrs: { title: "上传nft信息" } }, [
              _c(
                "div",
                { staticClass: "form" },
                [
                  _c(
                    "van-form",
                    { on: { submit: _vm.createIssueBrfc } },
                    [
                      _c("van-field", {
                        attrs: {
                          name: "nftgenesisId",
                          label: "GenesisId",
                          placeholder: "填写genesisId",
                          required: "",
                        },
                        model: {
                          value: _vm.nftgenesisId,
                          callback: function ($$v) {
                            _vm.nftgenesisId = $$v
                          },
                          expression: "nftgenesisId",
                        },
                      }),
                      _c("van-field", {
                        attrs: {
                          name: "genesisTxid",
                          label: "GenesisTxid",
                          placeholder: "填写genesisTxid,必须是正确的tx格式",
                          required: "",
                        },
                        model: {
                          value: _vm.nftgenesisTxid,
                          callback: function ($$v) {
                            _vm.nftgenesisTxid = $$v
                          },
                          expression: "nftgenesisTxid",
                        },
                      }),
                      _c("van-field", {
                        attrs: {
                          name: "nftissuerName",
                          label: "发布者名称",
                          placeholder: "发布者名称",
                          required: "",
                        },
                        model: {
                          value: _vm.nftissuerName,
                          callback: function ($$v) {
                            _vm.nftissuerName = $$v
                          },
                          expression: "nftissuerName",
                        },
                      }),
                      _c("van-field", {
                        attrs: {
                          name: "receiverAddress",
                          label: "接收地址",
                          placeholder: "请填写接收的地址(销毁此处不填)",
                          required: "",
                        },
                        model: {
                          value: _vm.receiverAddress,
                          callback: function ($$v) {
                            _vm.receiverAddress = $$v
                          },
                          expression: "receiverAddress",
                        },
                      }),
                      _c("van-field", {
                        attrs: {
                          name: "nftdesc",
                          label: "描述",
                          placeholder: "请填写描述",
                          required: "",
                        },
                        model: {
                          value: _vm.nftdesc,
                          callback: function ($$v) {
                            _vm.nftdesc = $$v
                          },
                          expression: "nftdesc",
                        },
                      }),
                      _c("van-field", {
                        attrs: {
                          name: "nftname",
                          label: "NFT名称",
                          placeholder: "请填写nft名称",
                          required: "",
                        },
                        model: {
                          value: _vm.nftname,
                          callback: function ($$v) {
                            _vm.nftname = $$v
                          },
                          expression: "nftname",
                        },
                      }),
                      _c("van-field", {
                        attrs: {
                          name: "nftwebsite",
                          label: "网站介绍",
                          placeholder: "网站介绍,可以为空",
                          required: "",
                        },
                        model: {
                          value: _vm.nftwebsite,
                          callback: function ($$v) {
                            _vm.nftwebsite = $$v
                          },
                          expression: "nftwebsite",
                        },
                      }),
                      _c(
                        "div",
                        { staticStyle: { margin: "16px" } },
                        [
                          _c(
                            "van-button",
                            {
                              attrs: {
                                round: "",
                                block: "",
                                type: "info",
                                "native-type": "submit",
                                loading: _vm.isLoading,
                              },
                            },
                            [_vm._v("GENESIS")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("van-tab", { attrs: { title: "铸造" } }, [
              _c(
                "div",
                { staticClass: "form" },
                [
                  _c(
                    "van-form",
                    { on: { submit: _vm.createIssue } },
                    [
                      _c("van-field", {
                        attrs: {
                          name: "nftgenesisId",
                          label: "GenesisId",
                          placeholder: "填写genesisId",
                          required: "",
                        },
                        model: {
                          value: _vm.nftgenesisId,
                          callback: function ($$v) {
                            _vm.nftgenesisId = $$v
                          },
                          expression: "nftgenesisId",
                        },
                      }),
                      _c("van-field", {
                        attrs: {
                          name: "nftSensibleId",
                          label: "SensibleId",
                          placeholder: "填写genesisId",
                          required: "",
                        },
                        model: {
                          value: _vm.nftSensibleId,
                          callback: function ($$v) {
                            _vm.nftSensibleId = $$v
                          },
                          expression: "nftSensibleId",
                        },
                      }),
                      _c("van-field", {
                        attrs: {
                          name: "genesisTxid",
                          label: "GenesisTxid",
                          placeholder: "填写genesisTxid,必须是正确的tx格式",
                          required: "",
                        },
                        model: {
                          value: _vm.nftgenesisTxid,
                          callback: function ($$v) {
                            _vm.nftgenesisTxid = $$v
                          },
                          expression: "nftgenesisTxid",
                        },
                      }),
                      _c("van-field", {
                        attrs: {
                          name: "codehash",
                          label: "codeHash",
                          placeholder: "填写codeHash",
                          required: "",
                        },
                        model: {
                          value: _vm.codehash,
                          callback: function ($$v) {
                            _vm.codehash = $$v
                          },
                          expression: "codehash",
                        },
                      }),
                      _c("van-field", {
                        attrs: {
                          name: "receiverAddress",
                          label: "接收地址",
                          placeholder: "请填写接收的地址(销毁此处不填)",
                          required: "",
                        },
                        model: {
                          value: _vm.receiverAddress,
                          callback: function ($$v) {
                            _vm.receiverAddress = $$v
                          },
                          expression: "receiverAddress",
                        },
                      }),
                      _c(
                        "div",
                        { staticStyle: { margin: "16px" } },
                        [
                          _c(
                            "van-button",
                            {
                              attrs: {
                                round: "",
                                block: "",
                                type: "info",
                                "native-type": "submit",
                                loading: _vm.isLoading,
                              },
                            },
                            [_vm._v("ISSUE")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }