// api父类

import * as ajax from "@/common/js/ajax";
import { returnStatus } from "../config/code";
import router from "@/router.js";
import { Message } from "element-ui";
class ApiBase {
  // ajaxPost
  static ajaxPost(url, reqData, target, config) {
    return ApiBase._ajax(url, reqData, target, "post", config);
  }

  // ajaxGet
  static ajaxGet(url, reqData, target, config) {
    return ApiBase._ajax(url, reqData, target, "get", config);
  }

  // ajaxGetUseBase64
  static ajaxGetUseBase64(url, reqData, target, config) {
    return ApiBase._ajax(url, reqData, target, "get", config, true);
  }
  // ajax
  static _ajax(url, reqData, target, type, config, usebase64 = false) {
    if(!config){
      config = {}
    }
    let func =
      type === "post"
        ? ajax.createHttpPost
        : usebase64
        ? ajax.createHttpGetWithBase64
        : ajax.createHttpGet;
    return new Promise((resolve, reject) => {
      func(url, reqData, target, config)
        .then(resp => {
          switch (resp.code) {
            case returnStatus.NORMAL:
              // 正常数据
              resolve(resp.result);
              break;
            case returnStatus.NOT_LOGIN:
              Message.error({
                message:"Login information has expired, please login again",
                onClose: () => {
                  localStorage.clear()
                  sessionStorage.clear()
                  router.replace({
                    name: "login"
                  });
                }
              });
              break;
            case 0:
              resolve(resp.data || resp.result || resp.jwtToken || resp.msg);
              break;
            case 601:
              resolve(resp.data);
              break;
            default:
              throw new Error(resp.error);
          }
        })
        .catch(err => {
          // message.warning(err.msg || err.message || '未知错误')
          reject(err);
        });
    });
  }
}

export default ApiBase;
