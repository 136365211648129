<template>
  <div class="footer" :class="getRouter ? 'footer_none' : ''">
    <div class="footer_box">
      <div>
        <span> Copyright @ShowPay LLC. </span>
      </div>
      <div class="contact">
        <span>
          <!-- 联系我们： -->
          {{ $t("contactUs") }}:
        </span>
        <!-- <a href="mailto:showmoney@showpay.io" target="_blank">
          <img src="../assets/images/icon_email@2x.png" alt="" />
          email: showmoney@showpay.io
        </a> -->
        <a href="https://twitter.com/showpayio" target="_blank">
          <img src="../assets/images/icon_twitter@2x.png" alt="" />
          @showpayio
        </a>
        <a href="https://medium.com/@showpay" target="_blank">
          <img src="../assets/images/icon_medium@2x.png" alt="" />
          @showpay
        </a>
      </div>
      <div class="about">
        <span @click="opAlter()">
          <!-- 关于ShowMoney -->
          {{ $t("about") }}
        </span>
        <span @click="opAlter()">
          <!-- ShowMoney开放平台 -->
          {{ $t("terrace") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    opAlter() {
      return;
      this.gotoUrl("open");
    },
  },
  computed: {
    getRouter() {
      if (this.$route.path === "/sign" || this.$route.path === "/login") {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    "$route.path": function () {
      // console.log(this.$route.path)
    },
  },
  created() {
    // console.log(this.$route.path)
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: absolute;
  bottom: 0;
  width: 100vw;
  display: flex;
  align-items: center;
  min-height: 7vh;
  background: rgba(48, 49, 51, 0.25);
}
.footer_none {
  position: unset;
  background: #27282a;
}
.footer_box {
  max-width: 1280px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  span {
    color: #fff;
    font-size: 14px;
  }
  img {
    width: 24px;
    height: 24px;
    margin: 0 10px 0 15px;
    vertical-align: middle;
  }
  a {
    color: #fff;
    font-size: 14px;
  }
  .about {
    span {
      border-radius: 20px;
      height: 35px;
      padding: 0 10px;
      border: 1px solid #fff;
      font-size: 12px;
      cursor: pointer;
      line-height: 35px;
      margin-right: 15px;
    }
  }
}
.contact {
  margin: 0 10px;
  padding: 0 15px;
  min-height: 24px;
  border: 1px solid #fff;
  border-top: none;
  border-bottom: none;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1280px) {
  .footer {
    position: unset;
    background: #27282a;
  }
  .footer_box {
    max-height: unset;
  }
  .footer_box > div {
    margin-top: 15px;
  }
  .footer_box a {
    width: 100%;
  }
  .contact {
    flex-wrap: wrap;
  }
}
</style>
