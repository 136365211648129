/**
 * code定义
 */

// 服务端返回status定义
const returnStatus = {
  // 正常返回
  NORMAL: 200,
  // 未登录或登录过期
  NOT_LOGIN: 602
};

export { returnStatus };
