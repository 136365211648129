import axios from "axios";
import store from "@/store.js";
import { walletApi } from "./baseApi";
const suffix = process.env.VUE_APP_SUFFIX;
let logout = false;
// axios配置
const axiosBaseConfig = {
  // baseURL: walletApi(),
  timeout: 20000,
  headers: {
    "Content-Type": "application/json",
    // 'Content-Type': 'application/json'
  },
  method: "post",
  // 跨域请求，是否带上认证信息
  withCredentials: false, // default
  // http返回的数据类型
  // 默认是json，可选'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
  responseType: "json", // default
  // http请求返回状态码检查
  validateStatus: (status) => status >= 200 && status < 300, // default
  // 请求数据预处理
  transformRequest: [
    (data) => {
      // 请求对象转换成jon字符串，formdata数据除外
      if (typeof data === "object" && !(data instanceof FormData)) {
        return JSON.stringify(data);
      }
      return data;
    },
  ],
  // 返回数据预处理
  transformResponse: [
    (respData, headers) => {
      // 针对ie处理一下字符串情况
      const ct = headers["content-type"];
      if (ct && ct.indexOf("application/json") !== -1) {
        if (typeof respData === "string") {
          return JSON.parse(respData);
        }
      }
      return respData;
    },
  ],
};
// axios 实例
const axiosInstance = axios.create(axiosBaseConfig);
axiosInstance.interceptors.request.use((config) => {
  let userData = store.state.userData;
  // console.log(store.state.userData)
  // console.log(userData)
  console.log("config.url", config.url);
  let path = [
    "/apis/uploadFileApiForShowMoney",
    "/api/serviceapi/api/v1/metago/getShowDIDUserInfo",
    "/api/serviceapi/api/v1/metago/getInfoAddressAndTxId",
    "/api/api/v1/user/senduserbsvaddress",
    "/serviceapi/api/v1/",
    "/wallet/api/v1/",
  ];
  let NoHear = path.some((item) => {
    return (
      config.url.indexOf(item) > -1 ||
      config.method === "get" ||
      config.url.indexOf("oauth2") >= 0 ||
      config.url.indexOf("apprs") >= 0
    );
  });
  // console.log(NoHear)
  if (userData.token && !NoHear) {
    // console.log(store.state)
    // config.data = JSON.parse(config.data)
    let re =
      /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,16}$/;
    config.headers["accessKey"] = userData.token;
    config.headers["timestamp"] = Date.now();
    config.headers["userName"] = re.test(
      userData.register == "email" ? userData.email : userData.phone
    )
      ? userData.email
      : userData.phone
      ? userData.phone.replace("+", "")
      : userData.phone;
    // config.data['headers'] = JSON.stringify(config.data['headers'])
    // config.data = JSON.stringify(config.data)
  }
  // console.log(config)
  return config;
});
axiosInstance.interceptors.response.use(
  (resp) => resp,
  (error) => {
    // 当返回错误时
    if (axios.isCancel(error)) {
      throw { message: "请求被取消" };
    }
    if ("code" in error && error.code === "ECONNABORTED") {
      throw { message: "请求超时" };
    }
    if ("message" in error && error.message === "Network Error") {
      throw { message: "网络错误" };
    }
    if ("response" in error) {
      throw { message: `请求失败，HTTP CODE：${error.response.status}` };
    }
    return Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use((resp) => {
  if (resp?.data?.code === 601) {
    resp.data.data.code = 601;
    return resp;
  }
  if (resp?.data?.code === 602) {
    return resp;
  }
  if (resp.data?.code !== 200 && resp.data?.code !== 0) {
    throw { message: resp.data.error || resp.data.msg };
  }
  return resp;
});
const axiosPost = (url, config, reqData) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  return axiosInstance
    .post(url, reqData, {
      cancelToken: source.token,
      ...config,
    })
    .then((resp) => {
      return resp.data;
    });
};

const axiosGet = (url, config, reqData) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let reqArray = [];
  Object.keys(reqData).forEach((key) => {
    reqArray.push(`${key}=${encodeURIComponent(reqData[key])}`);
  });
  let newUrl = `${url}${reqArray.length > 0 ? "?" + reqArray.join("&") : ""}`;

  return axiosInstance
    .get(newUrl, {
      cancelToken: source.token,
      ...config,
    })
    .then((resp) => resp.data);
};

const axiosGetWithBase64 = (url, config, reqData) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const base64 = btoa(JSON.stringify(reqData));
  let newUrl = `${url}${base64}`;

  return axiosInstance
    .get(newUrl, {
      cancelToken: source.token,
      ...config,
    })
    .then((resp) => resp.data);
};

// post json字符串，即text/plain形式
const createHttpPost = (url, reqData, target, config) => {
  let newUrl;
  if (target) {
    newUrl = `${target}${url}${suffix}`;
  } else {
    newUrl = `${walletApi()}${url}${suffix}`;
  }

  return axiosPost(newUrl, config, reqData);
};

const createHttpGet = (url, reqData, target, config) => {
  let newUrl;
  if (target) {
    newUrl = `${target}${url}${suffix}`;
  } else {
    newUrl = `${walletApi()}${url}${suffix}`;
  }

  return axiosGet(newUrl, config, reqData);
};

const createHttpGetWithBase64 = (url, reqData, target, config) => {
  let newUrl;
  if (target) {
    newUrl = `${target}${url}${suffix}`;
  } else {
    newUrl = `${walletApi()}${url}${suffix}`;
  }

  return axiosGetWithBase64(newUrl, config, reqData);
};

export {
  createHttpPost,
  createHttpGet,
  createHttpGetWithBase64,
  axiosPost,
  axiosGet,
};
