<template>
  <div class="home">
    <div class="homeWrap">
      <h1 class="title">UTXO归集</h1>
      <el-button @click="mergeUtxo" type="primary" :loading="isLoading"
        >合并UTXO</el-button
      >
      <el-button @click="splitUtxo" type="primary" style="display: none"
        >拆分UTXO</el-button
      >
      <el-dialog
        :visible.sync="dialogTableVisible"
        :close-on-click-modal="false"
      >
        <el-progress
          :text-inside="true"
          :stroke-width="26"
          :percentage="percentage"
          :format="progress"
        ></el-progress>
      </el-dialog>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
export default {
  name: "IssueNft",
  components: {},
  data() {
    return {
      isLoading: false,
      fullLoading: "",
      dialogTableVisible: false,
      percentage: 0,
      utxocount: 0,
      finish: 0,
    };
  },
  methods: {
    async mergeUtxo() {
      let result;
      this.$confirm(`确认合并？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.dialogTableVisible = true;
          const chunk = 500;
          const miner = 300;
          result = await this.$store.state.metanet.mergeUtxo(50000);
          this.utxocount = result.length;
          let finish = 0;
          let utxoAdd = {};
          let utxoAddrCount = [];
          let count = Math.ceil(result.length / chunk);
          let filterAdd = "";
          result.forEach((item) => {
            if (!utxoAdd[item.address]) {
              utxoAdd[item.address] = 1;
              return;
            }
            utxoAdd[item.address] += 1;
          });
          for (let item in utxoAdd) {
            utxoAddrCount.push(utxoAdd[item]);
          }
          utxoAddrCount.sort((a, b) => b - a);
          for (let item in utxoAdd) {
            if (utxoAdd[item] === utxoAddrCount[0]) {
              filterAdd = item;
            }
          }
          result = result.filter((item) => item.address === filterAdd);
          for (let i = 0; i < Math.ceil(count); i++) {
            const utxo = result.splice(0, chunk);
            await this.mergeBroad(utxo);
            finish += utxo.length;
            this.percentage = Math.floor((finish / this.utxocount) * 100);
            this.finish = finish;
          }
          this.finish = 0;
          this.percentage = 0;
          this.utxocount = 0;
          this.$message.success("归集完成");
          this.dialogTableVisible = false;
        })
        .catch((err) => {
          this.dialogTableVisible = false;
          if (err === "cancel") {
            return this.$message.warning(`已取消`);
          }
          if (err?.resData) {
            return this.$message.error(
              `错误:${JSON.parse(err.resData.body).message},如有疑问请联系客服`
            );
          }
          return this.$message.error(
            `错误:${err.message || err?.data?.message},如有疑问请联系客服`
          );
        });
    },
    progress(percentage) {
      if (percentage === 0) {
        return "数据准备中。。。。";
      } else {
        return `${this.finish}/${this.utxocount}`;
      }
    },
    async mergeBroad(utxos) {
      const transOptions = {
        utxos,
        change: this.$store.state.metanet.rootAddress.address,
        payAll: true,
      };
      const nodeTx = await this.$store.state.metanet.makeTx(transOptions);
      return this.$store.state.metanet.sendMetaSvTx(nodeTx.toString(), false);
    },
    async splitUtxo() {
      const result = await this.$store.state.metanet.splitUtxo();
      if (result.code == 200) {
        this.$message.success("拆分完成");
      } else {
        this.$message.error("拆分失败");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.uppicbtn {
  position: absolute;
  z-index: 1111;
}
.title {
  margin-bottom: 10px;
}
.home {
  background-color: #fff;
  width: 650px;
  height: 100%;
  margin: 0 auto;
}
.genesis {
  margin-top: 15px;
}
.homeWrap {
  padding-top: 80px;
}
.form {
  margin-top: 50px;
}
.mart10 {
  margin-top: 10px;
}
::v-deep .el-dialog {
  border-radius: 10px;
}
::v-deep .el-progress-bar__innerText {
  color: #606266;
}
::v-deep .van-tab__text--ellipsis {
  font-size: 20px;
}
::v-deep .van-cell {
  height: 70px;
  line-height: 70px;
}
::v-deep .van-field__control--custom {
  min-height: 70px;
}
input:focus,
textarea:focus {
  outline: none;
}
@media screen and (max-width: 500px) {
  .home {
    width: 380px;
  }
}
</style>
