<template>
  <div class="home">
    <div class="homeWrap">
      <van-tabs v-model="active">
        <van-tab title="生成NFT合约">
          <van-tabs v-model="nftactive" class="nftjenesis">
            <div
              class="step"
              :class="{
                hide: nftactive == 2
              }"
            >
              <span class="active"><em>1</em>填写NFT系列信息</span>
              <span>····</span>
              <span
                :class="{
                  active: nftactive == 1
                }"
                ><em>2</em>铸造NFT</span
              >
            </div>
            <van-tab>
              <div class="form">
                <van-form @submit="onNFTGenesis">
                  <van-field
                    v-model="nftTotal"
                    name="nftTotal"
                    label="发行总数"
                    type="digit"
                    placeholder="请填写你的NFT发行总量"
                    autocomplete="off"
                    required
                  />
                  <van-field
                    v-model="nftSeriesName"
                    name="nftSeriesName"
                    label="系列名称"
                    placeholder="请填写token 系列名称"
                    required
                  />
                  <p class="genesis" v-if="nftgenesisId">
                    {{ `GenesisId:${nftgenesisId}` }}
                  </p>
                  <div style="margin: 16px">
                    <van-button
                      round
                      block
                      type="info"
                      native-type="submit"
                      :loading="isLoading"
                      >下一步</van-button
                    >
                  </div>
                </van-form>
              </div>
            </van-tab>
            <van-tab>
              <div class="form">
                <van-form @submit="onNFTIssue">
                  <van-field
                    v-model="nftgenesisId"
                    name="nftgenesisId"
                    label="GenesisId"
                    placeholder="填写genesisId"
                    required
                  />
                  <van-field
                    v-model="nftSensibleId"
                    name="nftSensibleId"
                    label="SensibleId"
                    placeholder="填写genesisId"
                    required
                  />
                  <van-field
                    v-model="nftgenesisTxid"
                    name="genesisTxid"
                    label="GenesisTxid"
                    placeholder="填写genesisTxid,必须是正确的tx格式"
                    required
                  />
                  <van-field
                    v-model="codehash"
                    name="codehash"
                    label="CodeHash"
                    placeholder="填写codeHash"
                    required
                  />
                  <van-field
                    class="mt-40"
                    v-model="receiverAddress"
                    name="receiverAddress"
                    label="接收地址"
                    placeholder="请填写NFT的接收地址"
                    required
                  />
                  <van-field
                    v-model="nftname"
                    name="nftname"
                    label="通证名称"
                    placeholder="请填写NFT名称"
                    required
                  />
                  <van-field
                    v-model="nftissuerName"
                    name="nftissuerName"
                    label="发布者名称"
                    placeholder="请填写NFT发布者名称"
                    required
                  />
                  <van-field
                    v-model="nftdesc"
                    name="nftdesc"
                    label="通证描述"
                    placeholder="请填写NFT描述"
                    required
                  />
                  <van-field
                    v-model="nfticon"
                    name="nfticon"
                    label="通证图标"
                    placeholder="请填图标TX,可以为空"
                    required
                  />
                  <van-field
                    v-model="nftwebsite"
                    name="nftwebsite"
                    label="网站介绍"
                    placeholder="NFT相关的网站介绍，可以为空"
                  />
                  <div class="mt-40">
                    <van-button
                      round
                      block
                      type="info"
                      native-type="submit"
                      :loading="isLoading"
                      >铸造NFT</van-button
                    >
                  </div>
                </van-form>
              </div>
            </van-tab>
            <van-tab class="succontent">
              <i class="el-icon-success"></i>
              <p>NFT铸造成功</p>
              <div class="detail">
                <p>
                  <span>通证名称：</span><span>{{ nftname }}</span>
                </p>
                <p>
                  <span>铸造者：</span><span>{{ nftissuerName }}</span>
                </p>
                <p>
                  <span>系列名称：</span><span>{{ nftSeriesName }}</span>
                </p>
                <p>
                  <span>发行总量：</span><span>{{ nftTotal }}</span>
                </p>
              </div>
              <van-button
                round
                block
                type="info"
                @click="nftactive = 1"
                class="finishbtn"
                >完成</van-button
              >
              <van-button
                round
                block
                type="info"
                @click="gotoUrl('index')"
                class="viewbtn"
                >点击查看</van-button
              >
            </van-tab>
          </van-tabs>
        </van-tab>
        <van-tab title="生成FT合约">
          <van-tabs v-model="ftactive" class="ftjenesis">
            <div
              class="step"
              :class="{
                hide: ftactive == 2
              }"
            >
              <span class="active"><em>1</em>填写FT系列信息</span>
              <span>····</span>
              <span
                :class="{
                  active: ftactive == 1
                }"
                ><em>2</em>发行FT</span
              >
            </div>
            <van-tab>
              <div class="form">
                <van-form @submit="onFTGenesis">
                  <van-field
                    v-model="ftName"
                    name="ftName"
                    label="FT名称"
                    placeholder="请填写FT合约名称"
                    required
                  />
                  <van-field
                    v-model="ftSymbol"
                    name="ftSymbol"
                    label="名称缩写"
                    placeholder="请填写FT合约的缩写名称 (FT单位)"
                    required
                  />
                  <van-field
                    v-model="decimalNum"
                    name="ftDecimalNum"
                    label="小数点位数"
                    type="digit"
                    placeholder="请设置FT的小书点位数，如: 8"
                    required
                  />
                  <van-field
                    v-model="ftissuerName"
                    name="ftissuerName"
                    label="发布者名称"
                    placeholder="请填写发布者名称"
                    required
                  />
                  <van-field
                    v-model="ftdesc"
                    name="ftdesc"
                    label="FT描述"
                    placeholder="请填写FT描述"
                    required
                  />
                  <van-field
                    v-model="fticon"
                    name="fticon"
                    label="FT图标"
                    placeholder="请上传图标/图片"
                    required
                  />
                  <van-field
                    v-model="ftwebsite"
                    name="ftwebsite"
                    label="网站介绍"
                    placeholder="FT相关的网站介绍，可以为空"
                  />
                  <p class="genesis" v-if="ftgenesisId">
                    {{ `GenesisId:${ftgenesisId}` }}
                  </p>
                  <p class="genesis" v-if="ftgenesisprotocol">
                    {{ `GenesisId:${ftgenesisprotocol}` }}
                  </p>
                  <div style="margin: 16px">
                    <van-button
                      round
                      block
                      type="info"
                      native-type="submit"
                      :loading="isLoading"
                      >下一步</van-button
                    >
                  </div>
                </van-form>
              </div>
            </van-tab>
            <van-tab>
              <div class="form">
                <van-form @submit="onFTIssue">
                  <van-field
                    v-model="ftgenesisId"
                    name="ftgenesisId"
                    label="GenesisId"
                    placeholder="填写genesisId"
                    required
                  />
                  <van-field
                    v-model="codehash"
                    name="codehash"
                    label="codeHash"
                    placeholder="填写codeHash"
                    required
                  />
                  <van-field
                    v-model="sensibleId"
                    name="sensibleId"
                    label="sensibleId"
                    placeholder="填写sensibleId"
                    required
                  />
                  <van-field
                    class="mt-40"
                    v-model="ftTotal"
                    name="ftTotal"
                    label="发行总数量"
                    placeholder="请填写你的FT发行总量"
                    type="digit"
                    required
                  />
                  <van-field
                    v-model="ftreceiverAddress"
                    name="ftreceiverAddress"
                    label="接收地址"
                    placeholder="请填写FT的接收地址"
                    required
                  />
                  <van-field name="isAddPub" label="是否增发">
                    <template #input>
                      <van-radio-group
                        v-model="radio"
                        direction="horizontal"
                        required
                      >
                        <van-radio name="1">是</van-radio>
                        <van-radio name="0">否</van-radio>
                      </van-radio-group>
                    </template>
                  </van-field>
                  <div style="margin: 16px">
                    <van-button
                      round
                      block
                      type="info"
                      native-type="submit"
                      :loading="isLoading"
                      >发行FT</van-button
                    >
                  </div>
                </van-form>
              </div>
            </van-tab>
            <van-tab class="succontent">
              <i class="el-icon-success"></i>
              <p>FT发行成功！</p>
              <div class="detail">
                <p>
                  <span>通证名称：</span><span>{{ ftName }}</span>
                </p>
                <p>
                  <span>发行方：</span><span>{{ ftissuerName }}</span>
                </p>
                <p>
                  <span>发行总量：</span><span>{{ ftTotal }} {{ ftName }}</span>
                </p>
              </div>
              <van-button
                round
                block
                type="info"
                @click="ftactive = 1"
                class="finishbtn"
                >完成</van-button
              >
              <van-button
                round
                block
                type="info"
                @click="gotoUrl('index')"
                class="viewbtn"
                >点击查看</van-button
              >
            </van-tab>
          </van-tabs>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

// import {NftMgr,FtMgr} from '../bundle/bundle'
export default {
  name: "Home",
  components: {},
  data() {
    return {
      genesisTxid: "",
      ftName: "",
      receiverAddress: "",
      ftreceiverAddress: "",
      ftVersion: "",
      nftTotal: "",
      ftTotal: "",
      ftDecimalNum: "",
      ftSymbol: "",
      active: 0,
      nftactive: 0,
      ftactive: 0,
      radio: "0",
      yes: "1",
      no: "0",
      ftgenesisprotocol: "",
      transferLoding: false,
      isLoading: false,
      decimalNum: "",
      nftgenesisId: "",
      nftgenesisTxid: "",
      nftSensibleId: "",
      nftSeriesName: "",
      codehash: "",
      sensibleId: "",
      nftsymbol: "",
      nftname: "",
      nftdesc: "",
      nfticon: "",
      nftwebsite: "",
      nftissuerName: "",
      ftgenesisId: "",
      ftdesc: "",
      fticon: "",
      ftwebsite: "",
      ftissuerName: "",
      metafileP: "",
      transfernftP: "",
      issueFtP: ""
      // network:'mainnet',
      // privateKey:'',
      // metaTxId:'a24413a581f06969d6b7094d93313a5f6e69f43dbb6c2eab8274bafea9d6b749',
    };
  },
  mounted() {},
  methods: {
    async onNFTGenesis(values) {
      let result;
      this.isLoading = true;
      try {
        result = await this.$store.state.metanet.genesisNFT({
          nftTotal: +values.nftTotal,
          signersRaw: [
            {
              satotxApiPrefix: "https://s1.satoplay.cn,https://s1.satoplay.com",
              satotxPubKey:
                "2c8c0117aa5edba9a4539e783b6a1bdbc1ad88ad5b57f3d9c5cba55001c45e1fedb877ebc7d49d1cfa8aa938ccb303c3a37732eb0296fee4a6642b0ff1976817b603404f64c41ec098f8cd908caf64b4a3aada220ff61e252ef6d775079b69451367eda8fdb37bc55c8bfd69610e1f31b9d421ff44e3a0cfa7b11f334374827256a0b91ce80c45ffb798798e7bd6b110134e1a3c3fa89855a19829aab3922f55da92000495737e99e0094e6c4dbcc4e8d8de5459355c21ff055d039a202076e4ca263b745a885ef292eec0b5a5255e6ecc45534897d9572c3ebe97d36626c7b1e775159e00b17d03bc6d127260e13a252afd89bab72e8daf893075f18c1840cb394f18a9817913a9462c6ffc8951bee50a05f38da4c9090a4d6868cb8c955e5efb4f3be4e7cf0be1c399d78a6f6dd26a0af8492dca67843c6da9915bae571aa9f4696418ab1520dd50dd05f5c0c7a51d2843bd4d9b6b3b79910e98f3d98099fd86d71b2fac290e32bdacb31943a8384a7668c32a66be127b74390b4b0dec6455"
            },
            {
              satotxApiPrefix: "https://cnsatotx.showpay.top",
              satotxPubKey:
                "5b94858991d384c61ffd97174e895fcd4f62e4fea618916dc095fe4c149bbdf1188c9b33bc15cbe963a63b2522e70b80a5b722ac0e6180407917403755df4de27d69cc115c683a99face8c823cbccf73c7f0d546f1300b9ee2e96aea85542527f33b649f1885caebe19cf75d9a645807f03565c65bd4c99c8f6bb000644cfb56969eac3e9331c254b08aa279ceb64c47ef66be3f071e28b3a5a21e48cdfc3335d8b52e80a09a104a791ace6a2c1b4da88c52f9cc28c54a324e126ec91a988c1fe4e21afc8a84d0e876e01502386f74e7fc24fc32aa249075dd222361aea119d4824db2a797d58886e93bdd60556e504bb190b76a451a4e7b0431973c0410e71e808d0962415503931bbde3dfce5186b371c5bf729861f239ef626b7217d071dfd62bac877a847f2ac2dca07597a0bb9dc1969bed40606c025c4ff7b53a4a6bd921642199c16ede8165ed28da161739fa8d33f9f483212759498c1219d246092d14c9ae63808f58f03c8ca746904ba51fa326d793cea80cda411c85d35894bdb5"
            },
            {
              satotxApiPrefix: "https://satotx.volt.id",
              satotxPubKey:
                "3a62ce90c189ae322150cfc68cd00739cd681babf46a9b27793413ad780ea7c4ef22afd0042bc3711588587c2b8a953ced78496cb95579b1272b8979183ea3c66d204c8eeffebfa115c596c0c561f3569fe6d6e8e06d7e82192a24a84b739838ac846db8594a565679d617695f184eb85a3902a036eb8e82f95b83acc207f0deeac87291539865765899d97cfe41169c555480372195729269ae30b6c39324a6731d6f4e46da5ba1789c6e9bd14b16426d35fd4449eecd177e2834e87fb65d9d469176ffe0c12097fcc7e2393dbaa504631487a3ad725235b4d25fe3d09c2460f8a6c0bf4defc1ffe65d5fa28e85fae11eace2a66e48a0ae2ed6bcfb4bb94296717a4a5b1b3fa9b0fb3c165e517b9b69fa8aaca7fdc7351a0ac14d110258f442f423a780bebd87ac10173ca00ee4e9f56ced0510e7f53ed41411b91286f288438c361d2a15868d1c84d6a73510ef23eee9312ae2a7162c1fcd5438788236c0571ee822c326ebd123b8a6636e7b192db2911725a20da027bfaa79c33f58174285"
            },
            {
              satotxApiPrefix: "https://satotx.metasv.com",
              satotxPubKey:
                "19d9193ee2e95d09445d28408e8a3da730b2d557cd8d39a7ae4ebbfbceb17ed5d745623529ad33d043511f3e205c1f92b6322833424d19823c3b611b3adabb74e1006e0e93a8f1e0b97ab801c6060a4c060f775998d9f003568ab4ea7633a0395eb761c36106e229394f2c271b8522a44a5ae759254f5d22927923ba85b3729460ecccca07a5556299aa7f2518814c74a2a4d48b48013d609002631f2d93c906d07077ef58d473e3d971362d1129c1ab9b8f9b1365519f0c023c1cadad5ab57240d19e256e08022fd0708951ff90a8af0655aff806c6382d0a72c13f1e52b88222d7dfc6357179b06ffcf937f9da3b0419908aa589a731e26bbaba2fa0b754bf722e338c5627b11dc24aadc4d83c35851c034936cf0df18167e856a5f0a7121d23cd48b3f8a420869a37bd1362905d7f76ff18a991f75a0f9d1bcfc18416d76691cc357cbdcc8cc0df9dbd9318a40e08adb2fb4e78b3c47bdf07eeed4f3f4e0f7e81e37460a09b857e0194c72ec03bb564b5b409d8a1b84c153186ecbb4cfdfd"
            },
            {
              satotxApiPrefix: "https://satotx.tswap.io",
              satotxPubKey:
                "a36531727b324b34baef257d223b8ba97bac06d6b631cccb271101f20ef1de2523a0a3a5367d89d98ff354fe1a07bcfb00425ab252950ce10a90dc9040930cf86a3081f0c68ea05bfd40aab3e8bfaaaf6b5a1e7a2b202892dc9b1c0fe478210799759b31ee04e842106a58d901eb5bc538c1b58b7eb774a382e7ae0d6ed706bb0b12b9b891828da5266dd9f0b381b05ecbce99fcde628360d281800cf8ccf4630b2a0a1a25cf4d103199888984cf61edaa0dad578b80dbce25b3316985a8f846ada9bf9bdb8c930e2a43e69994a9b15ea33fe6ee29fa1a6f251f8d79a5de9f1f24152efddedc01b63e2f2468005ecce7da382a64d7936b22a7cac697e1b0a48419101a802d3be554a9b582a80e5c5d8b998e5eb9684c7aaf09ef286d3d990c71be6e3f3340fdaeb2dac70a0be928b6de6ef79f353c868def3385bccd36aa871eb7c8047d3f10b0a38135cdb3577eaafa512111a7af088e8f77821a27b195c95bf80da3c59fda5ff3dd1d40f60d61c099a608b58b6de4a76146cf7b89444c1055"
            }
          ],
          seriesName: this.nftSeriesName
        });
      } catch (err) {
        this.isLoading = false;
        if (err?.resData) {
          return this.$message.error(
            `错误:${JSON.parse(err.resData.body).message},如有疑问请联系客服`
          );
        }
        if (err?.data) {
          return this.$message.error(
            `错误:${err.data.message},如有疑问请联系客服`
          );
        }
        return this.$message.error(`错误:${err},如有疑问请联系客服`);
      }
      console.log(result);
      if (result?.genesisTxid) {
        this.nftgenesisId = result.genesisId;
        this.nftgenesisTxid = result.genesisTxid;
        this.nftSensibleId = result.sensibleId;
        this.codehash = result.codehash;
        this.nftactive = 1;
        this.$message.success("发布成功");
      }
      this.isLoading = false;
    },
    async onNFTIssue(values) {
      let result;
      try {
        this.isLoading = true;
        result = await this.$store.state.metanet.issueNFT({
          genesisId: values.nftgenesisId,
          genesisTxid: values.genesisTxid,
          codehash: values.codehash,
          sensibleId: values.nftSensibleId,
          receiverAddress: values.receiverAddress,
          nftname: values.nftname,
          nftdesc: values.nftdesc,
          nfticon: values.nfticon,
          nftwebsite: values.nftwebsite,
          nftissuerName: values.nftissuerName,
          sensibleId: values.nftSensibleId
        });
        console.log("issueNFT", result);
      } catch (err) {
        console.log(err);
        this.isLoading = false;
        if (err?.resData) {
          return this.$message.error(
            `错误:${JSON.parse(err.resData.body).message},如有疑问请联系客服`
          );
        }
        if (err?.data) {
          return this.$message.error(
            `错误:${err?.data?.message},如有疑问请联系客服`
          );
        }
        return this.$message.error(`错误:${err},如有疑问请联系客服`);
      }
      console.log(result);
      if (result?.txId) {
        this.nftactive = 2;
        this.$message.success("发布成功");
      }
      this.isLoading = false;
    },
    async onFTGenesis(values) {
      let result;
      this.isLoading = true;
      try {
        result = await this.$store.state.metanet.genesisFT({
          ftName: values.ftName,
          ftSymbol: values.ftSymbol,
          ftDecimalNum: +values.ftDecimalNum,
          ftdesc: values.ftdesc,
          fticon: values.fticon,
          ftwebsite: values.ftwebsite,
          ftissuerName: values.ftissuerName,
          signersRaw: [
            {
              satotxApiPrefix: "https://s1.satoplay.cn,https://s1.satoplay.com",
              satotxPubKey:
                "2c8c0117aa5edba9a4539e783b6a1bdbc1ad88ad5b57f3d9c5cba55001c45e1fedb877ebc7d49d1cfa8aa938ccb303c3a37732eb0296fee4a6642b0ff1976817b603404f64c41ec098f8cd908caf64b4a3aada220ff61e252ef6d775079b69451367eda8fdb37bc55c8bfd69610e1f31b9d421ff44e3a0cfa7b11f334374827256a0b91ce80c45ffb798798e7bd6b110134e1a3c3fa89855a19829aab3922f55da92000495737e99e0094e6c4dbcc4e8d8de5459355c21ff055d039a202076e4ca263b745a885ef292eec0b5a5255e6ecc45534897d9572c3ebe97d36626c7b1e775159e00b17d03bc6d127260e13a252afd89bab72e8daf893075f18c1840cb394f18a9817913a9462c6ffc8951bee50a05f38da4c9090a4d6868cb8c955e5efb4f3be4e7cf0be1c399d78a6f6dd26a0af8492dca67843c6da9915bae571aa9f4696418ab1520dd50dd05f5c0c7a51d2843bd4d9b6b3b79910e98f3d98099fd86d71b2fac290e32bdacb31943a8384a7668c32a66be127b74390b4b0dec6455"
            },
            {
              satotxApiPrefix: "https://cnsatotx.showpay.top",
              satotxPubKey:
                "5b94858991d384c61ffd97174e895fcd4f62e4fea618916dc095fe4c149bbdf1188c9b33bc15cbe963a63b2522e70b80a5b722ac0e6180407917403755df4de27d69cc115c683a99face8c823cbccf73c7f0d546f1300b9ee2e96aea85542527f33b649f1885caebe19cf75d9a645807f03565c65bd4c99c8f6bb000644cfb56969eac3e9331c254b08aa279ceb64c47ef66be3f071e28b3a5a21e48cdfc3335d8b52e80a09a104a791ace6a2c1b4da88c52f9cc28c54a324e126ec91a988c1fe4e21afc8a84d0e876e01502386f74e7fc24fc32aa249075dd222361aea119d4824db2a797d58886e93bdd60556e504bb190b76a451a4e7b0431973c0410e71e808d0962415503931bbde3dfce5186b371c5bf729861f239ef626b7217d071dfd62bac877a847f2ac2dca07597a0bb9dc1969bed40606c025c4ff7b53a4a6bd921642199c16ede8165ed28da161739fa8d33f9f483212759498c1219d246092d14c9ae63808f58f03c8ca746904ba51fa326d793cea80cda411c85d35894bdb5"
            },
            {
              satotxApiPrefix: "https://satotx.volt.id",
              satotxPubKey:
                "3a62ce90c189ae322150cfc68cd00739cd681babf46a9b27793413ad780ea7c4ef22afd0042bc3711588587c2b8a953ced78496cb95579b1272b8979183ea3c66d204c8eeffebfa115c596c0c561f3569fe6d6e8e06d7e82192a24a84b739838ac846db8594a565679d617695f184eb85a3902a036eb8e82f95b83acc207f0deeac87291539865765899d97cfe41169c555480372195729269ae30b6c39324a6731d6f4e46da5ba1789c6e9bd14b16426d35fd4449eecd177e2834e87fb65d9d469176ffe0c12097fcc7e2393dbaa504631487a3ad725235b4d25fe3d09c2460f8a6c0bf4defc1ffe65d5fa28e85fae11eace2a66e48a0ae2ed6bcfb4bb94296717a4a5b1b3fa9b0fb3c165e517b9b69fa8aaca7fdc7351a0ac14d110258f442f423a780bebd87ac10173ca00ee4e9f56ced0510e7f53ed41411b91286f288438c361d2a15868d1c84d6a73510ef23eee9312ae2a7162c1fcd5438788236c0571ee822c326ebd123b8a6636e7b192db2911725a20da027bfaa79c33f58174285"
            },
            {
              satotxApiPrefix: "https://satotx.metasv.com",
              satotxPubKey:
                "19d9193ee2e95d09445d28408e8a3da730b2d557cd8d39a7ae4ebbfbceb17ed5d745623529ad33d043511f3e205c1f92b6322833424d19823c3b611b3adabb74e1006e0e93a8f1e0b97ab801c6060a4c060f775998d9f003568ab4ea7633a0395eb761c36106e229394f2c271b8522a44a5ae759254f5d22927923ba85b3729460ecccca07a5556299aa7f2518814c74a2a4d48b48013d609002631f2d93c906d07077ef58d473e3d971362d1129c1ab9b8f9b1365519f0c023c1cadad5ab57240d19e256e08022fd0708951ff90a8af0655aff806c6382d0a72c13f1e52b88222d7dfc6357179b06ffcf937f9da3b0419908aa589a731e26bbaba2fa0b754bf722e338c5627b11dc24aadc4d83c35851c034936cf0df18167e856a5f0a7121d23cd48b3f8a420869a37bd1362905d7f76ff18a991f75a0f9d1bcfc18416d76691cc357cbdcc8cc0df9dbd9318a40e08adb2fb4e78b3c47bdf07eeed4f3f4e0f7e81e37460a09b857e0194c72ec03bb564b5b409d8a1b84c153186ecbb4cfdfd"
            },
            {
              satotxApiPrefix: "https://satotx.tswap.io",
              satotxPubKey:
                "a36531727b324b34baef257d223b8ba97bac06d6b631cccb271101f20ef1de2523a0a3a5367d89d98ff354fe1a07bcfb00425ab252950ce10a90dc9040930cf86a3081f0c68ea05bfd40aab3e8bfaaaf6b5a1e7a2b202892dc9b1c0fe478210799759b31ee04e842106a58d901eb5bc538c1b58b7eb774a382e7ae0d6ed706bb0b12b9b891828da5266dd9f0b381b05ecbce99fcde628360d281800cf8ccf4630b2a0a1a25cf4d103199888984cf61edaa0dad578b80dbce25b3316985a8f846ada9bf9bdb8c930e2a43e69994a9b15ea33fe6ee29fa1a6f251f8d79a5de9f1f24152efddedc01b63e2f2468005ecce7da382a64d7936b22a7cac697e1b0a48419101a802d3be554a9b582a80e5c5d8b998e5eb9684c7aaf09ef286d3d990c71be6e3f3340fdaeb2dac70a0be928b6de6ef79f353c868def3385bccd36aa871eb7c8047d3f10b0a38135cdb3577eaafa512111a7af088e8f77821a27b195c95bf80da3c59fda5ff3dd1d40f60d61c099a608b58b6de4a76146cf7b89444c1055"
            }
          ]
        });
      } catch (err) {
        this.isLoading = false;
        if (err?.resData) {
          return this.$message.error(
            `错误:${JSON.parse(err.resData.body).message},如有疑问请联系客服`
          );
        }
        if (err?.data) {
          return this.$message.error(
            `错误:${err.data.message},如有疑问请联系客服`
          );
        }
        return this.$message.error(`错误:${err},如有疑问请联系客服`);
      }
      console.log(result);

      if (result?.genesisId) {
        this.ftgenesisId = result.genesisId;
        this.codehash = result.codehash;
        this.sensibleId = result.sensibleId;
        this.ftactive = 1;
        this.$message.success("发布成功");
      }
      this.isLoading = false;
    },
    async onFTIssue(values) {
      let result;
      this.isLoading = true;
      let decimalNum = await this.getFtDecimal(values.sensibleId);
      try {
        result = await this.$store.state.metanet.issueFT({
          genesisId: values.ftgenesisId,
          ftTotal: parseInt(values.ftTotal * Math.pow(10, decimalNum)),
          codehash: values.codehash,
          sensibleId: values.sensibleId,
          isAddPub: Boolean(+values.isAddPub),
          ftreceiverAddress: values.ftreceiverAddress
        });
        console.log("result123", result);
      } catch (err) {
        this.isLoading = false;
        if (err?.resData) {
          return this.$message.error(
            `错误:${JSON.parse(err.resData.body).message},如有疑问请联系客服`
          );
        }
        return this.$message.error(`错误:${err},如有疑问请联系客服`);
      }
      console.log(result);
      if (result?.txId) {
        this.ftactive = 2;
        this.$message.success("发布成功");
      }
      this.isLoading = false;
    },
    async transfernftProtocol() {
      let result;
      this.isLoading = true;
      try {
        result = await this.$store.state.metanet.transfernftProtocol();
      } catch (err) {
        this.isLoading = false;
        if (err?.resData) {
          return this.$message.error(
            `错误:${JSON.parse(err.resData.body).message},如有疑问请联系客服`
          );
        }
        return this.$message.error(`错误:${err},如有疑问请联系客服`);
      }
      console.log(result);
      if (result?.txId) {
        this.ftgenesisId = null;
        this.$message.success("发布成功");
      }
      this.isLoading = false;
      this.transfernftP = result;
    },
    async metafileProtocol() {
      let result;
      this.isLoading = true;
      try {
        result = await this.$store.state.metanet.metafileProtocol();
      } catch (err) {
        this.isLoading = false;
        if (err?.resData) {
          return this.$message.error(
            `错误:${JSON.parse(err.resData.body).message},如有疑问请联系客服`
          );
        }
        return this.$message.error(`错误:${err},如有疑问请联系客服`);
      }
      console.log(result);
      if (result?.txId) {
        this.ftgenesisId = null;
        this.$message.success("发布成功");
      }
      this.isLoading = false;
      this.metafileP = result;
    },
    async issueFtProtocol() {
      let result;
      this.isLoading = true;
      try {
        result = await this.$store.state.metanet.issuenftProtocol();
      } catch (err) {
        this.isLoading = false;
        if (err?.resData) {
          return this.$message.error(
            `错误:${JSON.parse(err.resData.body).message},如有疑问请联系客服`
          );
        }
        return this.$message.error(`错误:${err},如有疑问请联系客服`);
      }
      console.log(result);
      if (result?.txId) {
        this.ftgenesisId = null;
        this.$message.success("发布成功");
      }
      this.isLoading = false;
      this.issueFtP = result;
    },
    async getTransferftProtocol() {
      this.transferLoding = true;
      try {
        let result = await this.$store.state.metanet.transferftProtocol();
        this.ftgenesisprotocol = result;
      } finally {
        this.transferLoding = false;
      }
    },
    //根据用户genisisID来获取FT的小数位
    getFtDecimal(params) {
      return new Promise(async (resolve, reject) => {
        const ftGenesisTxid = this.$store.state.metanet.reverceFtByteString(
          params
        );
        if (ftGenesisTxid) {
          let result = await this.$store.state.metanet.getFtGenesisInfo(
            ftGenesisTxid
          );
          resolve(result.data.decimalNum);
        } else {
          reject(this.$message.error(this.$t("lostFtDecimalNum")));
        }
      });
    }
  },
  async created() {}
};
</script>
<style lang="scss" scoped>
.home {
  box-sizing: content-box;
  background-color: #fff;
  width: 800px;
  margin: 0 auto;
}
.genesis {
  margin-top: 15px;
}
.homeWrap {
  padding-top: 80px;
}
.mt-40 {
  margin-top: 40px;
}
.form {
  margin-top: 40px;
}
.mart10 {
  margin-top: 10px;
}
.van-cell {
  height: 50px;
  line-height: 50px;
  padding-top: 0;
  padding-bottom: 0;
}
.step {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  margin: 0 auto;
  font-size: 12px;
  color: #909399;
  &.hide {
    display: none;
  }
  em {
    display: inline-block;
    margin-right: 6px;
    text-align: center;
    color: #ffffff;
    font-style: normal;
    border-radius: 50%;
    background-color: #bfc2cc;
    width: 20px;
    height: 20px;
    line-height: 20px;
  }
  span {
    &.active {
      color: #508ef0;
      em {
        color: #fff;
        background-color: #508ef0;
      }
    }
  }
}
.succontent {
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
  width: 350px;
  > p {
    color: #606266;
    margin: 20px 0 40px;
  }
  .detail {
    border-top: 1px solid #f5f7f9;
    border-bottom: 1px solid #f5f7f9;
    padding: 10px 0;
    p {
      display: flex;
      margin: 10px 0;
      justify-content: space-between;
      span:first-child {
        color: #909399;
      }
    }
  }
}
.finishbtn {
  margin: 60px auto 10px !important;
}
.viewbtn {
  border: none;
  color: rgba(80, 142, 240, 1);
  background-color: rgba(80, 142, 240, 0.15) !important;
}
.el-icon-success {
  font-size: 48px;
  color: #508ef0;
}
::v-deep .van-tab__text--ellipsis {
  font-size: 20px;
}
::v-deep .van-field__control--custom {
  min-height: 70px;
}
::v-deep .van-tabs__line {
  width: 12px;
  height: 4px;
  background: #508ef0;
  border-radius: 2px;
}
::v-deep .van-field__control--custom {
  min-height: 50px;
}
::v-deep .van-button {
  width: 120px;
  height: 40px;
  background: #508ef0;
  border-radius: 6px;
  margin: 0 auto;
}
.nftjenesis,
.ftjenesis {
  ::v-deep .van-tabs__line {
    display: none;
  }
  ::v-deep .van-tabs__wrap {
    display: none;
  }
  ::v-deep .van-tabs__content {
    border: 1px solid #f2f4f7;
    padding: 40px 50px;
    margin-top: 40px;
  }
}
input:focus,
textarea:focus {
  outline: none;
}
@media screen and (max-width: 500px) {
  .home {
    width: 380px;
  }
}
</style>
