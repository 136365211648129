<template>
  <div class="home">
    <div class="homeWrap">
      <h1 class="title">mc转账</h1>
      <div class="flex">
        <div class="flex flex-colume" style="width: 340px">
          <el-select
            v-model="value"
            placeholder="请选择"
            class="mart10"
            @change="handleSelectChange"
          >
            <el-option
              v-for="item in options"
              :key="item.ftSensibleId"
              :label="item.ftName"
              :value="item.ftSensibleId"
            >
            </el-option>
          </el-select>
          <el-input
            class="mart10"
            v-model="amount"
            placeholder="输入每个的转账数量"
          ></el-input>
          <el-input
            class="mart10"
            v-model="count"
            placeholder="输入每个账户转入次数"
          ></el-input>
          <el-input
            type="textarea"
            :rows="6"
            placeholder="请输入地址,英文逗号分隔(,)"
            v-model="addresstext"
            class="mart10"
          >
          </el-input>
          <div class="mart10 jus-spa-b flex">
            <el-button
              @click="transferFT"
              type="primary"
              class="startbtn"
              :loading="isLoading"
              >开始</el-button
            >
            <el-button
              @click="transferFT"
              type="success"
              class="startbtn"
              v-if="checkList.length > 0"
              >继续</el-button
            >
          </div>
        </div>

        <el-checkbox-group v-model="checkList" class="checklist">
          <el-checkbox
            v-for="item of addressList"
            :key="item"
            :label="item"
          ></el-checkbox>
        </el-checkbox-group>
      </div>
      <el-dialog
        :visible.sync="dialogTableVisible"
        :close-on-click-modal="false"
      >
        <el-progress
          :text-inside="true"
          :stroke-width="26"
          :percentage="percentage"
          :format="progress"
        ></el-progress>
      </el-dialog>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
export default {
  name: "IssueNft",
  components: {},
  data() {
    return {
      isLoading: false,
      fullLoading: "",
      dialogTableVisible: false,
      addressList: [],
      checkList: [],
      options: [],
      addresstext: "",
      value: "",
      currentItem: {},
      amount: "",
      percentage: 0,
      utxocount: 0,
      finish: 0,
      count: "",
    };
  },
  mounted() {
    this.getFtList();
  },
  methods: {
    getFtList() {
      this.$store.state.metanet
        .ftList(this.$store.state.metanet.rootAddress.address)
        .then((res) => {
          this.options = res;
          console.log(this.options);
        });
    },
    handleSelectChange(item) {
      if (item) {
        this.currentItem = this.options.find((opitem) => {
          return opitem.ftSensibleId == item;
        });
      }
    },
    async transferFT() {
      this.addressList = this.addresstext.replace(/,$/gi, "").split(",");
      this.$confirm(
        `目标用户数${this.addressList.length - this.checkList.length},每个用户${
          this.amount
        } MC!`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(async () => {
          this.dialogTableVisible = true;
          for (let item of this.addressList) {
            if (this.checkList.indexOf(item) > -1) {
              continue;
            }
            let receviers = [];
            console.log(this.count);
            for (let i = 0; i < +this.count; i++) {
              receviers.push({
                address: item.trim(),
                amount: BigInt(
                  (this.amount *
                    Math.pow(10, +this.currentItem.ftDecimalNum + 1)) /
                    10
                ).toString(),
              });
            }
            await this.$store.state.metanet.transferFT({
              genesisId: this.currentItem.ftGenesis,
              sensibleId: this.currentItem.ftSensibleId,
              codehash: this.currentItem.ftCodehash,
              receiverAddress: receviers,
            });
            this.checkList.push(item);
            this.percentage = Math.floor(
              (this.checkList.length / this.addressList.length) * 100
            );
            await this.sleep(3000);
          }
          this.$message.success("转账完成");

          this.dialogTableVisible = false;
        })
        .catch((err) => {
          this.dialogTableVisible = false;
          if (err === "cancel") {
            return this.$message.warning(`已取消`);
          }
          if (err?.resData) {
            return this.$message.error(
              `错误:${JSON.parse(err.resData.body).message},如有疑问请联系客服`
            );
          }
          return this.$message.error(
            `错误:${err.message || err?.data?.message},如有疑问请联系客服`
          );
        });
    },
    sleep(time) {
      return new Promise((resolve) => {
        setInterval(() => {
          resolve(true);
        }, time);
      });
    },
    progress(percentage) {
      if (percentage === 0) {
        return "数据准备中。。。。";
      } else {
        console.log(this.checkList);
        return `${this.checkList.length}/${this.addressList.length}`;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.uppicbtn {
  position: absolute;
  z-index: 1111;
}
.title {
  margin-bottom: 10px;
}
.home {
  background-color: #fff;
  width: 800px;
  height: 100%;
  margin: 0 auto;
}
.genesis {
  margin-top: 15px;
}
.jus-spa-b {
  justify-content: space-between;
}
.homeWrap {
  padding-top: 80px;
}
.form {
  margin-top: 50px;
}
.mart10 {
  margin-top: 10px;
}
::v-deep .el-dialog {
  border-radius: 10px;
}
::v-deep .el-progress-bar__innerText {
  color: #606266;
}
::v-deep .van-tab__text--ellipsis {
  font-size: 20px;
}
::v-deep .van-cell {
  height: 70px;
  line-height: 70px;
}
::v-deep .van-field__control--custom {
  min-height: 70px;
}
input:focus,
textarea:focus {
  outline: none;
}
.startbtn {
  height: 40px;
}
.checklist {
  display: flex;
  flex-direction: column;
}
.flex-colume {
  flex-direction: column;
}
.flex {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 500px) {
  .home {
    width: 380px;
  }
}
</style>
