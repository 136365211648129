<template>
  <div class="home">
    <div class="homeWrap">
      <h1 class="title">图片铸造</h1>
      <el-button @click="createMetafileBrfc" type="primary" class="uppicbtn"
        >上传图片</el-button
      >
      <van-tabs v-model="nftactive">
        <van-tab title="上传nft信息">
          <div class="form">
            <van-form @submit="createIssueBrfc">
              <van-field
                v-model="nftgenesisId"
                name="nftgenesisId"
                label="GenesisId"
                placeholder="填写genesisId"
                required
              />
              <van-field
                v-model="nftgenesisTxid"
                name="genesisTxid"
                label="GenesisTxid"
                placeholder="填写genesisTxid,必须是正确的tx格式"
                required
              />
              <van-field
                v-model="nftissuerName"
                name="nftissuerName"
                label="发布者名称"
                placeholder="发布者名称"
                required
              />
              <van-field
                v-model="receiverAddress"
                name="receiverAddress"
                label="接收地址"
                placeholder="请填写接收的地址(销毁此处不填)"
                required
              />
              <van-field
                v-model="nftdesc"
                name="nftdesc"
                label="描述"
                placeholder="请填写描述"
                required
              />
              <van-field
                v-model="nftname"
                name="nftname"
                label="NFT名称"
                placeholder="请填写nft名称"
                required
              />
              <van-field
                v-model="nftwebsite"
                name="nftwebsite"
                label="网站介绍"
                placeholder="网站介绍,可以为空"
                required
              />
              <div style="margin: 16px">
                <van-button
                  round
                  block
                  type="info"
                  native-type="submit"
                  :loading="isLoading"
                  >GENESIS</van-button
                >
              </div>
            </van-form>
          </div>
        </van-tab>
        <van-tab title="铸造">
          <div class="form">
            <van-form @submit="createIssue">
              <van-field
                v-model="nftgenesisId"
                name="nftgenesisId"
                label="GenesisId"
                placeholder="填写genesisId"
                required
              />
              <van-field
                v-model="nftSensibleId"
                name="nftSensibleId"
                label="SensibleId"
                placeholder="填写genesisId"
                required
              />
              <van-field
                v-model="nftgenesisTxid"
                name="genesisTxid"
                label="GenesisTxid"
                placeholder="填写genesisTxid,必须是正确的tx格式"
                required
              />
              <van-field
                v-model="codehash"
                name="codehash"
                label="codeHash"
                placeholder="填写codeHash"
                required
              />
              <van-field
                v-model="receiverAddress"
                name="receiverAddress"
                label="接收地址"
                placeholder="请填写接收的地址(销毁此处不填)"
                required
              />
              <div style="margin: 16px">
                <van-button
                  round
                  block
                  type="info"
                  native-type="submit"
                  :loading="isLoading"
                  >ISSUE</van-button
                >
              </div>
            </van-form>
          </div>
        </van-tab>
        <van-tab title="上架">
          <div class="form">
            <van-form @submit="createSell">
              <van-field
                v-model="nftgenesisId"
                name="nftgenesisId"
                label="GenesisId"
                placeholder="填写genesisId"
                required
              />
              <van-field
                v-model="nftSensibleId"
                name="nftSensibleId"
                label="SensibleId"
                placeholder="填写genesisId"
                required
              />
              <van-field
                v-model="nftgenesisTxid"
                name="genesisTxid"
                label="GenesisTxid"
                placeholder="填写genesisTxid,必须是正确的tx格式"
                required
              />
              <van-field
                v-model="codehash"
                name="codehash"
                label="codeHash"
                placeholder="填写codeHash"
                required
              />
              <van-field
                v-model="startTokenIndex"
                name="startTokenIndex"
                label="StartIndex"
                placeholder="批量售卖的nft的开始token_index"
                required
              />
              <van-field
                v-model="endTokenIndex"
                name="endTokenIndex"
                label="EndIndex"
                placeholder="批量售卖的nft的结束token_index"
                required
              />
              <van-field
                v-model="satoshisPrice"
                name="satoshisPrice"
                label="satoshisPrice"
                placeholder="售卖价格"
                required
              />
              <van-field
                v-model="sellDesc"
                name="sellDesc"
                label="sellDesc"
                placeholder="售卖介绍信息"
                required
              />
              <div style="margin: 16px">
                <van-button
                  round
                  block
                  type="info"
                  native-type="submit"
                  :loading="isLoading"
                  >Sell</van-button
                >
              </div>
            </van-form>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src

import {
  postFileBrfcNodeInfo,
  postNftIssueBrfcNodeInfo,
  nftIssue,
  nftSell
} from "@/api/api";
export default {
  name: "IssueNft",
  components: {},
  data() {
    return {
      wif: "",
      xpub: "",
      nftdesc: "",
      nftwebsite: "",
      nftissuerName: "",
      nftgenesisId: "",
      nftgenesisTxid: "",
      nftSensibleId: "",
      sellDesc:"",
      satoshisPrice: "",
      startTokenIndex:"",
      endTokenIndex: "",
      nftname: "",
      codehash: "",
      isLoading: false
    };
  },
  mounted() {},
  methods: {
    async createMetafileBrfc() {
      let result;
      this.$confirm(`确认上传图片？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          this.fullLoading = this.$loading({
            lock: true,
            text: "上传中。。。",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          this.fullLoading.close();
          result = await this.$store.state.metanet.createBrfcProtocolNode({
            nodeName: "NftTranMetaFilesfer",
            brfcId: "6d3eaf759bbc",
            path: "/Protocols/MetaFile",
            needConfirm: false,
            payAll: true
          });
          result = await postFileBrfcNodeInfo({
            wif: result.data.wif,
            xpub: result.data.xpub,
            metafileProtocolTxid: result.data.txId
          });
          if (result === "success") {
            this.$message.success("metafile协议上传成功");
          } else {
            this.$message.error(result.toString());
          }
        })
        .catch(err => {
          this.fullLoading.close();
          if (err?.resData) {
            return this.$message.error(
              `错误:${JSON.parse(err.resData.body).message},如有疑问请联系客服`
            );
          }
          return this.$message.error(`错误:${err.message},如有疑问请联系客服`);
        });
    },
    async createIssueBrfc() {
      let result;
      this.$confirm(`确认上传NFT信息？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          this.fullLoading = this.$loading({
            lock: true,
            text: "上传中。。。",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          result = await this.$store.state.metanet.createBrfcProtocolNode({
            nodeName: "NftIssue",
            brfcId: "61f44fdb6119",
            path: "/Protocols/NftIssue",
            needConfirm: false,
            payAll: true
          });
          this.wif = result.data.wif;
          this.xpub = result.data.xpub;
          result = await postNftIssueBrfcNodeInfo({
            wif: result.data.wif,
            xpub: result.data.xpub,
            nftIssueProtocolTxid: result.data.txId,
            genesisId: this.nftgenesisId,
            genesisTxid: this.nftgenesisTxid,
            receiverAddress: this.receiverAddress,
            issuerName: this.nftissuerName,
            desc: this.nftdesc,
            website: this.nftwebsite,
            name: this.nftname
          });
          if (result === "success") {
            this.$message.success("metafile协议上传成功");
          } else {
            this.$message.error(result.toString());
          }
          this.fullLoading.close();
        })
        .catch(err => {
          this.fullLoading.close();
          if (err?.resData) {
            return this.$message.error(
              `错误:${JSON.parse(err.resData.body).message},如有疑问请联系客服`
            );
          }
          return this.$message.error(`错误:${err.message},如有疑问请联系客服`);
        });
    },
    async createIssue() {
      let result;
      this.$confirm(`确认铸造？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          this.fullLoading = this.$loading({
            lock: true,
            text: "上传中。。。",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          result = await this.$store.state.metanet.createBrfcProtocolNode({
            nodeName: "NftIssue",
            brfcId: "61f44fdb6119",
            path: "/Protocols/NftIssue",
            needConfirm: false,
            privateKeyPath: "0/0",
            payAll: true
          });
          this.wif = result.data.wif;
          result = await nftIssue({
            wif: this.wif,
            nftIssueProtocolTxid: result.data.txId,
            codehash: this.codehash,
            genesisId: this.nftgenesisId,
            genesisTxid: this.nftgenesisTxid,
            receiverAddress: this.receiverAddress,
            sensibleId: this.nftSensibleId
          });
          if (result === "success") {
            this.$message.success("铸造成功");
          } else {
            this.$message.error(result.toString());
          }
          this.fullLoading.close();
        })
        .catch(err => {
          this.fullLoading.close();
          if (err?.resData) {
            return this.$message.error(
              `错误:${JSON.parse(err.resData.body).message},如有疑问请联系客服`
            );
          }
          return this.$message.error(`错误:${err.message},如有疑问请联系客服`);
        });
    },
    async createSell() {
      let result;
      this.$confirm(`确认上架？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          this.fullLoading = this.$loading({
            lock: true,
            text: "上架中。。。",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          result = await this.$store.state.metanet.createBrfcProtocolNode({
            nodeName: "NftSell",
            brfcId: "13104a689fd3",
            path: "/Protocols/NftSell",
            needConfirm: false,
            payAll: true
          });
          this.wif = result.data.wif;
          result = await nftSell({
            purseWif:this.wif,
            sellerWif: result.data.sellerWif,
            nftSellProtocolTxid: result.data.txId,
            codehash: this.codehash,
            genesisId: this.nftgenesisId,
            genesisTxid: this.nftgenesisTxid,
            sensibleId: this.nftSensibleId,
            startTokenIndex: this.startTokenIndex,
            endTokenIndex: this.endTokenIndex,
            satoshisPrice: this.satoshisPrice,
            sellDesc: this.sellDesc,
          });
          if (result === "success") {
            this.$message.success("上架成功");
          } else {
            this.$message.error(result.toString());
          }
          this.fullLoading.close();
        })
        .catch(err => {
          this.fullLoading.close();
          if (err?.resData) {
            return this.$message.error(
              `错误:${JSON.parse(err.resData.body).message},如有疑问请联系客服`
            );
          }
          return this.$message.error(`错误:${err.message},如有疑问请联系客服`);
        });
    }
  },
  async created() {
    this.receiverAddress = this.$store.state.metanet.rootAddress.address;
  }
};
</script>
<style lang="scss" scoped>
.uppicbtn {
  position: absolute;
  z-index: 1111;
}
.title {
  margin-bottom: 10px;
}
.home {
  background-color: #fff;
  width: 650px;
  height: 100%;
  margin: 0 auto;
}
.genesis {
  margin-top: 15px;
}
.homeWrap {
  padding-top: 80px;
}
.form {
  margin-top: 50px;
}
.mart10 {
  margin-top: 10px;
}
::v-deep .van-tab__text--ellipsis {
  font-size: 20px;
}
::v-deep .van-cell {
  height: 70px;
  line-height: 70px;
}
::v-deep .van-field__control--custom {
  min-height: 70px;
}
input:focus,
textarea:focus {
  outline: none;
}
@media screen and (max-width: 500px) {
  .home {
    width: 380px;
  }
}
</style>
