var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wallet" },
    [
      _c(
        "div",
        {
          ref: "wallet_warpBsv",
          staticClass: "wallet_warp",
          staticStyle: { "margin-top": "0" },
        },
        [
          _c("div", { staticClass: "wallet_data" }, [
            _c(
              "div",
              { staticClass: "tabWrap" },
              [
                _c(
                  "el-tabs",
                  {
                    attrs: { type: "card" },
                    on: { "tab-click": _vm.handleClick },
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.ftloading,
                            expression: "ftloading",
                          },
                        ],
                        staticClass: "ftLoading",
                        attrs: { label: _vm.ftLabel, name: "myft" },
                      },
                      [
                        _vm.FTlist.length
                          ? _vm._l(_vm.FTlist, function (item, index) {
                              return _c(
                                "ul",
                                { key: index, staticClass: "coinlist" },
                                [
                                  _c(
                                    "li",
                                    {
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.openFtDetail(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "contain" }, [
                                        _c("img", {
                                          attrs: {
                                            src: _vm._f("ftImgUrl")(
                                              item.ftIcon
                                            ),
                                            alt: "",
                                          },
                                        }),
                                        _c("div", { staticClass: "info" }, [
                                          _c("h5", [
                                            _vm._v(_vm._s(item.ftName)),
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                `${item.ftBalance} ${item.ftSymbol}`
                                              )
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                      _c("i", {
                                        staticClass: "el-icon-arrow-right",
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            })
                          : _c("div", { staticClass: "noRecord" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("noRecord")) +
                                  "\n            "
                              ),
                            ]),
                      ],
                      2
                    ),
                    _c(
                      "el-tab-pane",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.nftloading,
                            expression: "nftloading",
                          },
                        ],
                        staticClass: "nftLoading",
                        attrs: { label: _vm.nftLabel, name: "mynft" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "container" },
                          [
                            JSON.stringify(_vm.NFTlist) !== "{}"
                              ? _vm._l(
                                  _vm.NFTlist,
                                  function (val, name, index) {
                                    return _c(
                                      "div",
                                      { key: index, staticClass: "game" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "title",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.toDetail(
                                                  val.base,
                                                  name,
                                                  val.metaId
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("div", { staticClass: "left" }, [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm._f("metaidUrl")(
                                                    val.metaId
                                                  ),
                                                  alt: "",
                                                },
                                              }),
                                              _c("div", [_vm._v(_vm._s(name))]),
                                            ]),
                                            _c(
                                              "div",
                                              { staticClass: "right" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(val.base.length)
                                                  ),
                                                ]),
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-arrow-right",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "card" },
                                          _vm._l(
                                            val.info,
                                            function (item, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass: "cardWrap",
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: _vm._f("nftImgUrl")(
                                                        item.nftIcon
                                                      ),
                                                      alt: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.openNftDetail(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    { staticClass: "gameName" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.nftName)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    )
                                  }
                                )
                              : _c("div", { staticClass: "noRecord" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("noRecord")) +
                                      "\n              "
                                  ),
                                ]),
                          ],
                          2
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm.currentnftlist.length > 0
            ? _c("div", { key: _vm.i, staticClass: "nftDetailWrap" }, [
                _c("div", { staticClass: "detailTop" }, [
                  _c("div", { staticClass: "leftIcon" }, [
                    _c("i", {
                      staticClass: "el-icon-arrow-left",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.toHome.apply(null, arguments)
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _c("img", {
                      attrs: {
                        src: _vm._f("metaidUrl")(_vm.currentMetaid),
                        alt: "",
                      },
                    }),
                    _c("span", [_vm._v(_vm._s(_vm.currentName))]),
                    _c("span", [_vm._v(_vm._s(_vm.currentnftlist.length))]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "detailContainer" },
                  _vm._l(_vm.currentnftlist, function (v, i) {
                    return _c("div", { key: i, staticClass: "detailItem" }, [
                      _c("img", {
                        attrs: { src: _vm._f("nftImgUrl")(v.nftIcon), alt: "" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.openNftDetail(v)
                          },
                        },
                      }),
                      _c("span", [_vm._v(_vm._s(v.nftName))]),
                    ])
                  }),
                  0
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "coininfo",
          attrs: {
            "close-on-click-modal": false,
            visible: _vm.detailShow,
            width: "90vw",
            "show-close": _vm.isShowClose,
            center: "",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailShow = $event
            },
          },
        },
        [
          _vm.activeName === "mynft"
            ? _c("div", { staticClass: "isMyNft" }, [
                _vm.nftDetailShow === "detail"
                  ? _c("div", { staticClass: "nftContentWrap" }, [
                      _c("div", { staticClass: "nftContenTop" }, [
                        _c("img", {
                          attrs: {
                            src: _vm._f("nftImgUrl")(_vm.nftDetialItem.nftIcon),
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.imgShow = true
                            },
                          },
                        }),
                        _c("span", { staticClass: "cardName" }, [
                          _vm._v(_vm._s(_vm.CurNftItem.nftName)),
                        ]),
                      ]),
                      _c("div", { staticClass: "nftContentCenter" }, [
                        _c("div", { staticClass: "nftContentItem" }, [
                          _c("span", { staticClass: "left" }, [
                            _vm._v(_vm._s(_vm.$t("nftName"))),
                          ]),
                          _c("span", { staticClass: "right" }, [
                            _vm._v(_vm._s(_vm.CurNftItem.nftName)),
                          ]),
                        ]),
                        _c("div", { staticClass: "nftContentItem" }, [
                          _c("span", { staticClass: "left" }, [
                            _vm._v(_vm._s(_vm.$t("issuer"))),
                          ]),
                          _c("div", { staticClass: "right" }, [
                            _c("img", {
                              attrs: {
                                src: _vm._f("nftImgUrl")(
                                  _vm.CurNftItem.nftIcon
                                ),
                                alt: "",
                              },
                            }),
                            _c("span", [
                              _vm._v(_vm._s(_vm.CurNftItem.nftIssuer)),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "nftContentItem" }, [
                          _c("span", { staticClass: "left" }, [
                            _vm._v(_vm._s(_vm.$t("releaseTime"))),
                          ]),
                          _c("span", { staticClass: "right" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateStr")(
                                  _vm.CurNftItem.nftTimestamp,
                                  "YYYY-MM-DD"
                                )
                              )
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "nftContentItem" }, [
                          _c("span", { staticClass: "left" }, [
                            _vm._v(_vm._s(_vm.$t("curReleaseAmount"))),
                          ]),
                          _c("span", { staticClass: "right" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("tofixZero")(
                                  _vm.CurNftItem.nftTotalSupply
                                )
                              )
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "nftContenfooter" }, [
                        _c(
                          "div",
                          {
                            staticClass: "confirmBtn",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.nftPayFor.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("nftTransfer")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "confirmBtn sellbtn",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.nftSell.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("nftSell")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm.nftDetailShow === "transition"
                  ? _c("div", { staticClass: "transitionWrap" }, [
                      _c("div", { staticClass: "title" }, [
                        _c("i", {
                          staticClass: "el-icon-arrow-left fallback",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.nftDetailShow = "detail"
                            },
                          },
                        }),
                        _c("span", [_vm._v(_vm._s(_vm.$t("nftTransfer")))]),
                        _c("i", {
                          staticClass: "el-icon-close",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.detailShow = false
                            },
                          },
                        }),
                      ]),
                      _vm.CurNftItem
                        ? _c("div", { staticClass: "nftContent" }, [
                            _c("div", { staticClass: "payAcount" }, [
                              _c("div", [
                                _vm._v(_vm._s(_vm.$t("otherAccount"))),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.payNftAdress,
                                    expression: "payNftAdress",
                                  },
                                ],
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.transferAddress,
                                },
                                domProps: { value: _vm.payNftAdress },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.payNftAdress = $event.target.value
                                    },
                                    _vm.validate,
                                  ],
                                },
                              }),
                              _vm.tipsAddress
                                ? _c("div", { staticClass: "tips" }, [
                                    _vm._v(_vm._s(_vm.$t("tipsAddress"))),
                                  ])
                                : _vm._e(),
                              _vm.tipsInput
                                ? _c("div", { staticClass: "tips" }, [
                                    _vm._v(_vm._s(_vm.$t("tipsInput"))),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "nftName" }, [
                              _c("div", [_vm._v(_vm._s(_vm.$t("passCard")))]),
                              _c("div", [
                                _c("img", {
                                  attrs: {
                                    src: _vm._f("nftImgUrl")(
                                      _vm.CurNftItem.nftIcon
                                    ),
                                    alt: "",
                                  },
                                }),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.CurNftItem.nftName)),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "nftTotal" }, [
                              _c("div", { staticClass: "top" }, [
                                _vm._v(_vm._s(_vm.$t("tokenAmount"))),
                              ]),
                              _c("div", { staticClass: "foot" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.payNftVal,
                                      expression: "payNftVal",
                                    },
                                  ],
                                  attrs: { type: "text" },
                                  domProps: { value: _vm.payNftVal },
                                  on: {
                                    keyup: function ($event) {
                                      _vm.payNftVal = 1
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.payNftVal = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "payBtn" },
                        [
                          _c(
                            "el-button",
                            {
                              class: [
                                _vm.payToNftDisabled
                                  ? "confirmBtn"
                                  : "allowConfirmBtn",
                              ],
                              attrs: {
                                disabled: _vm.payToNftDisabled,
                                loading: _vm.nftSendLoding,
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.confirmSendNft.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("confirmTransfer")) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _c("div", { staticClass: "transitionWrap" }, [
                      _c("div", { staticClass: "title" }, [
                        _c("i", {
                          staticClass: "el-icon-arrow-left fallback",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.nftDetailShow = "detail"
                            },
                          },
                        }),
                        _c("span", [_vm._v(_vm._s(_vm.$t("sales")))]),
                        _c("i", {
                          staticClass: "el-icon-close",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.detailShow = false
                            },
                          },
                        }),
                      ]),
                      _vm.CurNftItem
                        ? _c("div", { staticClass: "nftContent" }, [
                            _c(
                              "div",
                              { staticClass: "nftName" },
                              [
                                _c("div", { staticClass: "saleTitle" }, [
                                  _vm._v(_vm._s(_vm.$t("saleDetail"))),
                                ]),
                                _c("van-field", {
                                  attrs: {
                                    rows: "2",
                                    autosize: "",
                                    type: "textarea",
                                    maxlength: "4000",
                                    placeholder: _vm.$t("nftDesc"),
                                    "show-word-limit": "",
                                  },
                                  model: {
                                    value: _vm.salesIntro,
                                    callback: function ($$v) {
                                      _vm.salesIntro = $$v
                                    },
                                    expression: "salesIntro",
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "saleDate" },
                                  [
                                    _c("van-field", {
                                      attrs: {
                                        readonly: "",
                                        value: _vm.$utils.dataFormat(
                                          _vm.saleTime
                                        ),
                                        placeholder: _vm.setShowTime,
                                        "right-icon": _vm.rightIcon,
                                      },
                                      on: {
                                        "click-right-icon": function ($event) {
                                          _vm.calenderShow = !_vm.calenderShow
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "datePicker" },
                                  [
                                    _vm.calenderShow
                                      ? _c("van-datetime-picker", {
                                          attrs: {
                                            type: "datetime",
                                            title: _vm.cancelSaleTime,
                                            "min-date": _vm.minDate,
                                            "max-date": _vm.maxDate,
                                            "columns-order": [
                                              "year",
                                              "month",
                                              "day",
                                              "hour",
                                              "minute",
                                            ],
                                            formatter: _vm.formatter,
                                          },
                                          on: {
                                            confirm: _vm.confirmDate,
                                            cancel: _vm.cancelChoose,
                                          },
                                          model: {
                                            value: _vm.currentDate,
                                            callback: function ($$v) {
                                              _vm.currentDate = $$v
                                            },
                                            expression: "currentDate",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "nftTotal" }, [
                              _c(
                                "div",
                                { staticClass: "foot" },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "sellPrice",
                                      attrs: { placeholder: _vm.pricePH },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.validatePrice()
                                        },
                                      },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          _vm.nftSellPrice =
                                            _vm.nftSellPrice.replace(
                                              /[^\d.]/g,
                                              ""
                                            )
                                        },
                                      },
                                      model: {
                                        value: _vm.nftSellPrice,
                                        callback: function ($$v) {
                                          _vm.nftSellPrice =
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                        },
                                        expression: "nftSellPrice",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-dropdown",
                                        {
                                          attrs: {
                                            slot: "append",
                                            trigger: "click",
                                          },
                                          on: { command: _vm.handleCommand },
                                          slot: "append",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "el-dropdown-link" },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.units[_vm.unitIndex]
                                                      .unit
                                                  ) +
                                                  "\n                    "
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-arrow-down el-icon--right",
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "el-dropdown-menu",
                                            _vm._l(
                                              _vm.units,
                                              function (unit, index) {
                                                return _c(
                                                  "el-dropdown-item",
                                                  {
                                                    key: index,
                                                    attrs: { command: index },
                                                  },
                                                  [_vm._v(_vm._s(unit.unit))]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "payBtn" },
                        [
                          _c(
                            "el-button",
                            {
                              class: [
                                _vm.saleBtnDisabled
                                  ? "confirmBtn"
                                  : "allowConfirmBtn",
                              ],
                              attrs: {
                                disabled: _vm.saleBtnDisabled,
                                loading: _vm.nftSendLoding,
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.confirmSellNft(true)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("nftSell")) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
              ])
            : _c("div", { staticClass: "isMyFt" }, [
                _vm.ftDetailShow
                  ? _c("div", { staticClass: "ftContentWrap" }, [
                      _c("div", { staticClass: "ftContenTop" }, [
                        _c("img", {
                          attrs: {
                            src: _vm._f("ftImgUrl")(_vm.CurFtItem.ftIcon),
                            alt: "",
                          },
                        }),
                        _c("span", { staticClass: "ftType" }, [
                          _vm._v(_vm._s(_vm.CurFtItem.ftName)),
                        ]),
                      ]),
                      _c("div", { staticClass: "ftAcountDetail" }, [
                        _c("div", { staticClass: "top" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("ThousandSeparator")(
                                  _vm.CurFtItem.ftBalance
                                )
                              )
                            ),
                          ]),
                        ]),
                        _c("span", { staticClass: "bottom" }, [
                          _vm._v(
                            _vm._s(
                              `${_vm.$t("me")} ${_vm.CurFtItem.ftName} ${_vm.$t(
                                "number"
                              )} ${_vm.CurFtItem.ftBalance} (${
                                _vm.CurFtItem.ftSymbol
                              })`
                            )
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "ftContentCenter" }, [
                        _c("div", { staticClass: "ftContentItem" }, [
                          _c("span", { staticClass: "left" }, [
                            _vm._v(_vm._s(_vm.$t("ftName"))),
                          ]),
                          _c("span", { staticClass: "right" }, [
                            _vm._v(_vm._s(_vm.CurFtItem.ftName)),
                          ]),
                        ]),
                        _c("div", { staticClass: "ftContentItem" }, [
                          _c("span", { staticClass: "left" }, [
                            _vm._v(_vm._s(_vm.$t("issuer"))),
                          ]),
                          _c("div", { staticClass: "right" }, [
                            _c("img", {
                              attrs: {
                                src: _vm._f("ftImgUrl")(_vm.CurFtItem.ftIcon),
                                alt: "",
                              },
                            }),
                            _c("span", [
                              _vm._v(_vm._s(_vm.CurFtItem.ftIssuer)),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "ftContentItem" }, [
                          _c("span", { staticClass: "left" }, [
                            _vm._v(_vm._s(_vm.$t("releaseTime"))),
                          ]),
                          _c("span", { staticClass: "right" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateStr")(
                                  _vm.CurFtItem.ftTimestamp,
                                  "YYYY-MM-DD"
                                )
                              )
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "ftContentItem" }, [
                          _c("span", { staticClass: "left" }, [
                            _vm._v(_vm._s(_vm.$t("curReleaseAmount"))),
                          ]),
                          _c("span", { staticClass: "right" }, [
                            _vm._v(
                              _vm._s(
                                `${_vm.CurFtItem.ftTotalSupply} ${_vm.CurFtItem.ftSymbol}`
                              )
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "ftContenfooter" }, [
                        _c(
                          "div",
                          {
                            staticClass: "confirmBtn",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.ftPayFor.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("ftTransfer")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _c("div", { staticClass: "transitionWrap" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.$t("ftTransfer"))),
                      ]),
                      _vm.CurFtItem
                        ? _c("div", { staticClass: "ftContent" }, [
                            _c("div", { staticClass: "payAcount" }, [
                              _c("div", [
                                _vm._v(_vm._s(_vm.$t("specifyAdress"))),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.specifyAdress,
                                    expression: "specifyAdress",
                                  },
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.specifyAdress },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.specifyAdress = $event.target.value
                                  },
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "payAcount" }, [
                              _c("div", [
                                _vm._v(_vm._s(_vm.$t("otherAccount"))),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.payFtAdress,
                                    expression: "payFtAdress",
                                  },
                                ],
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.transferAddress,
                                  required: "",
                                },
                                domProps: { value: _vm.payFtAdress },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.payFtAdress = $event.target.value
                                    },
                                    _vm.validate,
                                  ],
                                },
                              }),
                              _vm.tipsAddress
                                ? _c("div", { staticClass: "tips" }, [
                                    _vm._v(_vm._s(_vm.$t("tipsAddress"))),
                                  ])
                                : _vm._e(),
                              _vm.tipsInput
                                ? _c("div", { staticClass: "tips" }, [
                                    _vm._v(_vm._s(_vm.$t("tipsInput"))),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "ftName" }, [
                              _c("div", [_vm._v(_vm._s(_vm.$t("passCard")))]),
                              _c("div", [
                                _c("img", {
                                  attrs: {
                                    src: _vm._f("ftImgUrl")(
                                      _vm.CurFtItem.ftIcon
                                    ),
                                    alt: "",
                                  },
                                }),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.CurFtItem.ftName)),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "ftTotal" }, [
                              _c("div", { staticClass: "top" }, [
                                _vm._v(_vm._s(_vm.$t("tokenAmount"))),
                              ]),
                              _c("div", { staticClass: "foot" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.payFtVal,
                                      expression: "payFtVal",
                                    },
                                  ],
                                  attrs: {
                                    type: "text",
                                    required: "",
                                    placeholder: `${_vm.$t(
                                      "curHave"
                                    )} ${_vm.$options.filters.ThousandSeparator(
                                      _vm.CurFtItem.ftBalance
                                    )} ${_vm.CurFtItem.ftSymbol}`,
                                  },
                                  domProps: { value: _vm.payFtVal },
                                  on: {
                                    keyup: function ($event) {
                                      _vm.payFtVal = _vm.payFtVal.replace(
                                        /[^\d.]/g,
                                        ""
                                      )
                                    },
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.payFtVal = $event.target.value
                                      },
                                      _vm.validateNull,
                                    ],
                                  },
                                }),
                                _vm.tipsNum
                                  ? _c("div", { staticClass: "tipsNum" }, [
                                      _vm._v(_vm._s(_vm.$t("tipsNum"))),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "payBtn" },
                        [
                          _c(
                            "el-button",
                            {
                              class: [
                                _vm.payToFtDisabled
                                  ? "confirmBtn"
                                  : "allowConfirmBtn",
                              ],
                              attrs: {
                                disabled: _vm.payToFtDisabled,
                                loading: _vm.ftSendLoding,
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.confirmSendFt.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("confirmTransfer")) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
              ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "imgpreview",
          attrs: {
            visible: _vm.imgShow,
            center: "",
            "append-to-body": true,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.imgShow = $event
            },
          },
        },
        [
          _c("img", {
            attrs: {
              src: _vm._f("nftImgUrl")(_vm.nftDetialItem.nftIcon),
              alt: "",
            },
            on: {
              click: function ($event) {
                _vm.imgShow = false
              },
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }