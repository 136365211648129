var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "home" }, [
    _c(
      "div",
      { staticClass: "homeWrap" },
      [
        _c("h1", { staticClass: "title" }, [_vm._v("UTXO归集")]),
        _c(
          "el-button",
          {
            attrs: { type: "primary", loading: _vm.isLoading },
            on: { click: _vm.mergeUtxo },
          },
          [_vm._v("合并UTXO")]
        ),
        _c(
          "el-button",
          {
            staticStyle: { display: "none" },
            attrs: { type: "primary" },
            on: { click: _vm.splitUtxo },
          },
          [_vm._v("拆分UTXO")]
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              visible: _vm.dialogTableVisible,
              "close-on-click-modal": false,
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogTableVisible = $event
              },
            },
          },
          [
            _c("el-progress", {
              attrs: {
                "text-inside": true,
                "stroke-width": 26,
                percentage: _vm.percentage,
                format: _vm.progress,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }