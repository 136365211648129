import Vue from "vue";
import Vuex from "vuex";
import { useTestNet } from "./common/js/baseApi";

Vue.use(Vuex);
Vue.config.devtools = true;
export default new Vuex.Store({
  state: {
    appShow: true,
    wallet: "",
    moneyRate: 0,
    currency: "",
    lang: "",
    balance: {
      bsv: 0,
      satoshis: 0,
    },
    isBusying: false,
    mcAmount: "",
    address0: "",
    headUrl:
      "https://showpay.oss-cn-beijing.aliyuncs.com/defaultAvatar/img_user_default@2x.png",
    userData: {
      headUrl:
        "https://showjob.oss-cn-hangzhou.aliyuncs.com/index/img_photo_default.png",
    },
    UserLogin: false,
    tokenList: "",
    myTokenList: [],
    mnemonic: false,
    dealIn: false,
    rates: {},
    userMeta: "",
    autoLogin: false,
    loginShow: false,
    BSVname: `MVC #1`,
    metanet: "",
    infoTxId: "",
    metaShow: false,
    loginText: "",
    rootText: "",
    showMem: false,
    loginType: "",
    loginPhone: "",
    loginEmail: "",
    isTestnet:
      (localStorage.getItem("chain") || "MVC") === "BSV"
        ? process.env.VUE_APP_BSV_USEMAINNET != 1
        : process.env.VUE_APP_MVC_USEMAINNET != 1,
    ftList: [],
    nftList: {},
    saleNftList: [],
    curNftItem: {},
    curFtItem: {},
    nftBotAvatar: [],
    chain: localStorage.getItem("chain") || "MVC",
  },
  getters: {
    getMc(state) {
      return state.mcAmount;
    },
  },
  mutations: {
    getMetaBot(state, payload) {
      state.nftBotAvatar = payload;
    },
    getMoney(state, payload) {
      state.balance = payload;
    },
    getSaleNftList(state, payload) {
      if (payload.isInit) {
        state.saleNftList = payload.result;
      } else {
        state.saleNftList = payload.updataResult;
      }
    },
    getNftList(state, payload) {
      if (payload.isInit) {
        state.nftList = payload.cache;
      } else {
        state.nftList = payload.updataCache;
      }
    },
    getFtList(state, payload) {
      if (payload.isInit) {
        state.ftList = payload.result;
      } else {
        state.ftList = payload.updataResult;
      }
    },
    getCurNftItem(state, payload) {
      state.curNftItem = payload;
    },
    getCurFtItem(state, payload) {
      state.curFtItem = payload;
    },
    showMem(state, payload) {
      state.showMem = payload;
    },
    loginPhone(state, payload) {
      state.loginPhone = payload;
    },
    loginType(state, payload) {
      state.loginType = payload;
    },
    loginEmail(state, payload) {
      state.loginEmail = payload;
    },
    userData(state, payload) {
      const headUrl = state.userData.headUrl;
      state.userData = payload;
      if (!payload.headUrl) {
        state.userData.headUrl = headUrl;
      }
    },
    autoLogin(state, payload) {
      state.autoLogin = payload;
    },
    logout(state) {
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem("chain", "MVC");
      state.chain = "MVC";
      var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
      if (keys) {
        for (var i = keys.length; i--; ) {
          document.cookie = keys[i] + "=0;expires=" + new Date(0).toUTCString();
        }
      }
      state.autoLogin = false;
      // console.log(store.state.wallet)
      state.userData = "";
      state = {
        appShow: true,
        wallet: "",
        moneyRate: 0,
        currency: "",
        lang: "",
        balance: "",
        headUrl: "",
        userData: {
          headUrl:
            "https://showjob.oss-cn-hangzhou.aliyuncs.com/index/img_photo_default.png",
        },
        UserLogin: false,
        tokenList: "",
        myTokenList: [],
        mnemonic: false,
        dealIn: false,
        rates: {},
        userMeta: "",
        autoLogin: false,
        loginShow: true,
        BSVname: "MVC #1",
        metanet: "",
        infoTxId: "",
        metaShow: false,
        loginText: "",
        rootText: "",
        showMem: false,
        loginType: "",
        loginPhone: "",
        loginEmail: "",
        address0: "",
        isTestnet: process.env.VUE_APP_BSV_USEMAINNET == 1,
        chain: "MVC",
      };
    },
    appShow(state, playLoad) {
      state.appShow = playLoad;
    },
    setAddress0(state, address) {
      state.address0 = address;
    },
    changeChain(state, chain) {
      localStorage.setItem("chain", chain);
      state.chain = chain;
    },
  },
  actions: {
    setMetaBot({ commit }, payload) {
      commit("getMetaBot", payload);
    },
    updataMoney({ commit }, payload) {
      commit("getMoney", payload);
    },
    setSaleNftList({ commit }, payload) {
      commit("getSaleNftList", payload);
    },
    setNftList({ commit }, payload) {
      commit("getNftList", payload);
    },
    setFtList({ commit }, payload) {
      commit("getFtList", payload);
    },
    setCurNftItem({ commit }, payload) {
      commit("getCurNftItem", payload);
    },
    setCurFtItem({ commit }, payload) {
      commit("getCurFtItem", payload);
    },
    showMem({ commit }, payload) {
      commit("showMem", payload);
    },
    loginType({ commit }, payload) {
      commit("loginType", payload);
    },
    loginPhone({ commit }, payload) {
      commit("loginPhone", payload);
    },
    loginEmail({ commit }, payload) {
      commit("loginEmail", payload);
    },
    userData({ commit }, payload) {
      commit("userData", payload);
    },
    logout({ commit }) {
      commit("logout");
    },
    autoLogin({ commit }, payload) {
      commit("autoLogin", payload);
    },
  },
});
