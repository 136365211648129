<template>
  <div class="Web_Top" ref="indexTop">
    <div class="money_top">
      <div class="top_left">
        <div class="top_left" @click="gotoUrl('index')">
          <img src="../assets/images/VisionMoney.png" alt="" />
        </div>

        <div class="powered-by">
          <div class="">Powered by</div>
          <div class="chain">{{ $store.state.chain }}</div>
        </div>
      </div>

      <div class="top_right">
        <div class="lang">
          <el-dropdown @command="setLang">
            <span class="el-dropdown-link">
              {{ lang }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="zh">中文</el-dropdown-item>
              <el-dropdown-item command="en">English</el-dropdown-item>
              <el-dropdown-item command="jp">日本</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="user" v-if="user && $store.state.UserLogin">
          <div class="user_box">
            <span>
              {{ $store.state.userData.name }}
            </span>
            <div class="img" :class="userC ? 'userC' : ''">
              <img :src="userImg" alt="" />
            </div>
            <div class="user_menu">
              <span @click="gotoUrl('user')">
                <!-- 编辑资料 -->
                {{ $t("PersonalCenter") }}
              </span>
              <!-- <span @click="gotoUrl('open')">
                {{ $t("DeveloperPlatform") }}
              </span> -->
              <span @click="outLogin()">
                <!-- 退出登录 -->
                {{ $t("quit") }}
              </span>
            </div>
          </div>
          <s @click="gotoUrl('index')" v-if="$route.name === 'user'">
            <!-- 返回 -->
            {{ $t("back") }}
          </s>
        </div>
      </div>
    </div>
    <router-view
      v-if="$store.state.autoLogin || loginShow"
      class="index_body"
      ref="routerMain"
    />
    <div
      class="password_box"
      v-if="!$store.state.autoLogin && !loginShow && userShow"
    >
      <div class="password">
        <h2>
          <!-- 验证密码 -->
          {{ $t("verifyPassword") }}
        </h2>
        <div class="user_id">
          <span>
            <!-- 用户名 -->
            {{ $t("userName") }}
            : {{ user.register == "email" ? user.email : user.phone }}
          </span>
        </div>
        <div class="input_box">
          <input
            :type="passwordEye ? 'text' : 'password'"
            :placeholder="$t('inputPassword')"
            @keyup.enter="verifyLogin()"
            v-model="password"
          />
          <img
            v-if="!passwordEye"
            src="../assets/images/list_icon_eye_1@2x.png"
            alt=""
            @click="passwordEye = !passwordEye"
          />
          <img
            v-if="passwordEye"
            src="../assets/images/list_icon_eye_2@2x.png"
            alt=""
            @click="passwordEye = !passwordEye"
          />
        </div>
        <!-- <el-checkbox v-model="$store.state.loginShow" class="auto_login">
          自动登录
          {{$t('autoLogin')}}
        </el-checkbox> -->
        <div class="login_button">
          <button
            @click="verifyLogin()"
            v-loading="loginLoading"
            :disabled="loginLoading"
          >
            <!-- 确认 -->
            {{ $t("affirm") }}
          </button>
          <p @click="outLogin()">
            <!-- 取消 -->
            {{ $t("cancel") }}
          </p>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "./footer.vue";
import { getuserinfo, getOwnShowAccount } from "@/api/api";
import { getEnCryptedMnemonic } from "../api/api";
import { mapState } from "vuex";
import { avatarUrl } from "../common/js/baseApi";

import { Locale } from "vant";
import enUS from "vant/es/locale/lang/en-US";
import zhCN from "vant/es/locale/lang/zh-CN";
import jaJP from "vant/es/locale/lang/ja-JP";

export default {
  data() {
    return {
      passwordEye: false,
      user: "",
      userC: false,
      loginShow: false,
      autoLogin: false,
      loginLoading: false,
      userImg: avatarUrl() + this.$store.state.userData.metaId,
      password: "",
      lang: "中文",
      userShow: false,
      loginType:
        localStorage.getItem("loginType") ||
        (this.$store.state.userData.phone ? "phone" : "email"),
    };
  },
  components: {
    Footer,
  },
  filters: {
    userHeader(metaId) {
      return `${avatarUrl()}${metaId}`;
    },
  },
  watch: {
    "$store.state.userData": {
      handler: function (res) {
        if (this.$store.state.userData === "") return;
        // console.log(this.$store.state.userData, '用户发生了变化')
        this.user = this.$store.state.userData;
        if (res.headUrl) {
          this.userImg = res.headUrl;
        } else {
          this.userImg = avatarUrl() + this.$store.state.userData.metaId;
        }
        // if (!this.user.headUrl) {
        //   this.user.headUrl =
        //     "https://showjob.oss-cn-hangzhou.aliyuncs.com/index/img_photo_default.png";
        // }
        if (!this.user.register && !this.user.token) {
          this.loginType = this.user.phone ? "phone" : "email";
          this.getUserInfo();
          this.$forceUpdate();
        }
        // console.log(this.$store.state.userData.headUrl)
        // console.log(this.user, '变化后的 user')
      },
      deep: true,
    },
    "$store.state.wallet": {
      handler: function (wallet) {
        // 获取转账地址
        this.$store.state.tokenList = wallet.tokenized.tokenizedList.filter(
          (item) => {
            return item.symbol !== "USD";
          }
        );
      },
    },
    "$store.state.headUrl": {
      handler: function (headUrl) {
        // 获取转账地址
        this.userImg = headUrl;
      },
    },
    "$store.state.UserLogin": {
      handler: function (UserLogin) {
        if (UserLogin && !this.$store.state.userData.metaId) {
          this.userImg =
            "https://showpay.oss-cn-beijing.aliyuncs.com/defaultAvatar/img_user_default@2x.png";
        }
      },
    },
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      if (!this.user) return;
      let params = {
        // type: 2
      };
      let re =
        /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,16}$/;
      if (
        re.test(
          this.user.register == "email" ? this.user.email : this.user.phone
        )
      ) {
        params.userType = "email";
        params.email = this.user.register == "email" ? this.user.email : null;
      } else {
        params.userType = "phone";
        params.phone = this.user.register == "phone" ? this.user.phone : null;
      }
      const that = this;
      const wallet = this.$store.state.wallet;
      // 获取用户信息头像用户名等
      console.log(params);
      getuserinfo(params)
        .then((res) => {
          // 转账开始进行 metanet
          this.$store.state.userData.name = res.userName;
          this.$store.state.userData.email = res.email;
          this.$store.state.userData.phone = res.phone;
          localStorage.setItem("loginType", res.register);
          if (res.paymail.length > 0) {
            this.$store.state.userData.paymail = res.paymail[0];
          }
          console.log(this.$store.state.userData);
          if (res.metaId) {
            this.$store.state.userData.metaId = res.metaId;
          }
          // let data = {
          //   address: store.state.metanet.rootAddress.address,
          //   xpub: store.state.wallet.getXpub()
          // }
          // console.log(userData)
          // console.log(store.state.userData)
          let metaparams = {
            showId: this.$store.state.userData.metaId,
          };
          getOwnShowAccount(JSON.stringify(metaparams)).then((res) => {
            // console.log(res)
            // console.log(store.state.userMeta)
            if (res.showId) {
              let result = res;
              this.$store.state.metaShow = false;
              this.$store.state.userMeta = result;
              this.$store.state.userData.name = result.name;
              that.$utils.getShowIDUserData();
              if (this.$store.state.userMeta.headUrl) {
                // console.log('我进来了')
                let str = this.$store.state.userMeta.headUrl;
                if (!str) {
                  return new Uint8Array();
                }
                var a = [];
                for (let i = 0, len = str.length; i < len; i += 2) {
                  a.push(parseInt(str.substr(i, 2), 16));
                }
                var binary = "";
                var bytes = new Uint8Array(a);
                var len = bytes.byteLength;
                for (var i = 0; i < len; i++) {
                  binary += String.fromCharCode(bytes[i]);
                }
                let sty = window.btoa(binary);
                let type = "image/jpeg";
                this.$store.state.userData.headUrl =
                  "data:" + type + ";base64," + sty;
              }
              console.log(this.$store.state.userData);
              that.$forceUpdate();
              localStorage.setItem(
                "localuserData",
                JSON.stringify(this.$store.state.userData)
              );
            }
          });
        })
        .catch((err) => {
          return that.$message.error(that.$t("Error") + err.message);
        });
    },
    setLang(lang) {
      switch (lang) {
        case "zh":
          this.lang = "中文";
          this.$i18n.locale = "zh";
          localStorage.setItem("lang", "zh");
          this.$cookie.set("lang", "zh");
          Locale.use("zh-CN", zhCN);
          break;
        case "en":
          this.lang = "English";
          this.$i18n.locale = "en";
          this.$cookie.set("lang", "en");
          localStorage.setItem("lang", "en");
          Locale.use("en-US", enUS);
          break;
        case "jp":
          this.lang = "日本";
          this.$i18n.locale = "jp";
          this.$cookie.set("lang", "jp");
          localStorage.setItem("lang", "jp");
          Locale.use("ja-JP", jaJP);
          break;
      }
    },
    outLogin() {
      // this.$store.state.userData = "";
      // localStorage.clear();
      // sessionStorage.clear();
      this.$store.commit("logout");
      this.loginLoading = false;
      this.userShow = false;
      this.loginShow = true;
      this.$router.replace({ name: "login" });
      location.reload();
    },
    verifyLogin() {
      this.loginLoading = true;
      setTimeout(async () => {
        // let key = this.$ShowMoney.noneMnemonic.createMasterHdPrivateKey(
        //   this.user.register,
        //   this.password,
        //   this.user.pk2,
        //   this.user.tag
        // );
        let accessKey = this.$utils.encrypt(
          this.password,
          this.user.pk2.slice(0, 32)
        );
        // console.log(this.$utils.decrypt(accessKey, this.user.pk2.slice(0, 32)))
        // if (this.$store.state.loginShow) {
        //   // console.log('自动登录')
        //   localStorage.setItem("accessKey", accessKey);
        // } else {
        //   // console.log('非自动登录')
        //   sessionStorage.setItem("accessKey", accessKey);
        // }
        localStorage.setItem("accessKey", accessKey);
        localStorage.setItem("loginType", this.user.register);
        // const de_mnemonic = this.$ShowMoney.noneMnemonic.decodeMnemonic(
        //   key.mnemonic,
        //   key.ppk
        // );

        let de_mnemonic = "";
        const phone =
          this.user.register == "email" ? this.user.email : this.user.phone;
        const result = await getEnCryptedMnemonic({
          userType: this.user.register,
          phone: this.user.register == "phone" ? phone : "",
          email: this.user.register == "email" ? phone : "",
          token: this.user.token,
        });
        if (result.code == 601) {
          const _m = this.$utils.createMnemonicByPk2(
            phone,
            this.password,
            this.user.pk2,
            this.user.tag
          );
          const zeroAddress = this.$utils.creadZeroAddress(_m, this.user.tag);

          const checkResult = await this.$utils
            .checkPasswordAddress(zeroAddress, this.loginType, phone, this)
            .catch((err) => err);
          if (checkResult) {
            this.$store.dispatch("autoLogin", true);
            this.$nextTick(() => {
              this.$router.replace({
                name: "setPassword",
                password: this.password,
              });
            });
          } else {
            this.$message.error(this.$t("passWordErr"));
          }
          return;
        }
        de_mnemonic = this.$utils.aesDescryptMem(
          result.enCryptedMnemonic,
          this.password
        );
        if (!de_mnemonic) {
          this.loginLoading = false;
          return this.$message.error(this.$t("passWordErr"));
        }

        console.log("解码后的助记词：", de_mnemonic);
        const encrypt_mnemonic = this.$utils.aesEncryptMem(
          de_mnemonic,
          this.password
        );
        const encrypt_password = this.$utils.encryptPassword(this.password);
        this.$utils
          .newWallet(
            { ppk: "", mnemonic: de_mnemonic },
            "",
            "",
            encrypt_mnemonic
            // encrypt_password
          )
          .then((r) => {
            //TODO:bug
            this.$store.state.metanet = r;
            this.$store.state.autoLogin = true;
            this.$forceUpdate();
            console.log("verifyLogin", this.$route);
            this.$router.push({ name: this.$route.name });
          })
          .catch((err) => {
            this.loginLoading = false;
            console.log(err);
            if (err === "The accessKey failed") {
              // localStorage.clear();
              this.userShow = false;
              this.loginShow = true;
              this.outLogin();
              return this.$message.error(
                this.$t("Error") + err + "Please log in again"
              );
            }
            if (err === "incorect address") {
              return this.$message.error(this.$t("passWordErr"));
            }
            return this.$message.error(this.$t("Error") + err);
          });
      }, 50);
    },
  },
  created() {
    let path = [
      "sign",
      "login",
      "logintest",
      "404",
      "test",
      "Matesign",
      "userLogin",
      "authorize",
      "forgetPassword",
      "setPassword",
      "thirdSetPassword",
    ];
    // console.log(this.$route.name)
    let loginShow = path.some((item) => {
      return this.$route.name === item;
    });
    // console.log(loginShow)
    if (loginShow) {
      this.loginShow = true;
    }
    if (localStorage.getItem("lang")) {
      this.setLang(localStorage.getItem("lang"));
    } else {
      this.setLang("en");
    }
    let localuserData = JSON.parse(localStorage.getItem("localuserData")) || "";
    if (localuserData) {
      this.userShow = true;
    }
    if (this.$store.state.userData) {
      this.user = this.$store.state.userData;
      if (this.user.register) {
        this.getUserInfo();
      }
      // if (!this.user.headUrl) {
      //   this.user.headUrl =
      //     "https://showjob.oss-cn-hangzhou.aliyuncs.com/index/img_photo_default.png";
      // }
      // console.log(this.$store.state.autoLogin)
    }
  },
};
</script>

<style lang="scss" scoped>
.Web_Top {
  width: 100vw;
  position: relative;
}
.top_left {
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  s {
    width: 1px;
    height: 12px;
    margin: 0 10px;
    display: inline-block;
    background: #eab300;
  }

  .powered-by {
    margin-left: 10px;
    line-height: 12px;
    font-size: 10px;
    color: #4b5563;
    .chain {
      color: #eab300;
      font-weight: 700;
    }
  }
}

.index_body {
  padding-bottom: 120px;
  min-height: 100vh;
}
.money_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1080px;
  margin: 0 auto;
  height: 68px;
  max-height: 7vh;
  padding: 0 10px;
  img {
    height: 25px;
    margin-top: -5px;
  }
}
.user {
  display: flex;
  align-items: center;
  height: 100%;
  .user_box {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    span {
      font-size: 16px;
      color: #606266;
    }
    .user_menu {
      position: absolute;
      right: 0;
      // display: none;
      opacity: 0;
      transition: 0.3s all ease-in-out;
      top: 100%;
      cursor: pointer;
      width: 160px;
      background: #fff;
      span {
        height: 45px;
        display: block;
        line-height: 45px;
        text-align: center;
        font-size: 14px;
        color: #606266;
      }
    }
  }
  .user_box:hover .user_menu {
    // display: block;
    transition: 0.3s all ease-in-out;
    opacity: 1;
    z-index: 9;
  }
  .img {
    cursor: pointer;
    background: #fff;
    width: 60px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.1s all ease-in-out;
    position: relative;
    img {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .userC {
    transition: 0.1s all ease-in-out;
    border-top: 3px solid #eab300;
    background: rgba(234, 179, 0, 0.1);
  }
  > s {
    padding-left: 10px;
    border-left: 1px solid #e7e8e9;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    color: #606266;
    font-weight: bold;
    cursor: pointer;
  }
}
.top_right {
  display: flex;
  height: 100%;
  align-items: center;
}
.lang {
  cursor: pointer;
  margin-right: 10px;
}
.password_box {
  background: url("../assets/images/emb_line.png") no-repeat;
  background-color: #303133;
  background-position: 50%;
  width: 100vw;
  background-size: contain;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: calc(100vh - 148px);
  align-items: center;
  justify-content: center;
  .password {
    width: 315px;
    border-radius: 23px;
    background: #fff;
    max-height: 500px;
    padding: 30px;
    h2 {
      font-size: 21px;
      font-weight: bold;
      color: rgba(48, 49, 51, 1);
    }
    .user_id {
      margin-top: 20px;
      font-size: 16px;
      min-height: 60px;
    }
    .auto_login {
      margin-top: 20px;
      font-size: 16px;
    }
    .input_box {
      height: 60px;
      font-size: 15px;
      line-height: 60px;
      width: 100%;
      border-bottom: 1px solid #bfc2cc;
      transition: 0.3s all ease-in-out;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: 25px;
        height: 25px;
      }
      input {
        height: 59px;
        font-size: 15px;
        color: #303133;
        border: none;
        display: block;
        outline: none;
        width: 100%;
      }
    }
    .login_button {
      button {
        cursor: pointer;
        max-width: 270px;
        width: 100%;
        height: 60px;
        background: #eab300;
        border-radius: 12px;
        color: #fff;
        font-size: 24px;
        line-height: 60px;
        display: block;
        margin: 0 auto;
        margin-top: 40px;
        text-align: center;
      }
      p {
        margin-top: 20px;
        text-align: center;
        color: #606266;
        font-size: 14px;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .money_top img {
    height: 25px;
  }
}
</style>
