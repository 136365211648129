<template>
  <div id="app" v-if="appShow">
    <router-view />
    <el-dialog
      class="memWrap"
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="$store.state.showMem"
      @close="memCloseHandle"
    >
      <div>
        <div class="memTip" v-if="!isConfirmMem">
          <div class="memTipImgWrap">
            <div class="tipImg"></div>
          </div>
          <div class="memTipContetn">
            <p class="tipTile">{{ $t("safetyAlert") }}</p>
            <ul>
              <ol>
                · ShowPay{{
                  $t("mtip1")
                }}
              </ol>
              <ol>
                ·
                {{
                  $t("mtip2")
                }}
              </ol>
              <ol>
                ·
                {{
                  $t("mtip3")
                }}
              </ol>
            </ul>
          </div>
          <div>
            <button @click="startBackUp" class="memBtn">
              {{ $t("startBackupLabel") }}
            </button>
          </div>
        </div>
        <div class="backupMemWrap" v-if="isConfirmMem">
          <div class="title">{{ $t("backupMem") }}</div>
          <div class="tip">{{ $t("mtip4") }}</div>
          <div class="memShowBox">
            {{ memStr }}
          </div>

          <ul class="tip2">
            <ol>
              ·
              {{
                $t("mtip5")
              }}
            </ol>
            <ol>
              ·
              {{
                $t("mtip6")
              }}
            </ol>
          </ul>
          <div>
            <el-button
              @click.stop="finishBackup"
              class="finishMemBtn"
              :loading="finisedBackupLabelLoading"
              :disabled="disabled"
            >
              {{ $t("finisedBackupLabel") }}
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { refreshToken } from "@/api/api.js";
import { settoken } from "./api/api";
import { mapState } from "vuex";
export default {
  data() {
    return {
      isConfirmMem: false,
      memStr: "",
      memPath: "",
      finisedBackupLabelLoading: false,
      disabled: false,
    };
  },
  computed: {
    ...mapState(["appShow", "wallet"]),
  },
  watch: {
    "$store.state.wallet"(wallet) {
      if (!this.memStr) {
        const memObj = wallet._ppk
          ? wallet.getMnemonic()
          : { mnemonic: wallet._mnemonic_str, path: wallet._path };
        this.memStr = memObj.mnemonic;
        this.memPath = memObj.path;
      }
    },
    // "$i18n.locale"() {
    //   // this.$store.state.lang = this.$i18n.locale === "zh" ? "zh" : "en";
    //   // this.getBsvRate()
    // },
    // '$store.state.rootText': function () {
    //   // let err = JSON.stringify(this.$store.state.rootText)
    //   return this.$message.error('' + this.$store.state.rootText)
    // }
  },
  created() {
    let token = this.$cookie.get("userData") || "";
    console.log(!this.$cookie.get("__Injector-time"));
    // if (localStorage.getItem('loginShow')) {
    //   this.$store.state.loginShow = localStorage.getItem('loginShow')
    // }
    // console.log(this.$store.state.loginShow)
    if (token) {
      this.$store.state.userData =
        JSON.parse(localStorage.getItem("localuserData")) || "";
      refreshToken({
        token: this.$store.state.userData.token,
        type: "2",
      }).catch((err) => {
        if (err.message === "invalid token") {
          this.$store.commit("logout");
        }
      });
      // console.log(JSON.parse(localStorage.getItem('localuserData')))
    }
    // console.log(this.$store.state.currency)
    if (localStorage.getItem("currency")) {
      this.$store.state.currency = localStorage.getItem("currency");
    }
    if (localStorage.getItem("lang")) {
      this.$store.state.lang = localStorage.getItem("lang");
      this.$i18n.locale = localStorage.getItem("lang");
    } else {
      this.$store.state.lang = this.$i18n.locale === "zh" ? "zh" : "en";
    }
  },
  mounted() {
    if (this.$store.state.wallet && !this.memStr) {
      if (this.$store.state.wallet._ppk) {
        const memObj = this.$store.state.wallet.getMnemonic();

        this.memStr = memObj.mnemonic;
        this.memPath = memObj.path;
      } else {
        this.memStr = this.$store.state.wallet._mnemonic_str;
        this.memPath = this.$store.state.wallet._path;
      }
    }
  },
  methods: {
    memCloseHandle() {},
    startBackUp() {
      this.isConfirmMem = true;
    },
    finishBackup() {
      this.finisedBackupLabelLoading = true;
      this.disabled = true;
      this.$utils.gettokenpageData().then((r) => {
        r["isSaveMem"] = "saved";
        settoken({
          userType: this.$store.state.userData.phone ? "phone" : "email",
          type: 2,
          tokenString: JSON.stringify(r),
          phone: this.$store.state.userData.phone,
          email: this.$store.state.userData.email,
        }).then(() => {
          this.$store.dispatch("showMem", false);
          this.finisedBackupLabelLoading = false;
          this.disabled = false;
          this.$message(this.$t("finisedBackupLabel"));
        });
      });
    },
  },
};
</script>

<style>
/* 主题颜色修改 */
</style>

<style lang="scss">
@import "./app.scss";
.index_box {
  background: url("./assets/images/emb_line.png") no-repeat;
  background-color: #303133;
  background-position: 0 200px;
  width: 100vw;
  background-size: contain;
  display: flex;
  justify-content: space-between;
  min-height: calc(100vh - 68px);
}
#app {
  width: 100%;
  margin: 0 auto;
  .login_box > .el-loading-mask {
    width: 200px;
    background-color: white;
    height: 200px;
    top: 50%;
    margin-top: -100px;
    left: 50%;
    border-radius: 10px;
    margin-left: -100px;
  }
}

.memWrap {
  ::v-deep .el-dialog {
    width: 500px;
    border-radius: 20px;
    border: 1px solid #707070;
    .el-dialog__header {
      display: none;
    }
  }
  .memTip {
    .memTipImgWrap {
      .tipImg {
        width: 207px;
        height: 110px;
        background: url("./assets/images/pic-pc@2x.png");
        background-repeat: no-repeat;
        background-size: 100%;
        margin: 0 auto;
      }
    }
    .memTipContetn {
      margin-top: 48px;
      margin-bottom: 60px;
      .tipTile {
        font-size: 24px;
        font-weight: 500;
        line-height: 33px;
        color: #303133;
        margin-bottom: 18px;
      }
      ol {
      }
    }
  }
  .backupMemWrap {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #606266;
    .title {
      font-size: 24px;
      font-weight: 500;
      line-height: 33px;
      color: #303133;
    }
    .tip {
      margin: 14px 0 25px 0;
    }
    .memShowBox {
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
      color: #303133;
      background: #f5f7fa;
      border-radius: 16px;
      min-height: 162px;
      padding: 10px 15px;
    }
    .tip2 {
      margin-top: 17px;
      margin-bottom: 35px;
    }
    .finishMemBtn {
      width: 100%;
      height: 55px;
      background-color: #eab300ff;
      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      border-radius: 16px;
      cursor: pointer;
    }
  }
  .memBtn {
    width: 100%;
    height: 55px;
    background-color: #eab300ff;
    font-size: 20px;
    font-weight: 500;
    line-height: 55px;
    color: #ffffff;
    text-align: center;
    border-radius: 16px;
    cursor: pointer;
  }
}
@media only screen and (max-width: 600px) {
  .memWrap {
    ::v-deep .el-dialog {
      width: 90% !important;
    }
    .el-dialog {
      width: 90% !important;
    }
  }
}
</style>
