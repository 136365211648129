import Api from "./base";
import axios from "axios";
import { walletApi } from "../common/js/baseApi";
// import store from '@/store.js'
// import qs from 'qs'
// 登录第三方
export const authorizeLogin = (params) => {
  return Api.ajaxPost("/oauth2/user/login", params, walletApi());
};

// 第三方登录(获取短信/邮件接口)
export const loginVerification = (params) => {
  return Api.ajaxPost(
    "/oauth2/api/v1/user/login/verification",
    params,
    walletApi()
  );
};

// 第三方登录(验证短信/邮件接口)
export const logincheck = (params) => {
  return Api.ajaxPost("/oauth2/api/v1/user/login/check", params, walletApi());
};

// 退出登录
export const authorizeLoginOut = (params) => {
  return Api.ajaxGet(
    "/oauth2/user/logout?user_token=" + params,
    {},
    walletApi()
  );
};

// 验证第三方是否登录
export const authorizeCheck = (params) => {
  return Api.ajaxGet(
    "/oauth2/api/v1/user/check?user_token=" + params,
    {},
    walletApi()
  );
};
export const authCheck = async (params) => {
  console.log("检查token中", walletApi());
  // const res = await Api.ajaxGet(
  //   "/oauth2/oauth/check_token?token=" + params,
  //   {},
  //   walletApi()
  // ).then((res) => {
  //   if (res.status === 200) {
  //     return res.data;
  //   } else {
  //     return null;
  //   }
  // });
  // console.log("检查token结果", res);
  // return res;
  const res = await axios({
    method: "get",
    url: `${walletApi()}/oauth2/oauth/check_token?token=` + params,
  }).then((res) => {
    if (res.status === 200) {
      return res.data;
    } else {
      return null;
    }
  });
  return res;
};

// 获取授权的HTML
export const GetHtml = (url) => {
  return Api.ajaxGet(url, {}, process.env.VUE_APP_AUTH_URL);
};

// 获取登录第三方的应用名
export const getClient = (params) => {
  return Api.ajaxPost("/oauth2/api/v1/client/getName", params, walletApi());
};
