import "core-js/es7/string";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n";
import Decimal from "decimal.js";
// import ELEMENT from "element-ui";
import "./assets/css/theme/index.css";
import utils from "./common/js/util";
import moment from "moment";
import VueClipboard from "vue-clipboard2";
import VueCookie from "vue-cookies";
import VueCropper from "vue-cropper";
// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";
import "@vant/touch-emulator";
import "vant/lib/index.css";
import {
  Tab,
  Tabs,
  Form,
  Field,
  Button,
  RadioGroup,
  Radio,
  Loading,
  DatetimePicker,
  Dialog,
} from "vant";
import VueCountryIntl from "vue-country-intl";
// import css
import "vue-country-intl/lib/vue-country-intl.css";
// Global registration component
Vue.component("VueCountryIntl", VueCountryIntl);
// if(process.env.NODE_ENV === "production"){
//   Sentry.init({
//     Vue: Vue,
//     dsn: "https://5306a50a644a43da95daf6eea9eac278@o358408.ingest.sentry.io/5725210",
//     integrations: [new Integrations.BrowserTracing()],
//     tracesSampleRate: 1
//   });
// }
VueClipboard.config.autoSetContainer = true;
Vue.config.devtools = true;

Vue.use(VueCropper);
Vue.use(Button);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Form);
Vue.use(Field);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Dialog);
Vue.use(Loading);
Vue.use(DatetimePicker);
Vue.use(VueClipboard);
Vue.config.productionTip = false;
// Vue.use(ELEMENT);
Vue.use(VueI18n);

// Vue.use(VueCookie)
// Vue.config.devtools = true
VueCookie.config("7d", "", "", true, "None");
Vue.prototype.$utils = utils;
Vue.prototype.$moment = moment;
Vue.prototype.$cookie = VueCookie;
// VueCookie.config('7d', '/123', '/123', false)
// console.log(process.env.NODE_ENV)
// Vue.prototype.$storejs = storejs
// Vue.prototype.conditionSet = process.env.VUE_APP_CONDITION_SET
// 格式转换 df344e44-013a-4621-b154-1f8126d9ff22
Vue.prototype.$ShowMoney = window.ShowMoney;
Vue.prototype.$wallet = "";
// const i18n = new VueI18n({
//   locale: 'zh',
//   // this.$i18n.locale
//   messages: {
//     'zh': require('./common/lang/zh'),
//     'en': require('./common/lang/en')
//   }
// })
let lang = "en";
// switch (navigator.language.toLowerCase()) {
//   case "zh-tw":
//   case "zh-hk":
//   case "zh-cn":
//   case "zh":
//     lang = "zh";
//     break;
//   default:
//     lang = "en";
//     break;
// }
if (VueCookie.get("lang")) {
  lang = VueCookie.get("lang");
}
const i18n = new VueI18n({
  locale: lang, // 定义默认语言为英文
  fallbackLocale: lang,
  messages: {
    zh: require("@/assets/languages/zh.json"),
    en: require("@/assets/languages/en.json"),
    jp: require("@/assets/languages/jp.json"),
  },
});

// if (process.env.NODE_ENV == 'development') {
//   Vue.config.devtools = true;
// } else {
//   Vue.config.devtools = false;
// }
let timeout;
Vue.prototype.debounce = (func, wait, immediate) => {
  // let context = this
  // let args = arguments
  if (timeout) {
    clearTimeout(timeout);
  }
  if (immediate) {
    let callNow = !timeout;
    timeout = setTimeout(() => {
      timeout = null;
    }, wait);
    if (callNow) func();
  } else {
    timeout = setTimeout(() => {
      func();
    }, wait);
  }
};
Vue.prototype.gotoUrl = function (name, params) {
  let that = this;
  // console.log(params, 123)
  that.$router.push({
    name: name,
    query: {
      ...params,
    },
  });
};
// 保留小数
Vue.prototype.cutNumberWithZero = (value, decima) => {
  let result = value;
  if (!isNaN(Number(result))) {
    result = new Decimal(result);
    result = Decimal.floor(result.mul(Decimal.pow(10, decima)))
      .div(Decimal.pow(10, decima))
      .toFixed(decima);
  } else {
    result = "";
  }
  return result;
};
Vue.prototype.cutNumberNoZero = (value, decima) => {
  let result = value;
  if (result && !isNaN(Number(result))) {
    result = $vm.cutNumberWithZero(value, decima);
    let max = result.length;
    let left = 0;
    if (result && result.length > 0 && result.indexOf(".") > -1) {
      left = result.split(".")[0].length + 1;
      for (let i = result.length - 1; i >= 0; i--) {
        if (result[i] === "0") {
          max = i;
        } else if (result[i] === ".") {
          max = i;
          break;
        } else {
          break;
        }
      }
      max = max > left + decima ? left + decima : max;
      result = result.substring(0, max);
    }
  }
  return result;
};
// 只允许输入数字，并且小数点只能有一个
Vue.directive("enterNumber2", {
  inserted: function (el) {
    el.addEventListener("keypress", function (e) {
      e = e || window.event;
      let charcode = typeof e.charCode === "number" ? e.charCode : e.keyCode;
      let re = /\d/;
      if (charcode === 46) {
        if (el.value.includes(".")) {
          return e.preventDefault();
        }
      } else if (
        !re.test(String.fromCharCode(charcode)) &&
        charcode > 9 &&
        !e.ctrlKey
      ) {
        if (e.preventDefault) {
          e.preventDefault();
        } else {
          e.returnValue = false;
        }
      }
    });
  },
});
let $vm = new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
