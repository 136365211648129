import Vue from "vue";
import Router from "vue-router";
import indexTop from "./components/indexTop.vue";
import link from "./views/link.vue";
import transfer from "./views/tansferFt.vue";
import nftcreate from "./views/wallet/NFTcreate.vue";
import showcreate from "./views/wallet/NFTshow.vue";
import issuenft from "./views/wallet/issueNFT.vue";
import utxoMerge from "./views/wallet/utxoMerge.vue";
import transfermc from "./views/wallet/sendMC.vue";
import transferft from "./views/wallet/sendft.vue";
import issuenos from "./views/wallet/IssueNos.vue";
import checkSign from "./views/checkSign.vue";
// import { getInfoAddressAndTxId } from '@/api/api'
import utils from "./common/js/util";
// eslint-disable-next-line no-unused-vars
import store from "@/store";
import { getEnCryptedMnemonic } from "./api/api";
const that = Vue.prototype;
Vue.use(Router);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};
const router = new Router({
  mode: "history",
  // base: '/wallet/',
  routes: [
    {
      path: "",
      name: "",
      component: indexTop,
      children: [
        {
          path: "",
          name: "index",
          component: () => import("./views/wallet/index.vue")
        },
        {
          path: "/user",
          name: "user",
          component: () => import("./views/user/index.vue")
        },
        {
          path: "/open",
          name: "open",
          component: () => import("./views/open/index.vue")
        },
        {
          path: "/created_app",
          name: "created_app",
          component: () => import("./views/open/createdApp.vue")
        },
        {
          path: "/editPassword",
          name: "editPassword",
          component: () => import("./views/user/editPassword.vue")
        },
        {
          path: "/forgetPassword",
          name: "forgetPassword",
          component: () => import("./views/user/forgetPassword.vue")
        },
        {
          path: "/setPassword",
          name: "setPassword",
          component: () => import("./views/user/setPasword.vue")
        },
        {
          path: "/thirdSetPassword",
          name: "thirdSetPassword",
          component: () => import("./views/thirdSetPassword.vue")
        },
        {
          path: "/mergeutxo",
          name: "mergeutxo",
          component: utxoMerge
        },
        {
          path: "/link",
          name: "link",
          component: link
        },
        {
          path: "/linktest",
          name: "linktest",
          component: checkSign
        },
        {
          path: "/transfer",
          name: "transfer",
          component: transfer
        },
        {
          path: "/transfer/ft",
          name: "transfermc",
          component: transfermc
        },
        {
          path: "/transfer/bactch/ft",
          name: "transferft",
          component: transferft
        },
        {
          path: "/createtoken",
          name: "nftcreate",
          component: nftcreate
        },
        {
          path: "/createshow",
          name: "showcreate",
          component: showcreate
        },
        {
          path: "/issuenft",
          name: "issuenft",
          component: issuenft
        },
        {
          path: "/issuenft/ubc",
          name: "issuenos",
          component: issuenos
        },
        // {
        //   path: "/faucet",
        //   name: "faucet",
        //   component: () => import("./views/faucet/faucet.vue")
        // }
      ],
      beforeEnter(to, from, next) {
        let token = that.$cookie.get("userData");
        let userData =
          JSON.parse(localStorage.getItem("localuserData")) || undefined;
        // 获取本地 cookie
        console.log("tofrom", to, from);
        if (from.name === "userLogin" && to.name == "thirdSetPassword") {
          next();
          return;
        } else if (from.name === "login" && to.name !== "login") {
          next();
          return;
        } else if (!userData || !token) {
          console.log("删除密码");
          // localStorage.clear();
          that.$cookie.remove("userData");
          if (to.name === "link") {
            localStorage.setItem("tolink", true);
          }
          console.log(Vue);
          if (to.query.user && to.query.pubkey && to.query.sig) {
            localStorage.setItem(
              "binding",
              JSON.stringify({
                user: to.query.user,
                pubkey: to.query.pubkey,
                sign: to.query.sig
              })
            );
          }
          // next()
          next({ name: "login" });
          return;
        } else {
          // 实例化钱包
          store.state.userData = userData;
          if (to.name === "link") {
            localStorage.setItem("tolink", true);
          }
          if (to.query.user && to.query.pubkey && to.query.sig) {
            localStorage.setItem(
              "binding",
              JSON.stringify({
                user: to.query.user,
                pubkey: to.query.pubkey,
                sign: to.query.sig
              })
            );
          }
          if (!store.state.wallet) {
            let key;
            if (
              localStorage.getItem("accessKey") ||
              sessionStorage.getItem("accessKey")
            ) {
              let accessKey =
                localStorage.getItem("accessKey") ||
                sessionStorage.getItem("accessKey");
              try {
                accessKey = that.$utils.decrypt(
                  accessKey,
                  store.state.userData.pk2.slice(0, 32)
                );
              } catch {
                // console.log(to)
                next({ name: "login" });
              }
              if (accessKey) {
                const userType =
                  localStorage.getItem("loginType") ||
                  (userData.phone ? "phone" : "email");
                getEnCryptedMnemonic({
                  userType: userType,
                  phone: userType == "phone" ? userData.phone : "",
                  email: userType == "email" ? userData.email : "",
                  token: userData.token
                }).then(result => {
                  // console.log(accessKey)
                  if (result.code === 601) {
                    console.log("router getEnCryptedMnemonic");
                    return next({ name: "setPassword" });
                  }
                  const de_mnemonic = utils.aesDescryptMem(
                    result.enCryptedMnemonic,
                    accessKey
                  );
                  store.state.autoLogin = true;
                  if (!de_mnemonic) {
                    console.log("decode mnemonic error");
                    // localStorage.clear();
                    // sessionStorage.clear();
                    store.state.userData = null;
                    return next({ name: "login" });
                  }
                  utils
                    .newWallet({ ppk: "", mnemonic: de_mnemonic }, "login")
                    .then(() => {
                      next();
                    })
                    .catch(() => {
                      store.state.autoLogin = false;
                      next();
                    });
                  // key = window.ShowMoney.noneMnemonic.createMasterHdPrivateKey(
                  //   store.state.userData.register,
                  //   accessKey,
                  //   store.state.userData.pk2,
                  //   store.state.userData.tag
                  // );
                }).catch(error => {
                  console.log(error);
                });
              } else {
                store.state.autoLogin = false;
                next();
              }
            } else {
              store.state.autoLogin = false;
              next();
            }
          } else {
            next();
          }
        }
      }
    },
    {
      path: "/",
      name: "",
      component: indexTop,
      children: [
        {
          path: "/login",
          name: "login",
          component: () => import("./views/login.vue")
        },
        {
          path: "/sign",
          name: "sign",
          component: () => import("./views/signUp.vue")
        },
        {
          path: "/Matesign",
          name: "Matesign",
          component: () => import("./views/Matesign.vue")
        }
      ]
    },
    {
      path: "/",
      name: "",
      component: indexTop,
      children: [
        {
          path: "/userLogin",
          name: "userLogin",
          component: () => import("./views/thirdLogin.vue")
        },
        {
          path: "/authorize",
          name: "authorize",
          component: () => import("./views/authorize.vue")
        }
      ]
    },
    {
      path: "/iframe",
      name: "iframe",
      component: () => import("./views/main_frame.vue")
    },
    {
      path: "/privacy_policy",
      name: "privacy_policy",
      component: () => import("./components/privacy.vue")
    },
    {
      path: "/download_android",
      name: "download_android",
      component: () => import("./views/downloadAndroid.vue")
    },
    {
      path: '/app-recommend',
      name: 'appRecommend',
      component: () => import("./views/app-recommend.vue")
    },
    {
      path: "/",
      name: "",
      component: indexTop,
      children: [
        {
          path: "*",
          name: "404",
          component: () => import("./views/404.vue")
        }
      ]
    }
  ]
});
export default router;
