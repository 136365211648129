var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "indexTop", staticClass: "Web_Top" },
    [
      _c("div", { staticClass: "money_top" }, [
        _c("div", { staticClass: "top_left" }, [
          _c(
            "div",
            {
              staticClass: "top_left",
              on: {
                click: function ($event) {
                  return _vm.gotoUrl("index")
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../assets/images/VisionMoney.png"),
                  alt: "",
                },
              }),
            ]
          ),
          _c("div", { staticClass: "powered-by" }, [
            _c("div", {}, [_vm._v("Powered by")]),
            _c("div", { staticClass: "chain" }, [
              _vm._v(_vm._s(_vm.$store.state.chain)),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "top_right" }, [
          _c(
            "div",
            { staticClass: "lang" },
            [
              _c(
                "el-dropdown",
                { on: { command: _vm.setLang } },
                [
                  _c("span", { staticClass: "el-dropdown-link" }, [
                    _vm._v("\n            " + _vm._s(_vm.lang)),
                    _c("i", {
                      staticClass: "el-icon-arrow-down el-icon--right",
                    }),
                  ]),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c("el-dropdown-item", { attrs: { command: "zh" } }, [
                        _vm._v("中文"),
                      ]),
                      _c("el-dropdown-item", { attrs: { command: "en" } }, [
                        _vm._v("English"),
                      ]),
                      _c("el-dropdown-item", { attrs: { command: "jp" } }, [
                        _vm._v("日本"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.user && _vm.$store.state.UserLogin
            ? _c("div", { staticClass: "user" }, [
                _c("div", { staticClass: "user_box" }, [
                  _c("span", [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$store.state.userData.name) +
                        "\n          "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "img", class: _vm.userC ? "userC" : "" },
                    [_c("img", { attrs: { src: _vm.userImg, alt: "" } })]
                  ),
                  _c("div", { staticClass: "user_menu" }, [
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.gotoUrl("user")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("PersonalCenter")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.outLogin()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("quit")) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm.$route.name === "user"
                  ? _c(
                      "s",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.gotoUrl("index")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(_vm.$t("back")) + "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm.$store.state.autoLogin || _vm.loginShow
        ? _c("router-view", { ref: "routerMain", staticClass: "index_body" })
        : _vm._e(),
      !_vm.$store.state.autoLogin && !_vm.loginShow && _vm.userShow
        ? _c("div", { staticClass: "password_box" }, [
            _c("div", { staticClass: "password" }, [
              _c("h2", [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("verifyPassword")) + "\n      "
                ),
              ]),
              _c("div", { staticClass: "user_id" }, [
                _c("span", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("userName")) +
                      "\n          : " +
                      _vm._s(
                        _vm.user.register == "email"
                          ? _vm.user.email
                          : _vm.user.phone
                      ) +
                      "\n        "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "input_box" }, [
                (_vm.passwordEye ? "text" : "password") === "checkbox"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      attrs: {
                        placeholder: _vm.$t("inputPassword"),
                        type: "checkbox",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.password)
                          ? _vm._i(_vm.password, null) > -1
                          : _vm.password,
                      },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.verifyLogin()
                        },
                        change: function ($event) {
                          var $$a = _vm.password,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.password = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.password = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.password = $$c
                          }
                        },
                      },
                    })
                  : (_vm.passwordEye ? "text" : "password") === "radio"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      attrs: {
                        placeholder: _vm.$t("inputPassword"),
                        type: "radio",
                      },
                      domProps: { checked: _vm._q(_vm.password, null) },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.verifyLogin()
                        },
                        change: function ($event) {
                          _vm.password = null
                        },
                      },
                    })
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      attrs: {
                        placeholder: _vm.$t("inputPassword"),
                        type: _vm.passwordEye ? "text" : "password",
                      },
                      domProps: { value: _vm.password },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.verifyLogin()
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.password = $event.target.value
                        },
                      },
                    }),
                !_vm.passwordEye
                  ? _c("img", {
                      attrs: {
                        src: require("../assets/images/list_icon_eye_1@2x.png"),
                        alt: "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.passwordEye = !_vm.passwordEye
                        },
                      },
                    })
                  : _vm._e(),
                _vm.passwordEye
                  ? _c("img", {
                      attrs: {
                        src: require("../assets/images/list_icon_eye_2@2x.png"),
                        alt: "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.passwordEye = !_vm.passwordEye
                        },
                      },
                    })
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "login_button" }, [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loginLoading,
                        expression: "loginLoading",
                      },
                    ],
                    attrs: { disabled: _vm.loginLoading },
                    on: {
                      click: function ($event) {
                        return _vm.verifyLogin()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("affirm")) + "\n        "
                    ),
                  ]
                ),
                _c(
                  "p",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.outLogin()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("cancel")) + "\n        "
                    ),
                  ]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _c("Footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }