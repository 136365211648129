import store from "../../store";

export const walletApi = () => {
  return store.state.chain === "BSV"
    ? process.env.VUE_APP_WALLET_BSV_API
    : process.env.VUE_APP_WALLET_MVC_API;
};

export const imageUrl = () => {
  return store.state.chain === "BSV"
    ? process.env.VUE_APP_IMGURL
    : process.env.VUE_APP_MVC_IMGURL;
};

export const avatarUrl = () => {
  return store.state.chain === "BSV"
    ? process.env.VUE_APP_AVATARURL
    : process.env.VUE_APP_MVC_AVATARURL;
};

export const wsWalletApi = () => {
  return store.state.chain === "BSV"
    ? process.env.VUE_APP_WS_WALLET_BSV_API
    : process.env.VUE_APP_WS_WALLET_MVC_API;
};

export const aggregationApi = () => {
  return store.state.chain === "BSV"
    ? process.env.VUE_APP_BSV_AGGREGATION
    : process.env.VUE_APP_MVC_AGGREGATION;
};

export const useMainNet = () => {
  return store.state.chain === "BSV"
    ? process.env.VUE_APP_BSV_USEMAINNET == 1
    : process.env.VUE_APP_MVC_USEMAINNET == 1;
};

export const useTestNet = () => {
  return !useMainNet();
};
