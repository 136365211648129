<template>
  <div class="check-sign">
    <div class="item">
      <span>pubkey</span>
      <input type="text" v-model="pubkey"/>
    </div>
    <div class="item">
      <span>{{ $t('sign') }}</span>
      <input type="text" v-model="sign"/>
    </div>
    <p v-if="result">{{ $t('checkResult') }}:{{ result }}</p>
    <button @click="debounce(checkSign, 1000)">{{ $t('query') }}</button>
  </div>
</template>
<script>
import { signCheck } from '@/api/api'
export default {
  data () {
    return {
      pubkey: '',
      sign: '',
      result: ''
    }
  },
  created () {
    // console.log(this.$store.state.wallet.cur_private_key.xprivkey)
  },
  methods: {
    async checkSign () {
      if (this.pubkey === '' || this.sign === '') {
        return this.$message.error(this.$t('inputNull'))
      }
      try {
        await signCheck({
          pubkey: this.pubkey,
          sign: this.sign,
          tag: 'test'
        })
        this.result = this.$t('success')
      } catch (err) {
        this.$message.error(err.message)
        this.result = this.$t('nopass')
      }
    }
  },
  watch: {
    pubkey (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.result = ''
      }
    },
    sign (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.result = ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .check-sign {
    margin: 50px;
    .item {
      width: 500px;
      margin-bottom: 30px;
      height: 32px;
      line-height: 32px;
      span {
        font-size: 18px;
        font-weight: 400;
        width: 100px;
      }
      input {
        width: 400px;
        border: solid 1px #cccccc;
        border-radius: 5px;
        padding: 5px 10px;
      }
    }
    p {
      font-size: 18px;
      font-weight: 500;
    }
    button {
      width: 100px;
      height: 36px;
      line-height: 36px;
      font-size: 16px;
      font-weight: bold;
      margin-top: 20px;
      border-radius: 10px;
      cursor: pointer;
    }
  }
</style>
