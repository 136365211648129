var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "footer", class: _vm.getRouter ? "footer_none" : "" },
    [
      _c("div", { staticClass: "footer_box" }, [
        _vm._m(0),
        _c("div", { staticClass: "contact" }, [
          _c("span", [
            _vm._v("\n        " + _vm._s(_vm.$t("contactUs")) + ":\n      "),
          ]),
          _vm._m(1),
          _vm._m(2),
        ]),
        _c("div", { staticClass: "about" }, [
          _c(
            "span",
            {
              on: {
                click: function ($event) {
                  return _vm.opAlter()
                },
              },
            },
            [_vm._v("\n        " + _vm._s(_vm.$t("about")) + "\n      ")]
          ),
          _c(
            "span",
            {
              on: {
                click: function ($event) {
                  return _vm.opAlter()
                },
              },
            },
            [_vm._v("\n        " + _vm._s(_vm.$t("terrace")) + "\n      ")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("span", [_vm._v(" Copyright @ShowPay LLC. ")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { attrs: { href: "https://twitter.com/showpayio", target: "_blank" } },
      [
        _c("img", {
          attrs: {
            src: require("../assets/images/icon_twitter@2x.png"),
            alt: "",
          },
        }),
        _vm._v("\n        @showpayio\n      "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { attrs: { href: "https://medium.com/@showpay", target: "_blank" } },
      [
        _c("img", {
          attrs: {
            src: require("../assets/images/icon_medium@2x.png"),
            alt: "",
          },
        }),
        _vm._v("\n        @showpay\n      "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }