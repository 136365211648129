var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "home" }, [
    _c(
      "div",
      { staticClass: "homeWrap" },
      [
        _c(
          "van-tabs",
          {
            model: {
              value: _vm.active,
              callback: function ($$v) {
                _vm.active = $$v
              },
              expression: "active",
            },
          },
          [
            _c(
              "van-tab",
              { attrs: { title: "生成NFT合约" } },
              [
                _c(
                  "van-tabs",
                  {
                    staticClass: "nftjenesis",
                    model: {
                      value: _vm.nftactive,
                      callback: function ($$v) {
                        _vm.nftactive = $$v
                      },
                      expression: "nftactive",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "step",
                        class: {
                          hide: _vm.nftactive == 2,
                        },
                      },
                      [
                        _c("span", { staticClass: "active" }, [
                          _c("em", [_vm._v("1")]),
                          _vm._v("填写NFT系列信息"),
                        ]),
                        _c("span", [_vm._v("····")]),
                        _c(
                          "span",
                          {
                            class: {
                              active: _vm.nftactive == 1,
                            },
                          },
                          [_c("em", [_vm._v("2")]), _vm._v("铸造NFT")]
                        ),
                      ]
                    ),
                    _c("van-tab", [
                      _c(
                        "div",
                        { staticClass: "form" },
                        [
                          _c(
                            "van-form",
                            { on: { submit: _vm.onNFTGenesis } },
                            [
                              _c("van-field", {
                                attrs: {
                                  name: "nftTotal",
                                  label: "发行总数",
                                  type: "digit",
                                  placeholder: "请填写你的NFT发行总量",
                                  autocomplete: "off",
                                  required: "",
                                },
                                model: {
                                  value: _vm.nftTotal,
                                  callback: function ($$v) {
                                    _vm.nftTotal = $$v
                                  },
                                  expression: "nftTotal",
                                },
                              }),
                              _c("van-field", {
                                attrs: {
                                  name: "nftSeriesName",
                                  label: "系列名称",
                                  placeholder: "请填写token 系列名称",
                                  required: "",
                                },
                                model: {
                                  value: _vm.nftSeriesName,
                                  callback: function ($$v) {
                                    _vm.nftSeriesName = $$v
                                  },
                                  expression: "nftSeriesName",
                                },
                              }),
                              _vm.nftgenesisId
                                ? _c("p", { staticClass: "genesis" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          `GenesisId:${_vm.nftgenesisId}`
                                        ) +
                                        "\n                "
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "div",
                                { staticStyle: { margin: "16px" } },
                                [
                                  _c(
                                    "van-button",
                                    {
                                      attrs: {
                                        round: "",
                                        block: "",
                                        type: "info",
                                        "native-type": "submit",
                                        loading: _vm.isLoading,
                                      },
                                    },
                                    [_vm._v("下一步")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("van-tab", [
                      _c(
                        "div",
                        { staticClass: "form" },
                        [
                          _c(
                            "van-form",
                            { on: { submit: _vm.onNFTIssue } },
                            [
                              _c("van-field", {
                                attrs: {
                                  name: "nftgenesisId",
                                  label: "GenesisId",
                                  placeholder: "填写genesisId",
                                  required: "",
                                },
                                model: {
                                  value: _vm.nftgenesisId,
                                  callback: function ($$v) {
                                    _vm.nftgenesisId = $$v
                                  },
                                  expression: "nftgenesisId",
                                },
                              }),
                              _c("van-field", {
                                attrs: {
                                  name: "nftSensibleId",
                                  label: "SensibleId",
                                  placeholder: "填写genesisId",
                                  required: "",
                                },
                                model: {
                                  value: _vm.nftSensibleId,
                                  callback: function ($$v) {
                                    _vm.nftSensibleId = $$v
                                  },
                                  expression: "nftSensibleId",
                                },
                              }),
                              _c("van-field", {
                                attrs: {
                                  name: "genesisTxid",
                                  label: "GenesisTxid",
                                  placeholder:
                                    "填写genesisTxid,必须是正确的tx格式",
                                  required: "",
                                },
                                model: {
                                  value: _vm.nftgenesisTxid,
                                  callback: function ($$v) {
                                    _vm.nftgenesisTxid = $$v
                                  },
                                  expression: "nftgenesisTxid",
                                },
                              }),
                              _c("van-field", {
                                attrs: {
                                  name: "codehash",
                                  label: "CodeHash",
                                  placeholder: "填写codeHash",
                                  required: "",
                                },
                                model: {
                                  value: _vm.codehash,
                                  callback: function ($$v) {
                                    _vm.codehash = $$v
                                  },
                                  expression: "codehash",
                                },
                              }),
                              _c("van-field", {
                                staticClass: "mt-40",
                                attrs: {
                                  name: "receiverAddress",
                                  label: "接收地址",
                                  placeholder: "请填写NFT的接收地址",
                                  required: "",
                                },
                                model: {
                                  value: _vm.receiverAddress,
                                  callback: function ($$v) {
                                    _vm.receiverAddress = $$v
                                  },
                                  expression: "receiverAddress",
                                },
                              }),
                              _c("van-field", {
                                attrs: {
                                  name: "nftname",
                                  label: "通证名称",
                                  placeholder: "请填写NFT名称",
                                  required: "",
                                },
                                model: {
                                  value: _vm.nftname,
                                  callback: function ($$v) {
                                    _vm.nftname = $$v
                                  },
                                  expression: "nftname",
                                },
                              }),
                              _c("van-field", {
                                attrs: {
                                  name: "nftissuerName",
                                  label: "发布者名称",
                                  placeholder: "请填写NFT发布者名称",
                                  required: "",
                                },
                                model: {
                                  value: _vm.nftissuerName,
                                  callback: function ($$v) {
                                    _vm.nftissuerName = $$v
                                  },
                                  expression: "nftissuerName",
                                },
                              }),
                              _c("van-field", {
                                attrs: {
                                  name: "nftdesc",
                                  label: "通证描述",
                                  placeholder: "请填写NFT描述",
                                  required: "",
                                },
                                model: {
                                  value: _vm.nftdesc,
                                  callback: function ($$v) {
                                    _vm.nftdesc = $$v
                                  },
                                  expression: "nftdesc",
                                },
                              }),
                              _c("van-field", {
                                attrs: {
                                  name: "nfticon",
                                  label: "通证图标",
                                  placeholder: "请填图标TX,可以为空",
                                  required: "",
                                },
                                model: {
                                  value: _vm.nfticon,
                                  callback: function ($$v) {
                                    _vm.nfticon = $$v
                                  },
                                  expression: "nfticon",
                                },
                              }),
                              _c("van-field", {
                                attrs: {
                                  name: "nftwebsite",
                                  label: "网站介绍",
                                  placeholder: "NFT相关的网站介绍，可以为空",
                                },
                                model: {
                                  value: _vm.nftwebsite,
                                  callback: function ($$v) {
                                    _vm.nftwebsite = $$v
                                  },
                                  expression: "nftwebsite",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "mt-40" },
                                [
                                  _c(
                                    "van-button",
                                    {
                                      attrs: {
                                        round: "",
                                        block: "",
                                        type: "info",
                                        "native-type": "submit",
                                        loading: _vm.isLoading,
                                      },
                                    },
                                    [_vm._v("铸造NFT")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "van-tab",
                      { staticClass: "succontent" },
                      [
                        _c("i", { staticClass: "el-icon-success" }),
                        _c("p", [_vm._v("NFT铸造成功")]),
                        _c("div", { staticClass: "detail" }, [
                          _c("p", [
                            _c("span", [_vm._v("通证名称：")]),
                            _c("span", [_vm._v(_vm._s(_vm.nftname))]),
                          ]),
                          _c("p", [
                            _c("span", [_vm._v("铸造者：")]),
                            _c("span", [_vm._v(_vm._s(_vm.nftissuerName))]),
                          ]),
                          _c("p", [
                            _c("span", [_vm._v("系列名称：")]),
                            _c("span", [_vm._v(_vm._s(_vm.nftSeriesName))]),
                          ]),
                          _c("p", [
                            _c("span", [_vm._v("发行总量：")]),
                            _c("span", [_vm._v(_vm._s(_vm.nftTotal))]),
                          ]),
                        ]),
                        _c(
                          "van-button",
                          {
                            staticClass: "finishbtn",
                            attrs: { round: "", block: "", type: "info" },
                            on: {
                              click: function ($event) {
                                _vm.nftactive = 1
                              },
                            },
                          },
                          [_vm._v("完成")]
                        ),
                        _c(
                          "van-button",
                          {
                            staticClass: "viewbtn",
                            attrs: { round: "", block: "", type: "info" },
                            on: {
                              click: function ($event) {
                                return _vm.gotoUrl("index")
                              },
                            },
                          },
                          [_vm._v("点击查看")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "van-tab",
              { attrs: { title: "生成FT合约" } },
              [
                _c(
                  "van-tabs",
                  {
                    staticClass: "ftjenesis",
                    model: {
                      value: _vm.ftactive,
                      callback: function ($$v) {
                        _vm.ftactive = $$v
                      },
                      expression: "ftactive",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "step",
                        class: {
                          hide: _vm.ftactive == 2,
                        },
                      },
                      [
                        _c("span", { staticClass: "active" }, [
                          _c("em", [_vm._v("1")]),
                          _vm._v("填写FT系列信息"),
                        ]),
                        _c("span", [_vm._v("····")]),
                        _c(
                          "span",
                          {
                            class: {
                              active: _vm.ftactive == 1,
                            },
                          },
                          [_c("em", [_vm._v("2")]), _vm._v("发行FT")]
                        ),
                      ]
                    ),
                    _c("van-tab", [
                      _c(
                        "div",
                        { staticClass: "form" },
                        [
                          _c(
                            "van-form",
                            { on: { submit: _vm.onFTGenesis } },
                            [
                              _c("van-field", {
                                attrs: {
                                  name: "ftName",
                                  label: "FT名称",
                                  placeholder: "请填写FT合约名称",
                                  required: "",
                                },
                                model: {
                                  value: _vm.ftName,
                                  callback: function ($$v) {
                                    _vm.ftName = $$v
                                  },
                                  expression: "ftName",
                                },
                              }),
                              _c("van-field", {
                                attrs: {
                                  name: "ftSymbol",
                                  label: "名称缩写",
                                  placeholder:
                                    "请填写FT合约的缩写名称 (FT单位)",
                                  required: "",
                                },
                                model: {
                                  value: _vm.ftSymbol,
                                  callback: function ($$v) {
                                    _vm.ftSymbol = $$v
                                  },
                                  expression: "ftSymbol",
                                },
                              }),
                              _c("van-field", {
                                attrs: {
                                  name: "ftDecimalNum",
                                  label: "小数点位数",
                                  type: "digit",
                                  placeholder: "请设置FT的小书点位数，如: 8",
                                  required: "",
                                },
                                model: {
                                  value: _vm.decimalNum,
                                  callback: function ($$v) {
                                    _vm.decimalNum = $$v
                                  },
                                  expression: "decimalNum",
                                },
                              }),
                              _c("van-field", {
                                attrs: {
                                  name: "ftissuerName",
                                  label: "发布者名称",
                                  placeholder: "请填写发布者名称",
                                  required: "",
                                },
                                model: {
                                  value: _vm.ftissuerName,
                                  callback: function ($$v) {
                                    _vm.ftissuerName = $$v
                                  },
                                  expression: "ftissuerName",
                                },
                              }),
                              _c("van-field", {
                                attrs: {
                                  name: "ftdesc",
                                  label: "FT描述",
                                  placeholder: "请填写FT描述",
                                  required: "",
                                },
                                model: {
                                  value: _vm.ftdesc,
                                  callback: function ($$v) {
                                    _vm.ftdesc = $$v
                                  },
                                  expression: "ftdesc",
                                },
                              }),
                              _c("van-field", {
                                attrs: {
                                  name: "fticon",
                                  label: "FT图标",
                                  placeholder: "请上传图标/图片",
                                  required: "",
                                },
                                model: {
                                  value: _vm.fticon,
                                  callback: function ($$v) {
                                    _vm.fticon = $$v
                                  },
                                  expression: "fticon",
                                },
                              }),
                              _c("van-field", {
                                attrs: {
                                  name: "ftwebsite",
                                  label: "网站介绍",
                                  placeholder: "FT相关的网站介绍，可以为空",
                                },
                                model: {
                                  value: _vm.ftwebsite,
                                  callback: function ($$v) {
                                    _vm.ftwebsite = $$v
                                  },
                                  expression: "ftwebsite",
                                },
                              }),
                              _vm.ftgenesisId
                                ? _c("p", { staticClass: "genesis" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(`GenesisId:${_vm.ftgenesisId}`) +
                                        "\n                "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.ftgenesisprotocol
                                ? _c("p", { staticClass: "genesis" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          `GenesisId:${_vm.ftgenesisprotocol}`
                                        ) +
                                        "\n                "
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "div",
                                { staticStyle: { margin: "16px" } },
                                [
                                  _c(
                                    "van-button",
                                    {
                                      attrs: {
                                        round: "",
                                        block: "",
                                        type: "info",
                                        "native-type": "submit",
                                        loading: _vm.isLoading,
                                      },
                                    },
                                    [_vm._v("下一步")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("van-tab", [
                      _c(
                        "div",
                        { staticClass: "form" },
                        [
                          _c(
                            "van-form",
                            { on: { submit: _vm.onFTIssue } },
                            [
                              _c("van-field", {
                                attrs: {
                                  name: "ftgenesisId",
                                  label: "GenesisId",
                                  placeholder: "填写genesisId",
                                  required: "",
                                },
                                model: {
                                  value: _vm.ftgenesisId,
                                  callback: function ($$v) {
                                    _vm.ftgenesisId = $$v
                                  },
                                  expression: "ftgenesisId",
                                },
                              }),
                              _c("van-field", {
                                attrs: {
                                  name: "codehash",
                                  label: "codeHash",
                                  placeholder: "填写codeHash",
                                  required: "",
                                },
                                model: {
                                  value: _vm.codehash,
                                  callback: function ($$v) {
                                    _vm.codehash = $$v
                                  },
                                  expression: "codehash",
                                },
                              }),
                              _c("van-field", {
                                attrs: {
                                  name: "sensibleId",
                                  label: "sensibleId",
                                  placeholder: "填写sensibleId",
                                  required: "",
                                },
                                model: {
                                  value: _vm.sensibleId,
                                  callback: function ($$v) {
                                    _vm.sensibleId = $$v
                                  },
                                  expression: "sensibleId",
                                },
                              }),
                              _c("van-field", {
                                staticClass: "mt-40",
                                attrs: {
                                  name: "ftTotal",
                                  label: "发行总数量",
                                  placeholder: "请填写你的FT发行总量",
                                  type: "digit",
                                  required: "",
                                },
                                model: {
                                  value: _vm.ftTotal,
                                  callback: function ($$v) {
                                    _vm.ftTotal = $$v
                                  },
                                  expression: "ftTotal",
                                },
                              }),
                              _c("van-field", {
                                attrs: {
                                  name: "ftreceiverAddress",
                                  label: "接收地址",
                                  placeholder: "请填写FT的接收地址",
                                  required: "",
                                },
                                model: {
                                  value: _vm.ftreceiverAddress,
                                  callback: function ($$v) {
                                    _vm.ftreceiverAddress = $$v
                                  },
                                  expression: "ftreceiverAddress",
                                },
                              }),
                              _c("van-field", {
                                attrs: { name: "isAddPub", label: "是否增发" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "input",
                                    fn: function () {
                                      return [
                                        _c(
                                          "van-radio-group",
                                          {
                                            attrs: {
                                              direction: "horizontal",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.radio,
                                              callback: function ($$v) {
                                                _vm.radio = $$v
                                              },
                                              expression: "radio",
                                            },
                                          },
                                          [
                                            _c(
                                              "van-radio",
                                              { attrs: { name: "1" } },
                                              [_vm._v("是")]
                                            ),
                                            _c(
                                              "van-radio",
                                              { attrs: { name: "0" } },
                                              [_vm._v("否")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                              _c(
                                "div",
                                { staticStyle: { margin: "16px" } },
                                [
                                  _c(
                                    "van-button",
                                    {
                                      attrs: {
                                        round: "",
                                        block: "",
                                        type: "info",
                                        "native-type": "submit",
                                        loading: _vm.isLoading,
                                      },
                                    },
                                    [_vm._v("发行FT")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "van-tab",
                      { staticClass: "succontent" },
                      [
                        _c("i", { staticClass: "el-icon-success" }),
                        _c("p", [_vm._v("FT发行成功！")]),
                        _c("div", { staticClass: "detail" }, [
                          _c("p", [
                            _c("span", [_vm._v("通证名称：")]),
                            _c("span", [_vm._v(_vm._s(_vm.ftName))]),
                          ]),
                          _c("p", [
                            _c("span", [_vm._v("发行方：")]),
                            _c("span", [_vm._v(_vm._s(_vm.ftissuerName))]),
                          ]),
                          _c("p", [
                            _c("span", [_vm._v("发行总量：")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.ftTotal) + " " + _vm._s(_vm.ftName)
                              ),
                            ]),
                          ]),
                        ]),
                        _c(
                          "van-button",
                          {
                            staticClass: "finishbtn",
                            attrs: { round: "", block: "", type: "info" },
                            on: {
                              click: function ($event) {
                                _vm.ftactive = 1
                              },
                            },
                          },
                          [_vm._v("完成")]
                        ),
                        _c(
                          "van-button",
                          {
                            staticClass: "viewbtn",
                            attrs: { round: "", block: "", type: "info" },
                            on: {
                              click: function ($event) {
                                return _vm.gotoUrl("index")
                              },
                            },
                          },
                          [_vm._v("点击查看")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }