<template>
  <div class="index">
    <el-table class="table" :data="tableData">
      <el-table-column :label="$t('platform')" prop="plateName"> </el-table-column>
      <el-table-column :label="$t('introduce')" prop="plateIntro"> </el-table-column>
      <el-table-column align="right" :label="$t('operation')">
        <template slot-scope="scope">
          <el-button
            size="mini"
            v-if="isLogin"
            type="danger"
            @click="unBinding(scope.$index, scope.row)"
            >{{$t("unbound")}}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="control" v-if="hasBind">
      <div class="tips">
        <p>{{$t("boundMetaid")}}</p>
        <div class="btn">
          <el-button @click="cancel">{{$t("cancel")}}</el-button>
          <el-button @click="handleBinding" :loading="confirmloding"
            >{{$t("affirm")}}</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  getProtocolData,
  sign,
  signCheck,
  queryWebot,
  unbindWebot,
  queryMetaData,
  bindWebot,
  getMetaidData
} from "@/api/api";
export default {
  name: "Index",
  computed: {
    isLogin() {
      return this.$store.state.UserLogin;
    },
    ...mapState(["loginLoding", "userInfo", "isAppPlateform"])
  },
  components: {},
  data() {
    return {
      params: {
        user: "",
        pubkey: "",
        sig: ""
      },
      hasBind: false,
      isReady: false,
      issueBindTaskId: null,
      issueWebotTaskId: null,
      confirmloding: false,
      amount: 0,
      signTx: "",
      protocolTypeTxId: "",
      userData: JSON.parse(localStorage.getItem("localuserData")),
      botAmount: 0,
      fullLoading: "",
      tableData: []
    };
  },
  created() {
    this.lang = this.$i18n.locale;
    localStorage.removeItem("tolink");
    const binding = JSON.parse(localStorage.getItem("binding"));
    if (binding) {
      this.hasBind = true;
      this.params.user = binding.user;
      this.params.pubkey = binding.pubkey;
      this.params.sig = binding.sign;
      this.params.from = binding.from;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      if (this.isLogin) {
        this.getBindProtocol();
      }
    },
    async handleBinding() {
      this.confirmloding = true;
      const isChecked = await this.checkSign();
      if (isChecked) {
        try{
          await this.issueWebot(this.params.pubkey, true);
        }catch(error){
          this.confirmloding = false;
          this.$message.error(error);
        }
      } else {
        this.confirmloding = false;
      }
    },
    cancel() {
      this.hasBind = false;
      localStorage.removeItem("binding");
    },
    async unBinding(index, row) {
      this.$confirm(`${this.$t("confirmUnbind")}`,this.$t("tips"), {
        confirmButtonText: this.$t("affirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning"
      })
        .then(async () => {
          console.log(row);
          // this.getParentTxid(row.pubkey)
          this.fullLoading = this.$loading({
            lock: true,
            text: "Ing...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          console.log(row);

          const result = await this.issueUnBinding(
            row.pubkey,
            0,
            false,
            false,
            true
          );
          console.log(result);
          if (result.code === 200) {
            const signData = await sign({ tx: row.txid, type: 1 });
            console.log("signData", signData);
            const bindingRedult = await this.issueBinding(
              row.pubkey,
              0,
              false,
              false,
              true
            );
            if (bindingRedult.code === 200) {
              const result = await unbindWebot({
                sig: signData.sign,
                txid: row.txid
              });
              console.log("unbindWebot result", result);
              if (result.data === "ok") {
                this.hasBind = false;
                localStorage.removeItem("binding");
                this.$message.success(this.$t("unbindSuccess"));
                this.getBindProtocol();
              } else {
                this.$message.error(this.$t("unbindFault"));
              }
            } else {
              this.$message.error(bindingRedult.data.message);
            }
            this.tableData.splice(index, 1);
            this.$message.success(this.$t("unbindSuccess"));
          } else {
            this.$message.error(result.data.message);
          }
          this.hasBind = false;
          this.fullLoading.close();
        })
        .catch(err => {
          console.log(err);
          localStorage.removeItem("binding");
          this.fullLoading.close();
          this.$message({
            type: "info",
            message: err.message || "已取消"
          });
        });
    },
    async issueBinding(
      pubkey = "",
      status = 1,
      checkOnly = false,
      needConfirm = true
    ) {
      let accessToken = this.$store.state.userData.token || "";
      let protocolData = {
        name: "WeBot",
        pubkey,
        userpubkey: pubkey,
        callbackURL: "https://www.webot.sv/callback/showbuzz/bind",
        protocosType: "SimpleMicroblog",
        usertx: this.protocolTypeTxId,
        memo: this.protocolTypeTxId,
        updateTime: Date.now(),
        status
      };
      console.log(protocolData);
      const node = {
        nodeName: "LinkProtocols",
        metaIdTag: process.env.VUE_APP_IDtags,
        brfcId: "6ba956ed3593",
        encrypt: 0,
        payCurrency: "usd",
        path: "/Protocols/LinkProtocols",
        dataType: "application/json",
        data: JSON.stringify(protocolData),
        needConfirm,
        checkOnly,
        accessToken,
        nodeKey: pubkey
      };
      return await this.$store.state.metanet.createWebotProtocolNode(node);
    },
    async issueUnBinding(
      pubkey = "",
      status = 1,
      checkOnly = false,
      needConfirm = true
    ) {
      let accessToken = this.$store.state.userData.token || "";
      let protocolData = {
        name: "WeBot",
        pubkey,
        callbackURL: "https://www.webot.sv/callback/showbuzz/bind",
        protocosType: "WebotProtocol",
        updateTime: Date.now(),
        status
      };
      console.log(protocolData);
      const node = {
        nodeName: "WebotProtocol",
        metaIdTag: process.env.VUE_APP_IDtags,
        brfcId: "7f84e3d7a32b",
        encrypt: 0,
        payCurrency: "usd",
        path: "/Protocols/WebotProtocol",
        dataType: "application/json",
        data: JSON.stringify(protocolData),
        needConfirm,
        checkOnly,
        accessToken
      };
      return await this.$store.state.metanet.createBrfcProtocolNode(node);
    },
    issueBindingHandle(res) {
      this.amount = this.botAmount + res.data.usedAmount;
      this.issueBindTaskId = res.data.transactionTask;
      this.$confirm(`${this.$t("bindNeed")} ${this.amount} SAT`, this.$t("tips"), {
        confirmButtonText: this.$t("affirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning"
      })
        .then(async () => {
          this.fullLoading = this.$loading({
            lock: true,
            text: "Ing...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          const signData = await sign({ tx: this.signTx, type: 1 });
          console.log("signData", signData);
          const webotResult = await this.$store.state.metanet.resumeTransaction(
            this.issueWebotTaskId
          );
          console.log("webotResult", webotResult);
          if (webotResult.code === 200) {
            const bindingRedult = await this.issueBinding(
              this.params.pubkey,
              1,
              false,
              false
            );
            if (bindingRedult.code === 200) {
              const result = await bindWebot({
                user: this.params.user,
                sig: signData.sign,
                pubkey: this.params.pubkey,
                txid: webotResult.data.txId
              });
              console.log("result", result);
              if (result.data === "ok") {
                this.hasBind = false;
                localStorage.removeItem("binding");
                this.$message.success(this.$t("bindSuccess"));
                this.getBindProtocol();
              } else {
                this.$message.error(this.$t("bindSuccess"));
              }
            } else {
              this.$message.error(bindingRedult.data.message);
            }
          }

          this.confirmloding = false;
          this.fullLoading.close();
        })
        .catch((err) => {
          if (this.fullLoading) {
            this.fullLoading.close();
          }
          this.confirmloding = false;
          localStorage.removeItem("binding");
          this.$message({
            type: "info",
            message: err.message || "已取消"
          });
        });
    },
    async issueWebot(nodekey, checkOnly = false, needConfirm = true) {
      let accessToken = this.$store.state.userData.token || "";
      const node = {
        nodeName: "WebotBuzz",
        metaIdTag: process.env.VUE_APP_IDtags,
        brfcId: "7f84e3d7a32b",
        encrypt: 0,
        payCurrency: "usd",
        path: "/Protocols/WebotProtocol",
        dataType: "text/plain",
        data: "7f84e3d7a32b",
        accessToken,
        needConfirm,
        nodeKey: this.params.pubkey,
        isWebot: true,
        checkOnly
      };
      if (nodekey) {
        node.nodekey = nodekey;
      }
      let result
      try {
        result = await this.$store.state.metanet.createWebotProtocolNode(
          node
        );
      } catch (error) {
        this.$message.error('create webot protocol error: ' + error.data ? error.data.message : error.message)
      }
      try {
        this.issueWebotHandle(result);
      } catch (error) {
        this.$message.error('issueWebot error: ' + error.data ? error.data.message : error.message)
      }
    },
    async getParentPubkey(txid) {
      const data = await getMetaidData({ txId: txid });
      console.log(data);
      return data.data.result.data[0].publicKey;
    },
    async issueWebotHandle(res) {
      console.log("issueWebotHandle", res);
      this.botAmount = res.data.usedAmount;
      this.issueWebotTaskId = res.data.transactionTask;
      this.signTx = res.data.txId;
      this.protocolTypeTxId = res.data.protocolTypeTxId;
      try {
        const binddata = await this.issueBinding(this.params.pubkey, 1);
        this.issueBindingHandle(binddata);
      } catch (error) {
        this.$message.error('issueBinding error: ' + error.message)
      }
    },
    async checkSign() {
      try {
        await signCheck({
          pubkey: this.params.pubkey,
          sign: this.params.sig,
          type: 1
        });
        return true;
      } catch (err) {
        this.$message.error(err.message);
        return false;
      }
    },
    async getBindProtocol() {
      const result = await queryWebot({
        metaid: this.userData.metaId
      });
      console.log("unbindWebot result", result);
      if (result.data.r === "ok") {
        this.tableData = result.data.users;
      }
      //   const result = await getProtocolData(
      //     "LinkProtocols",
      //     {
      //       rootTxId: this.userData.metaId,
      //       isNew: true
      //     },
      //     0,
      //     1000
      //   );
      //   const data = result.data.result.data;
      //   const cache = {};
      //   for (let i = 0; i < data.length; i++) {
      //     let pubkey;
      //     let status;
      //     if (data[i]?.data?.pubkey) {
      //       pubkey = data[i].data?.pubkey;
      //       status = data[i].data?.status;
      //     }
      //     if (!pubkey) {
      //       continue;
      //     }
      //     if (!cache[pubkey]) {
      //       cache[pubkey] = {};
      //     }
      //     if (+data[i].data.status === 1 && !cache[pubkey]?.status) {
      //       cache[pubkey][status] = data[i].data;
      //     }
      //     if (+data[i].data.status === 0 && !cache[pubkey]?.status) {
      //       cache[pubkey][status] = data[i].data;
      //     }
      //   }
      //   for (let item in cache) {
      //     if (cache[item]["0"] && cache[item]["1"]) {
      //       const unbindtime = cache[item]["0"]?.updateTime || 0;
      //       const bindtime = cache[item]["1"]?.updateTime || 0;
      //       if (unbindtime < bindtime) {
      //         this.tableData.push(cache[item]["1"]);
      //       }
      //       break
      //     }
      //     if(cache[item]["1"]){
      //       this.tableData.push(cache[item]["1"]);
      //       break
      //     }
      //   }
      //   console.log("cache", cache);
      //   console.log(this.tableData);
      // for (let item in cache) {
      //   if (cache[item]["0"] && cache[item]["1"]) {
      //     if (cache[item]["0"].updateTime > cache[item]["1"].updateTime) {
      //       console.log(`cache[item]["0"]`, cache[item]["0"].pubkey);
      //       this.batchunbind(cache[item]["0"].pubkey);
      //     }
      //   }
      //   // if(cache[item]["0"] && !cache[item]["1"]){
      //   //   console.log(cache[item]["0"]);
      //   // }
      // }
      // for (let i = 0; i < data.length; i++) {
      //   let platename
      //   if(data[i]?.data?.name){
      //     platename = data[i].data?.name.toLowerCase()
      //   }
      //   if(!platename){
      //     continue
      //   }
      //   if(!cache[platename]){
      //     cache[platename] = []
      //   }
      //   if (+data[i].data.status === 1 && cache[platename].length < 1) {
      //     cache[platename].push(data[i].data);
      //     this.tableData.push(data[i].data)
      //   }
      // }
    },
    async getNodeTxid(parentTxId) {
      const result = await queryMetaData({
        parentTxId
      });
      const data = result.data.result.data[0];
      return data?.txId;
    },
    async batchunbind(pubkey) {
      const parentTxid = await this.getNodeTxid(pubkey);
      console.log("parentTxid", parentTxid);
      if (parentTxid) {
        const signData = await sign({ tx: parentTxid, type: 1 });
        console.log("signData", signData);
        if (signData.data.code === 0) {
          const result = await unbindWebot({
            sig: signData.data.data.sign,
            txid: parentTxid
          });
          console.log("unbindWebot result", result);
          if (result.data === "ok") {
            this.$message.success("解绑成功");
          } else {
            this.$message.error("解绑失败");
          }
        } else {
          this.$message.error(bindingRedult.data.message);
        }
        this.$message.success("解绑成功");
      } else {
        this.$message.error("数据不存在,请重新绑定");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.table {
  width: 85%;
  margin: 50px auto;
}
.control {
  display: flex;
  justify-content: center;
}
.tips {
  width: 90%;
  max-width: 400px;
  text-align: center;
  background-color: rgba(126, 126, 126, 0.1);
  padding: 15px;
  border-radius: 6px;
  p {
    font-size: 16px;
    margin-bottom: 16px;
  }
  .btn {
    display: flex;
    justify-content: space-between;
  }
}
</style>
