import store from "@/store";
import Vue from "vue";
import CryptoJS from "crypto-js";
import ShowmoneyMetanetSDK from "showmoney-metanet-sdk";
import {
  checkAddress,
  gettokenpage,
  getOneExchangeRateForLocal,
  // addUserAddress,
  getOwnShowAccount,
  getInfoAddressAndTxId,
  getUserRemark,
  setUserWalletInfo,
  // addresslist
  // getMetaIdByZoreAddress,
  // sendMetaId
} from "@/api/api";
import { authorizeLogin } from "@/api/authorizeLogin_api.js";
import { setPassword } from "../../api/api";
import { useTestNet } from "./baseApi";
const that = Vue.prototype;
/* eslint-disable */
const utils = {
  tokenFilters(genesis) {
    switch (genesis) {
      case "8e9c53e1a38ff28772db99ee34a23bb305062a1a":
        return "ovts";
      case "341476e63af470912dbd166b19cfb21429c32566":
        return "boex";
      case "67cfb6b1b163946a738cb0c2bed781d57d8099a7":
        return "usdt";
      case "5d15eedd93c90d91e0d76de5cc932c833baf8336":
        return "tsc";
      case "54256eb1b9c815a37c4af1b82791ec6bdf5b3fa3":
        return "mc";
      case "ba85ed5e6f4492e2789f92d8c66cbe211943bdfc":
        return "asc";
      case "8c6294e1ade490a79ee846a1e244ada0ebe16b9c":
        return "cc";
      case "0b75d5dfabfeff2b1d989bc692a199f1ef094209":
        return "ceo";
      case "7b674d5fa1b60da14aae43e61842a290a172e16a":
        return "bart";
      case "121e946a8b1ea87dae4484cd00d5ef94112d3b43":
        return "whst";
      default:
        return "empty";
    }
  },
  dataFormat(time) {
    if (time) {
      let year = time.getFullYear(); //年
      let month = time.getMonth() + 1; //月
      let day = time.getDate(); //日
      let hour = time.getHours(); //时
      let minute = time.getMinutes(); //分
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      if (hour >= 0 && hour <= 9) {
        hour = `0${hour}`;
      }
      if (minute >= 0 && minute <= 9) {
        minute = `0${minute}`;
      }
      let str = `${year}-${month}-${day} ${hour}:${minute}`;
      return str;
    } else return "";
  },
  // 打钱完之后判断是否需要创建Metaid
  cryptojs: CryptoJS,
  getShowIDUserData(mataId) {
    let params = {
      rootTxId: mataId || store.state.userData.metaId,
    };
    // console.log(params)
    getInfoAddressAndTxId(JSON.stringify(params))
      .then((res) => {
        // console.log(res, '拿到数据')
        store.state.infoTxId = res.infoTxId;
      })
      .catch((err) => {
        return that.$message.error("" + err);
        // console.log(err)
      });
  },
  // 创建 metaid
  async getMetaData() {
    let metaparams = {
      showId: store.state.userData.metaId,
    };
    // console.log(store.state.userData.metaId)
    getOwnShowAccount(JSON.stringify(metaparams))
      .then((res) => {
        if (res.showId) {
          let result = res;
          store.state.metaShow = false;
          store.state.userMeta = result;
          store.state.userData.name = result.name;
          that.$utils.getShowIDUserData();
          if (store.state.userMeta.headUrl) {
            // console.log('我进来了')
            let str = store.state.userMeta.headUrl;
            if (!str) {
              return new Uint8Array();
            }
            var a = [];
            for (let i = 0, len = str.length; i < len; i += 2) {
              a.push(parseInt(str.substr(i, 2), 16));
            }
            var binary = "";
            var bytes = new Uint8Array(a);
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
              binary += String.fromCharCode(bytes[i]);
            }
            let sty = window.btoa(binary);
            let type = "image/jpeg";
            store.state.userData.headUrl = "data:" + type + ";base64," + sty;
            console.log(store.state.userData);
            localStorage.setItem(
              "localuserData",
              JSON.stringify(store.state.userData)
            );
            that.$forceUpdate();
          }
        }
      })
      .catch((err) => {
        // store.state.metaShow = false
        return that.$message.error("" + err);
      });
  },
  getTxUserData(tx) {
    console.log(tx, "用户信息");
    return new Promise((resolve, reject) => {
      if (!tx) {
        return reject("No txId");
      }
      const metaparams = {
        showId: tx,
      };
      getOwnShowAccount(JSON.stringify(metaparams))
        .then((res) => {
          res.headUrl = this.hexStringToByte(res.headUrl);
          return resolve(res);
        })
        .catch((err) => {
          return reject(err.message);
        });
    });
  },
  hexStringToByte(str) {
    if (!str) {
      return "";
    }
    var a = [];
    for (let i = 0, len = str.length; i < len; i += 2) {
      a.push(parseInt(str.substr(i, 2), 16));
    }
    var binary = "";
    var bytes = new Uint8Array(a);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    const sty = window.btoa(binary);
    const type = "image/jpeg";
    return "data:" + type + ";base64," + sty;
  },
  UpLogin(tx, type, data) {
    return new Promise(async (resolve, reject) => {
      let re =
        /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,16}$/;
      let txData = {
        nodeName: type,
        parentTxId: tx || store.state.infoTxId,
        data: data,
        encrypt: 1,
      };
      // console.log(txData)
      await store.state.metanet.getUtxoFromMetaSV(true);
      store.state.metanet
        .createNode(txData)
        .then((res) => {
          console.log("emai 的返回", res);
          resolve();
        })
        .catch((err) => {
          reject();
          // console.log(err, 'login 错误', typeof err)
          store.state.rootText = err;
          return that.$message.error("" + err.data.message);
        });
    });
  },
  UpName(data) {
    // console.log(store.state.userData.metaId)
    console.log(store.state.userMeta.infoTxId, data);
    return new Promise(async (resolve, reject) => {
      let txData = {
        nodeName: "name",
        parentTxId: store.state.userMeta.infoTxId,
        data: data,
      };
      console.log("修改用户名", txData);
      await store.state.metanet.getUtxoFromMetaSV(true);
      store.state.metanet
        .createNode(txData)
        .then((res) => {
          console.log(res);
          resolve(res);
        })
        .catch((err) => {
          that.$message.error("" + err.data.message);
          reject();
        });
    });
  },
  //上传 NFT 头像
  upNftAvatar(tx, tokenData) {
    console.log("上传 1111 头像", tx, store.state.userMeta.infoTxId, tokenData);
    return new Promise((resolve, reject) => {
      let txData = {
        // path: '/Protocols/MetaFile',
        nodeName: "NFTAvatar",
        // parentTxId: tx || store.state.userMeta.infoTxId,
        data: JSON.stringify(tokenData),
        dataType: "text/plain",
        path: "/Protocols/NFTAvatar",
        brfcId: "b1e12b089e71",
        needConfirm: false,
        encoding: "UTF-8",
      };
      // console.log(store.state.userData.metaId)
      console.log("zzxzczx", store.state.metanet);
      // console.log(tx, imgData)
      // console.log(store.state.metanet)
      store.state.metanet
        .createProtocolNode(txData)
        .then((res) => {
          console.log(res);
          resolve(res);
        })
        .catch((err) => {
          store.state.rootText = err;
          console.log(err, "img 头像", typeof err);
          reject();
          // return this.$message.error("" + err.data.message);
        });
    });
  },
  // 上传头像
  UpheadImg(tx, imgData, dataType) {
    console.log(
      "上传头像",
      tx,
      store.state.userMeta.infoTxId,
      imgData,
      dataType
    );
    return new Promise(async (resolve, reject) => {
      let txData = {
        nodeName: "avatar",
        parentTxId: tx || store.state.userMeta.infoTxId,
        data: imgData,
        dataType: dataType,
        needConfirm: false,
        encoding: "binary",
      };
      // console.log(store.state.userData.metaId)
      console.log(txData);
      // console.log(tx, imgData)
      // console.log(store.state.metanet)
      await store.state.metanet.getUtxoFromMetaSV(true);
      store.state.metanet
        .createNode(txData)
        .then((res) => {
          console.log(res);
          resolve(res);
        })
        .catch((err) => {
          store.state.rootText = err;
          console.log(err, "img 头像", typeof err);
          reject();
          return that.$message.error("" + err.data.message);
        });
    });
  },
  // 加密
  encrypt(word, keyStr) {
    let encrypted = CryptoJS.AES.encrypt(
      word,
      CryptoJS.enc.Utf8.parse(keyStr),
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    return encrypted.toString();
  },
  // 解密
  decrypt(word, keyStr) {
    var decrypt = CryptoJS.AES.decrypt(word, CryptoJS.enc.Utf8.parse(keyStr), {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return decrypt.toString(CryptoJS.enc.Utf8);
  },
  // 获取余额
  async getBalance() {
    let metanet = store.state.metanet;
    // console.log(wallet.getBalance(), 123132)
    let balance = await metanet.getBalance();
    // store.state.balance = balance;
    await store.dispatch("updataMoney", balance);

    // return balance;
  },
  // 获取汇率
  getMoneyRate() {
    return new Promise((resolve, reject) => {
      let data = {
        type: 2,
        userType: "phone",
        symbolId: "",
      };
      let params = {
        data: JSON.stringify(data),
      };
      getOneExchangeRateForLocal(params)
        .then((res) => {
          // console.log(res, 123)
          resolve(res);
          // console.log('lang', this.store.state.moneyRate)
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  },
  // 根据是否设置币种运算汇率法币
  getAmount(amount, nub) {
    if (store.state.moneyRate) {
      // let currency = store.state.currency;
      let currency = "USD";
      if (currency) {
        if (currency === "CNY") {
          return that.cutNumberNoZero(amount * store.state.moneyRate.CNY, nub);
        } else {
          let money = store.state.moneyRate.USD;
          return that.cutNumberNoZero(amount * money, nub);
        }
      } else {
        if (store.state.lang === "zh") {
          return that.cutNumberNoZero(amount * store.state.moneyRate.CNY, nub);
        } else {
          let money = store.state.moneyRate.USD;
          return that.cutNumberNoZero(amount * money, nub);
        }
      }
    }
  },
  // 币种展示
  getCurrency() {
    return "USD";
    if (localStorage.getItem("currency")) {
      return localStorage.getItem("currency");
    } else {
      return store.state.lang === "zh" ? "CNY" : "USD";
    }
  },
  getTokenFee(options) {
    // console.log(walletService, 4564654654654)
    return new Promise(function (resolve, reject) {
      // console.log(options, '我是 option')
      let walletService = store.state.wallet || "";
      if (walletService) {
        walletService
          .spentToken(options)
          .then((tx) => {
            // console.log(tx, '获取 tx'
            console.log("here!!!!!");
            tx.feePerKb = 500;
            resolve({
              tx: tx,
              fee: tx.getFee(),
            });
          })
          .catch((err) => {
            console.log(err);
            reject(err.error);
          });
      } else {
        reject("Failed to initialize wallet");
      }
    });
  },
  // 获取自定义信息
  gettokenpageData() {
    return new Promise((resolve, reject) => {
      let params = {
        userType: store.state.userData.phone ? "phone" : "email",
      };
      params.userType === "phone"
        ? (params.phone = store.state.userData.phone)
        : (params.email = store.state.userData.email);
      gettokenpage(params).then((res) => {
        // console.log(res)
        let data;
        try {
          data = JSON.parse(res.tokenString);
          data.card = data?.card || [];
        } catch {
          data = {};
        }
        resolve(data);
      });
    });
  },
  // 获取旷工费并且拿到 TX
  getFee(options) {
    let MetanetSDK = store.state.metanet;
    console.log(MetanetSDK);
    return new Promise(function (resolve, reject) {
      MetanetSDK.sendMoney(options)
        .then((res) => {
          console.log(res, "新费率");
          resolve({
            tx: res.data.transactionHex,
            fee: res.data.fee,
          });
        })
        .catch((err) => {
          if (err?.data?.message) {
            reject(err.data.message);
          } else {
            reject(err);
          }
        });
    });
  },
  // 钱包列表信息
  getWallet(item) {
    let name = item;
    // console.log(item, '我的列表名字')
    let myTokenList = store.state.myTokenList;
    let walletList = store.state.tokenList;
    let tokenList;
    if (walletList) {
      tokenList = walletList.filter(function (item) {
        return item.symbol === name;
      });
      for (let i = 0; i < tokenList.length; i++) {
        if (tokenList[i].symbol === name) {
          store.state.wallet.tokenized
            .getUserTokenBalance({
              symbol: tokenList[i].symbol,
              symbolId: tokenList[i].symbolId,
              userAddress: store.state.wallet.getTokenReceiveAddress(),
            })
            .then((res) => {
              // console.log(res, '对应币种的信息')
              if (res.code === 200) {
                tokenList[i].balance = res.result;
                myTokenList.push(tokenList[i]);
              } else {
                // return that.$message.error(that.$t("Error") + res.msg);
                return that.$message.error(res.msg);
              }
            })
            .catch((err) => {
              // return that.$message.error(that.$t("Error") + err);
              return that.$message.error(err);
            });
        }
      }
      that.$utils.gettokenpageData().then((res) => {
        let userData = res || {};
        userData.cardName = userData.cardName || {};
        store.state.BSVname = userData.cardName.BSV
          ? userData.cardName.BSV
          : "BSV 钱包 1";
        let text = store.state.lang === "zh" ? "钱包" : "wallet";
        if (userData.cardName) {
          for (let i = 0; i < myTokenList.length; i++) {
            if (
              userData.cardName[myTokenList[i].symbol] ||
              userData.cardName[myTokenList[i].symbol] === ""
            ) {
              myTokenList[i].cardName =
                userData.cardName[myTokenList[i].symbol];
            } else {
              myTokenList[i].cardName = myTokenList[i].symbol + text + (i + 2);
            }
          }
        }
      });
    }
    // console.log(tokenList)
  },
  // 币种列表
  getWalletList() {
    let params = {
      userType:
        localStorage.getItem("loginType") ||
        (store.state.userData.phone ? "phone" : "email"),
    };
    params.userType === "phone"
      ? (params.phone = store.state.userData.phone)
      : (params.email = store.state.userData.email);
    console.log(params);
    gettokenpage(params)
      .then((res) => {
        if (res.tokenString) {
          let data = JSON.parse(res.tokenString) || {};
          store.state.myTokenList = [];
          if (data.mnemonic) {
            store.state.mnemonic = data.mnemonic;
          }
          if (data.card) {
            for (let i = 0; i < data.card.length; i++) {
              // console.log(111)
              that.$utils.getWallet(data.card[i]);
            }
          }
        }
      })
      .catch((err) => {
        return that.$message.error("错误 2" + err.message);
      });
  },
  // 实例化钱包
  async newWallet(key, type, remark, encrypt_mnemonic, encrypt_password) {
    // console.log(key)
    return new Promise(async (resolve, reject) => {
      const isLogin = type == "login";
      // console.log(store.state.userData)
      let loginType = "";
      let re =
        /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,16}$/;
      if (
        re.test(
          store.state.userData.register == "email"
            ? store.state.userData.email
            : store.state.userData.phone
        )
      ) {
        window.ShowMoney.noneMnemonic.setLoginType("email");
        loginType = "email";
      } else {
        window.ShowMoney.noneMnemonic.setLoginType("phone");
        loginType = "phone";
      }
      let userData = {
        userType: loginType,
        remark: remark,
      };
      let getRemarkParams = {
        userType: loginType,
      };
      if (loginType === "email") {
        userData.email = store.state.userData.email;
        getRemarkParams.email = store.state.userData.email;
      } else if (loginType === "phone") {
        userData.phone = store.state.userData.phone;
        getRemarkParams.phone = store.state.userData.phone;
      }
      // 获取密码提示
      if (type === "login") {
        await getUserRemark(getRemarkParams).then((res) => {
          userData.remark = res.remark;
        });
      }
      // console.log('实例化钱包')
      console.log("store.state.userData", store.state.userData);
      const fullPath = store.state.userData.path
        ? `m/44'/${store.state.userData.path}'/0'`
        : "";
      try {
        let wallet = new window.ShowMoney.WalletService({
          isMain: useTestNet() ? false : true,
          phone:
            store.state.userData.register == "email"
              ? store.state.userData.email
              : store.state.userData.phone,
          path: fullPath,
          mnemonic_str: key.mnemonic,
          ppk: key.ppk,
          encrypt_mnemonic: encrypt_mnemonic,
          headers: {
            accessKey: store.state.userData.token,
            userName:
              store.state.userData.register == "email"
                ? store.state.userData.email
                : store.state.userData.phone,
            timestamp: Date.now(),
          },
          userData: userData,
          userFlag: store.state.userData.tag,
          // 实例化完成的回调
          cb: async (wallet2) => {
            console.log("wallet2", wallet2);
            console.log({
              accessKey: store.state.userData.token,
              userName:
                store.state.userData.register == "email"
                  ? store.state.userData.email
                  : store.state.userData.phone,
              timestamp: Date.now(),
            });
            store.state.wallet = wallet2;
            console.log(wallet2.cur_private_key);
            console.log("wallet", wallet);
            let address = wallet2.getTokenReceiveAddress();
            let addresslistParams = {
              userType: loginType,
            };
            loginType === "phone"
              ? (addresslistParams.phone =
                  store.state.userData.register == "email"
                    ? store.state.userData.email
                    : store.state.userData.phone)
              : (addresslistParams.email =
                  store.state.userData.register == "email"
                    ? store.state.userData.email
                    : store.state.userData.phone);
            // addresslist(addresslistParams).then(res => {
            // // console.log(res)
            // wallet2.chargeList = res;
            // });
            let userData = store.state.userData;
            console.log("root address", address);
            // 上报钱包信息
            if (type === "sign" || type === "oauth") {
              let sendWalletParams = {
                userType: loginType,
                address,
                type: 0,
                email: userData.email,
                phone: userData.phone,
                xpub: wallet2.getXpub(),
                remark: remark,
                pubkey: wallet2.cur_private_key.publicKey.toString(),
                path: 10001,
              };
              console.log("sendWalletInfo", sendWalletParams);
              try {
                let walletRes = await setUserWalletInfo(sendWalletParams);
                // if (res.data.code === 0) {
                //   if (type === 'oauth') {
                //     return resolve()
                //   }
                // } else {
                //   if (res.data.code === 501) {
                //     reject('The accessKey failed')
                //   } else {
                //     reject(res.data.msg)
                //   }
                // }
                // const walletRes = await wallet2.setUserWalletInfo();
                console.log("setWalletRes", walletRes);
                if (walletRes.code === 500) {
                  reject("上报钱包信息失败");
                  throw new Error("注册钱包信息失败");
                }
                const passwordRes = await setPassword({
                  userType: loginType,
                  phone: userData.phone,
                  email: userData.email,
                  password: encrypt_password,
                  affirmPassword: encrypt_password,
                  enCryptedMnemonic: encrypt_mnemonic,
                  remark: remark,
                  address: address,
                });
                if (passwordRes.code === 500) {
                  reject("上报钱包信息失败");
                }
              } catch (error) {
                reject(error);
                throw new Error(error);
              }
            }
            // 上报地址
            console.log(userData);
            // console.log(senduserParams)
            const userDataParams = Object.assign(
              {
                userType: loginType,
                remark: remark,
                userName:
                  store.state.userData.register == "email"
                    ? store.state.userData.email
                    : store.state.userData.phone,
                nickName: userData.name,
              },
              userData
            );
            // store.state.loginText = 'Querying MetaID data'
            // // }
            console.log(userDataParams);
            // store.state.metaShow = true
            let metanet1;
            try {
              store.state.metanet = metanet1 = await ShowmoneyMetanetSDK.create(
                {
                  xprivKey: wallet2.cur_private_key.xprivkey,
                  userData: userDataParams,
                  isProd: Boolean(+process.env.VUE_APP_USEMAINNET),
                  debug: true,
                  isTestnet: useTestNet(),
                  isSign: type === "sign" || type === "oauth",
                  chain: store.state.chain,
                }
              );
            } catch (err) {
              return reject(err?.data?.message || err);
            }
            console.log("type: ", type);
            console.log(store.state.metanet);

            let checkAddressParams = {
              userType: loginType,
              address,
            };
            loginType === "phone"
              ? (checkAddressParams.phone =
                  store.state.userData.register == "email"
                    ? store.state.userData.email
                    : store.state.userData.phone)
              : (checkAddressParams.email =
                  store.state.userData.register == "email"
                    ? store.state.userData.email
                    : store.state.userData.phone);
            // 验证用户地址
            checkAddress(checkAddressParams)
              .then((res) => {
                if (res.code === 500) {
                  reject(res.message);
                }
                let params = {
                  type: 2,
                  userType: loginType,
                };
                loginType === "phone"
                  ? (params.phone =
                      store.state.userData.register == "email"
                        ? store.state.userData.email
                        : store.state.userData.phone)
                  : (params.email =
                      store.state.userData.register == "email"
                        ? store.state.userData.email
                        : store.state.userData.phone);
                store.state.UserLogin = true;
                resolve(metanet1);
              })
              .catch((err) => {
                reject(err.message);
              });
          },
          reciverCb: (res) => {
            // console.log(res, '有钱来了')
          },
          errorCb: (err) => {
            // return true;
            // debugger;
            // console.log(err)
            console.log("setUserWalletInfo", err);
            console.log(that.$message);
            // reject(err);
            // return that.$message.error("" + err);
          },
        });
      } catch (error) {
        console.error(error);
      }
    });
  },
  // 第三方登录获取 JWTtoken
  getJWTtoken(address) {
    let params = {
      username:
        store.state.userData.register == "email"
          ? store.state.userData.email
          : store.state.userData.phone,
      password: address || store.state.wallet.getTokenReceiveAddress(),
    };
    return authorizeLogin(params);
  },
  aesEncryptMem(mem, password) {
    if (!mem || !password) {
      return false;
    }
    password = this.paddingPwd(password);
    const memStr = mem.split(" ").join(",");
    console.log("aesEncryptMem 合并后助记词:", memStr);
    console.log("aesEncryptMem 密码:", password);
    console.log("aesEncryptMem 加密结果:", this.aesEncrypt(memStr, password));
    return this.aesEncrypt(memStr, password);
  },
  aesDescryptMem(mem, password) {
    try {
      if (!mem || !password) {
        return false;
      }
      password = this.paddingPwd(password);
      // const memStr = CryptoJS.AES.decrypt(mem, password)
      //   .toString(CryptoJS.enc.Utf8)
      //   .split(",")
      //   .join(" ");
      const memStr = this.aesDecrypt(mem, password);
      console.log("aesDescryptMem 解密前助记词:", mem);
      console.log("aesDescryptMem 解密密码:", password);
      console.log("aesDescryptMem 解密结果:", memStr);
      return memStr.split(",").join(" ");
    } catch (error) {
      return false;
    }
  },
  encryptPassword(password) {
    if (!password) {
      return false;
    }
    const buf = Buffer;
    const md5password = CryptoJS.MD5(password).toString();
    // const md5_buf = new Buffer.from(md5password, 'base64')
    // const md5_buf_hex = new Buffer.from(md5password, 'hex')
    const sha256Str = CryptoJS.SHA256(md5password).toString();
    console.log("encryptPassword:", sha256Str);
    return sha256Str.toUpperCase();
  },
  createMnemonicByPk2(account, password, pk2, tag) {
    const showMoney = window.ShowMoney;
    const key = showMoney.noneMnemonic.createMasterHdPrivateKey(
      account,
      password,
      pk2,
      tag
    );
    const de_mnemonic = showMoney.noneMnemonic.decodeMnemonic(
      key.mnemonic,
      key.ppk
    );
    return de_mnemonic;
  },
  creadZeroAddress(mnemonic, tag) {
    console.log("creadZeroAddress mnemonic:", mnemonic);
    const address = window.ShowMoney.bsv.Mnemonic.fromString(mnemonic)
      .toHDPrivateKey()
      .deriveChild(tag == "new" ? "m/44'/10001'/0'" : "m/44'/145'/0'")
      .deriveChild(0)
      .deriveChild(0)
      .privateKey.toAddress(
        useTestNet()
          ? window.ShowMoney.bsv.Networks.testnet.name
          : window.ShowMoney.bsv.Networks.mainnet.name
      )
      .toString();
    return address;
  },
  creadPrivate(mnemonic, tag) {
    console.log("creadZeroAddress mnemonic:", mnemonic);
    const address = window.ShowMoney.bsv.Mnemonic.fromString(mnemonic)
      .toHDPrivateKey()
      .deriveChild(tag == "new" ? "m/44'/10001'/0'" : "m/44'/145'/0'");
    return address;
  },
  checkPasswordAddress(address, userType, _phone, vue) {
    const phone = userType == "phone" ? _phone : "";
    const email = userType == "email" ? _phone : "";
    return new Promise((resolve, reject) => {
      checkAddress({
        address,
        userType,
        phone,
        email,
      })
        .then((r) => {
          resolve(true);
        })
        .catch((err) => {
          console.log("checkAddress request error");
          resolve(false);
        });
    });
  },
  encodeUtf8(str) {
    var rs = "";
    for (var i of str) {
      var code = i.codePointAt(0);
      if (code < 128) {
        rs += i;
      } else if (code > 127 && code < 2048) {
        rs += String.fromCharCode((code >> 6) | 192, (code & 63) | 128);
      } else if (code > 2047 && code < 65536) {
        rs += String.fromCharCode(
          (code >> 12) | 224,
          ((code >> 6) & 63) | 128,
          (code & 63) | 128
        );
      } else if (code > 65536 && code < 1114112) {
        rs += String.fromCharCode(
          (code >> 18) | 240,
          ((code >> 12) & 63) | 128,
          ((code >> 6) & 63) | 128,
          (code & 63) | 128
        );
      }
    }
    console.log(rs);
    return rs;
  },
  decodeUtf8(str) {
    var rs = "";
    for (var i = 0; i < str.length; i++) {
      var code = str.charCodeAt(i);
      console.log(code);
      if ((240 & code) == 240) {
        var code1 = str.charCodeAt(i + 1),
          code2 = str.charCodeAt(i + 2),
          code3 = str.charCodeAt(i + 3);
        rs += String.fromCodePoint(
          ((code & 7) << 18) |
            ((code1 & 63) << 12) |
            ((code2 & 63) << 6) |
            (code3 & 63)
        );
        i += 3;
      } else if ((224 & code) == 224) {
        var code1 = str.charCodeAt(i + 1),
          code2 = str.charCodeAt(i + 2);
        rs += String.fromCodePoint(
          ((code & 15) << 12) | ((code1 & 63) << 6) | (code2 & 63)
        );
        i += 2;
      } else if ((192 & code) == 192) {
        var code1 = str.charCodeAt(i + 1);
        rs += String.fromCodePoint(((code & 31) << 6) | (code1 & 63));
        i++;
      } else if ((128 & code) == 0) {
        rs += String.fromCharCode(code);
      }
    }
    console.log(rs);
  },
  aesEncrypt(message, secret_key) {
    let iv = "0000000000000000";
    message = CryptoJS.enc.Utf8.parse(message);
    secret_key = CryptoJS.enc.Utf8.parse(secret_key);
    iv = CryptoJS.enc.Utf8.parse(iv);

    // Encrypt
    var ciphertext = CryptoJS.AES.encrypt(message, secret_key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    let output = Buffer.from(ciphertext.toString(), "base64");
    return output.toString("hex");
  },
  aesDecrypt(message, secret_key) {
    let iv = "0000000000000000";
    iv = CryptoJS.enc.Utf8.parse(iv);
    let _secret_key = CryptoJS.enc.Utf8.parse(secret_key);
    let buff;
    try {
      buff = new Buffer.from(message, "hex");
    } catch {
      return message;
    }
    let base64data = buff.toString("base64");
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(base64data, _secret_key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return bytes.toString(CryptoJS.enc.Utf8);
  },
  paddingPwd(pwd, paddingLength = 16) {
    if (pwd.length > 16) {
      paddingLength = paddingLength * 2;
    }
    if (pwd.length < paddingLength) {
      for (let i = pwd.length; i < paddingLength; i++) {
        pwd += "0";
      }
    }
    console.log("paddingPwd长度:", pwd.length);
    return pwd;
  },
  async sleep(time) {
    return new Promise((resolve) => {
      setInterval(() => {
        resolve(true);
      }, time);
    });
  },
};

export default utils;
